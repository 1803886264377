/* eslint-disable */ 
import React, { Component } from 'react';
import { css } from 'aphrodite';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ApiHelper } from '../../common/helpers/ApiHelper';
import { Helper } from '../../common/helpers/Helper';
import { UiHelper } from '../../common/helpers/UiHelper';
import { Styles } from './Styles';
import { EventActionHeaderbar } from './EventActionHeaderBar';
import { EventDetails } from './EventDetails';
import { EventEditor } from './EventEditor';
import { adminData } from '../../data/AdminData.js'
import { CommonStyles } from '../../styles/Styles';

export class Event extends Component {
  constructor(props) {
    super(props);
    this.eventId = this.props.orgLevel ? this.props.eventId : ((this.props.match && this.props.match.params && this.props.match.params.eventId) ? this.props.match.params.eventId : null)
    this.eventsByGroupId = {}
    this.liveUrl = null
    this.state = {
      fetchState: ApiHelper.State.LOADING,
      selectedRelatedEvent: null,
      eventIndex: 0,
      eventLatest: null,
      eventEditor: false,
      eventListLoading: false,
      eventList: [],
      relativeImageSliderMode: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.fetchEventDetails();
  }

  reload() {
    this.setState({
      fetchState: ApiHelper.State.LOADING,
    }, () => this.fetchEventDetails())
  }

  fetchEventDetails() {
    ApiHelper.call(
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['camevents', this.eventId]) },
      function (err, result) {
        if (err !== null) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err)
          })
          return
        }
        if (result && result.cam) {
          this.liveUrl = result.cam.urlJpeg
        }

        if (result && result.place) {
          this.setBreadcrumbs(result.place, result.cam, result)
        }

        this.setState({
          place: result && result.place,
          cam: result && result.cam,
          selectedEvent: result.event,
          selectedRelatedEvent: null,
          eventIndex: 0,
          fetchState: ApiHelper.State.READY,
          eventCompleteList: result,
          camId: result.event && result.event.camId,
          eventListLoading: false,
        })
      }.bind(this))
  }

  setBreadcrumbs = (item, cam, currSelectedEvent) => {
    this.breadcrumbs = [
      {
        title: 'Places',
        to: '/orgs/' + item.orgId + '/places'
      },
      {
        title: 'Live Grid',
        to: '/places/' + item._id + '/cams'
      },
      {
        title: 'Events',
        to: '/places/' + item._id
      },
      {
        title: currSelectedEvent && currSelectedEvent.event && currSelectedEvent.event.name,
        to: ''
      }
    ]
  }

  onRelatedEventTileClick = (item) => {
    this.setState({
      selectedRelatedEvent: item
    })
  }

  closeEventEditor = () => {
    this.setState({
      eventEditor: false,
      selectedEditItem: {}
    })
  }

  showEventEditor = (item) => {
    let prioritySelected
    for (const p of adminData.priorityValues) {
      if (item.priority === p.value) {
        prioritySelected = p
      }
    }
    this.setState({
      eventEditor: true,
      selectedEditItem: item,
      prioritySelected: prioritySelected
    })
  }

  editEvent = (priority, mode) => {
    let options = {}
    if (mode === 'save') {
      options = {
        method: 'PUT',
        endPoint: ApiHelper.makeUrlPath(['camevents', this.state.selectedEditItem._id]),
        jsonBody: {
          priority: Number(priority)
        }
      }
    } else if (mode === 'delete') {
      options = {
        method: 'DELETE',
        endPoint: ApiHelper.makeUrlPath(['camevents', this.state.selectedEditItem._id]),
      }
    } else {
      return
    }

    ApiHelper.call(options, function (err, data) {
      if (err) {
        const msg = err.message ? err.message : Helper.getString('defaultErrorMsg')
        this.setState({
          errMsg: msg,
          errCode: err.respCode,
        })
        return
      }
      this.setState({
        eventEditor: false,
      }, () => this.reload())
    }.bind(this))
  };

  imageSliderHandler = (sliderMode) => {
    this.setState({
      relativeImageSliderMode: sliderMode
    })
  }

  closeEvent = () => {
    this.props.handleShowEvent(false)
  }

  render() {
    if (this.state.fetchState === ApiHelper.State.LOADING) {
      return UiHelper.componentLoadingView();
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      return UiHelper.errorView(this);
    } else {
      return this.readyView();
    }
  }

  readyView() {
    return (
      <div className={css(CommonStyles.outerMainContainer)}>
        {!this.props.orgLevel &&
          < EventActionHeaderbar
            title={this.state.place.name}
            breadcrumbs={this.breadcrumbs}
            hideFilter={true}
            hideDateRangePicker={true}
            place={this.state.place}
          />
        }

        {this.props.orgLevel &&
          <div className={css(Styles.reportBackContainer)}>
            <div onClick={() => this.closeEvent()} className={css(CommonStyles.headerMemberAddContainer, CommonStyles.backButton, Styles.noRightMargin, Styles.withBorder)} >
              <img src={require('../../assets/images/back-img.svg')} className={css(CommonStyles.addImg)} />
              <p className={css(CommonStyles.margin)}>BACK</p>
            </div>
          </div>
        }

        {(!this.state.eventCompleteList || !this.state.eventCompleteList.cam ||
          !this.state.eventCompleteList.event ||
          !this.state.eventCompleteList.place) &&
          <p className={css(Styles.emptyEventContainer)}>{Helper.getString('noEventsFound')}</p>
        }
        {
          (this.state.eventCompleteList &&
            this.state.eventCompleteList.cam &&
            this.state.eventCompleteList.event &&
            this.state.eventCompleteList.place) &&

          <div>
            <div className={css(CommonStyles.innerMainContainer)}>
              <div className={this.props.orgLevel ? css(Styles.innerContainer, Styles.fullWidth) : css(Styles.innerContainer)}>
                <EventDetails
                  independentEventDetails={true}
                  liveUrl={this.liveUrl}
                  placeId={this.state.cam && this.state.cam.placeId}
                  camId={this.state.camId}
                  eventsByGroupId={this.eventsByGroupId}
                  onRelatedEventTileClick={this.onRelatedEventTileClick}
                  imageSliderHandler={this.imageSliderHandler}
                  selectedEvent={this.state.selectedEvent}
                  selectedRelatedEvent={this.state.selectedRelatedEvent}
                  relativeImageSliderMode={this.state.relativeImageSliderMode}
                  eventIndex={this.state.eventIndex}
                  showEventEditor={this.showEventEditor}
                />
              </div>
            </div>
          </div>

        }
        {this.state.eventEditor &&
          <EventEditor
            eventEditor={this.state.eventEditor}
            closeEventEditor={this.closeEventEditor}
            selectedEditItem={this.state.selectedEditItem}
            editEvent={this.editEvent}
            prioritySelected={this.state.prioritySelected}
          />
        }

      </div>
    )
  }
}
