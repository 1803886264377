/* eslint-disable */ 
import React, { Component } from 'react';
import {
  css
} from 'aphrodite';
import { Styles } from './Styles'
import 'rc-slider/assets/index.css';
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import { Helper } from '../../common/helpers/Helper';
import { StatCardiFrame } from '../../common/components/StatCardiFrame';
import { ApiHelper } from '../../common/helpers/ApiHelper';
import { StatCard } from '../../components/StatCard';
import { CustomFormFieldsDialogV2 } from '../../components/CustomFormFieldsDialogV2';

export class StatsComponent extends Component {
  constructor(props) {
    super(props);
    this.parentAttributes = this.props.parentAttributes ? this.props.parentAttributes : {}
    this.parentAttributes = Helper.arrToDict(this.parentAttributes, 'id')
    this.responseByKey = {}
    this.state = {
      inProcess: false,
      statsDialog: false,
    }
  }

  componentWillMount() {
    this.setData()
  }

  setData = () => {
    this.stringTypeData = []
    this.iFrameTypeData = []
    for (const k in this.props.attributes) {
      if (this.parentAttributes[k] && this.parentAttributes[k].type === 'string') {
        this.props.attributes[k].order = this.parentAttributes[k].order
        this.props.attributes[k].id = this.parentAttributes[k].id
        this.stringTypeData.push(this.props.attributes[k])
      } else if (this.parentAttributes[k] && this.parentAttributes[k].type === 'iFrame') {
        this.props.attributes[k].order = this.parentAttributes[k].order
        this.iFrameTypeData.push(this.props.attributes[k])
      }
    }
    this.stringTypeData = this.stringTypeData.sort(function (a, b) { return a.order - b.order })
    this.iFrameTypeData = this.iFrameTypeData.sort(function (a, b) { return a.order - b.order })
  }

  onSubmit = () => {

    this.setState({
      inProcess: true,
      errMsg: null,
    })

    const data = {
      title: this.state.selectedItem.title,
      value: this.responseByKey.value,
      id: this.state.selectedItem.id
    }

    const body = {
      updateAction: 'updateAttributes',
    }

    const options = {
      method: 'PUT',
    }

    if (this.props.mode === 'org') {
      body.orgData = data
      options.endPoint = ApiHelper.makeUrlPath(['orgs', this.props.entityId])
    } else if (this.props.mode === 'place') {
      body.placeData = data
      options.endPoint = ApiHelper.makeUrlPath(['places', this.props.entityId])
    } else if (this.props.mode === 'cam') {
      body.camData = data
      options.endPoint = ApiHelper.makeUrlPath(['cams', this.props.entityId])
    }

    options.jsonBody = body

    //console.log('options', options)

    ApiHelper.call(options, function (err, data) {
      if (err) {
        const msg = err.message ? err.message : 'An error occurred, please try again. Contact customer care if problem persists.';
        this.setState({
          errMsg: msg,
          errCode: err.respCode,
          inProcess: false,
        })
        return
      } else {
        this.resetToDefaultValues()
        if(this.props.refresh) {
          this.props.refresh()
        }
      }
    }.bind(this))
  }

  onChange = e => {
    this.responseByKey.value = e.target.value
  }

  openStatDialog = (item, key) => {
    this.responseByKey.value = item.value
    this.setState({
      selectedItem: item,
      statDialog: true,
    })
  }

  closeStatDialog = () => {
    this.setState({
      statDialog: false,
    })
  }

  resetToDefaultValues = () => {
    this.responseByKey = {}
    this.setState({
      statDialog: false,
      inProcess: false,
      selectedItem: {},
    })
  }

  renderStatDialog = () => {
    const formFields = [
      {
        key: 'value',
        label: this.state.selectedItem.title,
        type: 'input_text',
        placement: 'left',
        required: { value: true, err: 'Provide a value' },
      }
    ]

    return (
      <CustomFormFieldsDialogV2
        onClose={this.resetToDefaultValues}
        dialogOpen={this.state.statDialog}
        dialogTitle={'Edit'}
        errMsg={this.state.errMsg}
        inProcess={this.state.inProcess}
        action={'edit'}
        formFields={formFields}
        // errMsg={this.state.errMsg}
        responseByKey={this.responseByKey}
        enableValidation={this.state.dialogAction === 'delete' ? false : true}
        onSuccess={this.onSubmit}
        formDisabled={this.state.dialogAction === 'delete' ? true : false}>
      </CustomFormFieldsDialogV2>
    )
  }

  getCardFooter = (k, key) => {
    return (
      <div className={css(Styles.statAdjustContainer)}>
        <div style={{ flex: 1 }}></div>
        <Fab
          color='primary'
          classes={{
            root: css(Styles.statAdjustIconContainer),
          }}
          aria-label='delete'
          size={'small'}
          onClick={() => this.openStatDialog(k, key)}>
          <Icon className={css(Styles.statAdjustIcon)}>edit</Icon>
        </Fab>
      </div>
    )
  }

  outerHeaderStatCardStyle = () => {
    if (!this.props.orgLevel) {
      return css(Styles.outerHeaderStatCard, Styles.outerHeaderStatCardSpec)
    } else {
      return css(Styles.outerHeaderStatCard)
    }
  }

  render = () => {
    this.setData()
    return (
      <SlideDown className={'my-dropdown-slidedown'}>

        {this.props.showStats ?
          <div className={this.outerHeaderStatCardStyle()}>
            <div className={css(Styles.statContainer, Styles.statContainerSpec)}>
              <div className={css(Styles.statContainerTitle)}>
                <div className={css(Styles.statTitleImg)}>
                  <img src={require('../../assets/images/summaryIcon.svg')} className={css(Styles.eventImage)} />
                </div>
                <p className={css(Styles.statTitle)}>Summary</p>
                <div className={css(Styles.statCollapse)}>
                  <Icon
                    onClick={() => this.props.showStatsHandler(!this.props.showStats)}
                    className={css(Styles.statsUpIcon)}>
                    keyboard_arrow_up
                  </Icon>
                </div>
              </div>

              <Grid container spacing={2} className={css(Styles.gridContainerVariant)}  >
                {
                  (this.stringTypeData && this.stringTypeData.length > 0) &&
                  this.stringTypeData.map((k, index) => {
                    return (
                      <StatCard
                        uniqueId={k.id}
                        config={this.parentAttributes}
                        text={k.title}
                        title={k.title}
                        subText={k.value}
                        cardIndex={index}
                        cardFooter={() => this.getCardFooter(k, k.id)} />
                    )
                  })
                }
              </Grid>

              <Grid container spacing={2} className={css(Styles.gridContainerVariant)} >
                {
                  (this.iFrameTypeData && this.iFrameTypeData.length > 0) &&
                  this.iFrameTypeData.map((k, index) => (
                    <StatCardiFrame
                      uniqueId={k.id}
                      config={this.parentAttributes}
                      text={k.title}
                      title={k.title}
                      subText={k.value}
                      cardIndex={index}
                      cardFooter={() => this.getCardFooter(k, k.id)} />
                  ))
                }
              </Grid>
            </div>

            {this.state.statDialog &&
              this.renderStatDialog()
            }
          </div>
          :
          null
        }
      </SlideDown>
    )
  }
}