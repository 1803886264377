/* eslint-disable */ 
import React, { Component } from 'react';

import { StyleSheet, css } from 'aphrodite';
import { CognitoAuth } from 'amazon-cognito-auth-js';
import { AppConfig } from '../AppConfig';
import Button from '@material-ui/core/Button';
import { UiHelper } from '../common/helpers/UiHelper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Controller } from '../common/Controller';


export class CallbackHandler extends Component {
  constructor(props) {
    super(props);
    const curUrl = window.location.href;
    console.log('test-3')
    Controller.get().loginMgr()._initAuthSdk((err, session) => {
      if(err) {
        console.log('err', err)
      }
      console.log('test-8')
      Controller.get().userMgr().navigateUserToHome();
    })
    Controller.get().loginMgr().parseCognitoWebResponse(curUrl)
    this.state = {
      fetchState: ApiHelper.State.LOADING,
    }
  }

  render() {
    if (this.state.fetchState === ApiHelper.State.LOADING) { //TODO Should it change to custom loader
      return UiHelper.componentLoadingView();
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      return UiHelper.componentErrorView(this);
    } else {
      return this.readyView();
    }
  }
}


const Styles = StyleSheet.create({
  nfMiddle: {
    fontSize: 20,
    paddingTop: '15%',
    textAlign: 'center'
  }
})