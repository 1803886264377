/* eslint-disable */ 
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import XLSX from 'xlsx';
import Button from '@material-ui/core/Button';
import { ApiHelper } from '../helpers/ApiHelper';
import { Helper } from '../helpers/Helper';
import { ValidationHelper } from '../../helpers/ValidationHelper';
import { AppTheme } from '../../styles/AppTheme';
import { ColourPalette } from '../../styles/AppColourPalette';

export class ExcelDocUploader extends Component {
  constructor(props) {
    super(props);
    this.defaultErrMsg = 'An error occured.'
    this.dataList = []
    this.state = {
      isValidData: false,
      message: '',
      fetchState: ApiHelper.State.READY
    };
  }

  componentDidMount() { }

  onUpload() {
    this.props.onExcelValidationSuccess(this.dataList)
  }

  validateExcelData = (e, callback) => {
    if (!e ||
      !e.target ||
      !e.target.files ||
      e.target.files.length === 0) {
      return callback(false, this.defaultErrMsg)
    }

    const reader = new FileReader();
    reader.readAsArrayBuffer(e.target.files[0]);
    reader.onload = () => {
      const wb = XLSX.read(reader.result, { type: 'array' });
      if (!wb || !wb.SheetNames || wb.SheetNames.length === 0) {
        return callback(false, this.defaultErrMsg)
      }
      const firstSheetName = wb.SheetNames[0];
      const worksheet = wb.Sheets[firstSheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      let err;
      if (excelData && excelData.length > 1000) {
        err = 'Maximum of 1000 entries is only allowed!'
      } else {
        if (this.props.uploadType == "Cams") {
          err = ValidationHelper.customCamExcelValidation(this.props.orgType, excelData);
        }
        else {
          err = ValidationHelper.customExcelValidation(excelData);
        }
      }
      if (!err) {
        this.dataList = excelData;
        callback(true, err)
      } else {
        callback(false, err);
      }
    }
  }

  onFileChange(e) {
    const file = e.target.files[0]
    const reader = new FileReader();
    this.validateExcelData(e, (valid, errMsg) => {
      if (!valid) {
        return this.onError(errMsg);
      } else {
        reader.onload = (e) => {
          this.setState(
            {
              file: file,
              message: '',
              fetchState: ApiHelper.State.READY,
              isValidData: true,
            }
          )
        }
        reader.readAsDataURL(file)
      }
    })
  }

  onError(err) {
    this.setState({
      message: Helper.getErrorMsg(err),
      fetchState: ApiHelper.State.ERROR
    })
  }

  renderMessage() {
    let messageClass = css(Styles.textUpload, Styles.text)
    if (this.state.fetchState === ApiHelper.State.ERROR) {
      messageClass = css(Styles.textError, Styles.text)
    } else if (this.state.fetchState === ApiHelper.State.READY) {
      messageClass = css(Styles.textSuccess, Styles.text)
    }
    return (<div className={messageClass}>{this.state.message}</div>)
  }

  render() {
    return (
      <div className={css(Styles.container)}>
        <input
          className={css(Styles.fileInput)}
          onChange={(e) => this.onFileChange(e)}
          onClick={(e) => e.target.files[0] && this.onFileChange(e)}
          type='file'
          name='file'
          accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' />
        <div className={css(Styles.buttonContainer)}>
          <Button
            className={css(Styles.button)}
            size='small'
            disabled={!this.state.isValidData}
            onClick={this.onUpload.bind(this)}>
            {'Upload'.toUpperCase()}
          </Button>
          {this.renderMessage()}
        </div>
      </div>
    )
  }
}

const Styles = StyleSheet.create({
  container: {
    // backgroundColor: AppTheme.primaryBackground,
    padding: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    '@media (max-width: 767px)': {
      paddingTop: 10,
      paddingBottom: 10
    }
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 5
  },
  fileInput: {
    width: '100%',
    color: "#000"
  },
  button: {
    maxWidth: 100,
    minHeight: 24,
    textAlign: 'center',
    fontSize: 10,
    marginRight: 10,
    backgroundColor: ColourPalette.color3,
    borderRadius: 4,
    color: "#000"
  },
  text: {
    fontSize: 10,
    whiteSpace: 'pre-line',
    // backgroundColor: AppTheme.primaryBackground,
  },
  textUpload: {
    color: "#000"
  },
  textError: {
    color: "#000",
    maxHeight: 300,
    maxWidth: 300,
    overflowY: 'auto',
    // backgroundColor: AppTheme.primaryBackground
  },
  textSuccess: {
    color: 'green'
  }
})