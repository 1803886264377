/* eslint-disable */ 
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppTheme } from '../../styles/AppTheme';


export class StatCardiFrame extends Component {
  constructor(props) {
    super(props);
    this.defaultErrMsg = 'An error occured.'
    this.state = {
      inProcess: true
    };
  }

  onLoad = (e) => {
    this.setState({
      inProcess: false
    })
  }

  render() {
    return (
      <Grid item xs={12} sm={6} className={css(Styles.container)}>
        <div className={css(Styles.paperTitle)} elevation={0}>
          <p className={css(Styles.paragraphMargin)}>{this.props.title}</p>
          {this.props.cardFooter && (this.props.uniqueId && this.props.config[this.props.uniqueId] && this.props.config[this.props.uniqueId].isEditable) &&
            <div className={css(Styles.paperFooter)} elevation={0}>
              {this.props.arrow === 'down' && (
                <i class={'material-icons ' + css(Styles.arrow, Styles.arrowDown)}>keyboard_arrow_down</i>
              )}
              {this.props.arrow === 'up' && <i class={'material-icons ' + css(Styles.arrow, Styles.arrowUp)}>keyboard_arrow_up</i>}
              {this.props.cardFooter()}
            </div>
          }
        </div>

        <div>
          {this.state.inProcess &&
            <div className={css(Styles.loader)}>
              <CircularProgress size={48} className={css(Styles.progress)} />
            </div>
          }
          <iframe
            allow='autoplay'
            allowFullScreen={true}
            className={css(Styles.videoContainerTile)}
            width='100%'
            height={400}
            onLoad={() => this.onLoad()}
            src={this.props.subText}
            frameBorder='0' />
        </div>
      </Grid>
    );
  }
};

const Styles = StyleSheet.create({
  container: {
    color: '#474747',
    backgroundColour: 'red',
  },
  paperFooter: {
    marginBottom: 0,
    display: 'flex',
    verticalAlign: 'top',
    justifyContent: 'flex-end'
  },
  paperTitle: {
    fontWeight: 'bold',
    color: AppTheme.white,
    opacity: 0.6,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center'
  },
  subText: {
    display: 'inline-block',
    fontSize: 24,
    fontWeight: 'bold',
    flex: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    cursor: 'default',
    '@media(max-width: 768px)': {
      fontSize: 12
    }
  },
  arrowUp: {
    color: 'green'
  },
  arrowDown: {
    color: 'red'
  },
  videoContainerTile: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paragraphMargin: {
    margin: 0,
    flex: 1,
    cursor: 'default',
    '@media(max-width: 767px)': {
      fontSize: 14
    }
  },
  progress: {
    color: AppTheme.white
  },
  loader: {
    height: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});
