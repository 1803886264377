/* eslint-disable */ 
import React, { Component } from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { StyleSheet, css } from 'aphrodite';
import { AppTheme } from '../../styles/AppTheme';
import TextField from '@material-ui/core/TextField';

export class NumericOTPInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <FormControl fullWidth >
        <InputLabel className={css(Styles.inputLabelCss)} htmlFor='OTP'>Verification code</InputLabel>
        <Input {...this.props} type='password' />
      </FormControl>
    )
  }
}

export class PasswordInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <FormControl fullWidth className={css(Styles.loginInput)}>
        <TextField
          placeholder={'Enter password'}
          type='password'
          {...this.props}
          className={css(Styles.inputCss)}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: css(Styles.textFieldContentInput),
              input: css(Styles.inputText),
            }
          }}
          InputLabelProps={{
            classes: {
              root: css(Styles.textFieldContent),
            }
          }}
        />
      </FormControl>
    )
  }
}

export class UsernameInput extends Component {
  constructor(props) {
    super(props);
    this.userNameType = this.props.userNameType
  }

  render() {
    return (
      <FormControl fullWidth className={css(Styles.loginInput)}>
        <TextField
          placeholder={'Enter username'}
          {...this.props}
          className={css(Styles.inputCss)}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: css(Styles.textFieldContentInput),
              input: css(Styles.inputText),
            }
          }}
          InputLabelProps={{
            classes: {
              root: css(Styles.textFieldContent),
            }
          }}
        />
      </FormControl>
    )
  }
}

const Styles = StyleSheet.create({
  loginInput: {
    marginTop: 20,
  },

  inputCss: {
    flex: 1,
    // color: AppTheme.white
    color:"#000"
  },
  textFieldContentInput: {
    borderRadius: 4,
    // backgroundColor: AppTheme.secondaryColor,
    backgroundColor:"rgb(238, 238, 238) !important",
    color:"#000",
    paddingLeft: 8,
    // color: AppTheme.white,
  },
  textFieldContent: {
    // color: AppTheme.white,
    zIndex: 1,
    paddingLeft: 8,
    opacity: 1,
    color:'#000'
  },
  inputText: {
    textAlign: 'center'
  },
  inputLabelCss: {
    // color: AppTheme.white
  }
})