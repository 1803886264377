import React, { Component } from 'react';
import {
  css
} from 'aphrodite';
import async from 'async';

import { Controller } from '../../common/Controller';
import { UiHelper } from '../../common/helpers/UiHelper';
import { ApiHelper } from '../../common/helpers/ApiHelper';
import { Helper } from '../../common/helpers/Helper';
import { orgData } from './Data';
import { Stats } from '../../components/Stats'

import { Styles } from './Styles'
import { CommonStyles } from '../../styles/Styles';
import { ActionHeaderbar } from '../../components/ActionHeaderbar';
import { PopOverV2 } from '../../components/PopOverV2';
import { Vehicle } from '../../components/Vehicle'

export class GuestUsers extends Component {
  constructor(props) {
    super(props);
    this.orgId = this.props.match.params ? this.props.match.params.id : null;
    this.userId = Controller.get().userMgr().getUserId();
    this.responseByKey = {}
    this.state = {
      addOwnDialog: false,
      editOwnDialog: false,
      addGuestVehicleDialog: false,
      dialogAction: '',
      dialogTitle: '',
      selectedItem: {},
      anchorEl: null,
      confirmDeleteView: false,
      inProcess: false,
      items: [],
      logoUrl: '',
      fetchState: ApiHelper.State.LOADING,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.fetchItems();
  }

  fetchItems = () => {
    const queryParams = { userId: this.userId, orgId: this.orgId }
    const orgId = this.orgId
    async.parallel(
      [
        function (callback) {
          ApiHelper.call(
            { method: 'GET', endPoint: ApiHelper.makeUrlPath(['vehicles'], queryParams) }, callback)
        },
        function (callback) {
          ApiHelper.call(
            { method: 'GET', endPoint: ApiHelper.makeUrlPath(['orgs', orgId]) }, callback)
        }
      ],
      function (err, results) {
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err)
          })
          return
        }

        this.org = results[1]
        if (results[0] && results[0].length > 0) {
          for (const f of results[0]) {
            f.lp = f.lp.toUpperCase();
            if (f.type === "GUEST") {
              f.name = f.guestInfo && f.guestInfo.name;
              f.email = f.guestInfo && f.guestInfo.email;
              f.phone = f.guestInfo && f.guestInfo.phone;
            } else {
              f.name = f.ownerInfo && f.ownerInfo.name;
              f.email = f.ownerInfo && f.ownerInfo.email;
              f.phone = f.ownerInfo && f.ownerInfo.phone;
            }

            if (f.expireAt) {
              f.expireAt = new Date(f.expireAt).toString();
            } else {
              f.expireAt = "NA";
            }
          }
        }
        this.setState({
          items: results[0],
          logoUrl: this.org.logoUrl,
          fetchState: ApiHelper.State.READY
        })
      }.bind(this))
  }

  resetToDefaultValues = () => {
    this.responseByKey = {}
    this.setState({
      updateItemDialog: false,
      addOwnDialog: false,
      inProcess: false,
      dialogAction: '',
      dialogTitle: '',
      selectedItem: {},
      anchorEl: null,
      confirmDeleteView: false,
    })
  }

  showAddOwnVehicleDialog = () => {
    this.setState({
      addOwnDialog: true,
      dialogTitle: 'Add Own Vehicle',
      dialogAction: 'add',
    })
  }

  showAddGuestVehicleDialog = () => {
    this.setState({
      addGuestVehicleDialog: true,
      dialogTitle: 'Add Guest Vehicle',
      dialogAction: 'add',
    })
  }

  showConfirmDeleteView = () => {
    this.setState({
      confirmDeleteView: true,
      inProcess: false
    })
  }

  showDeleteItemDialog = () => {
    this.responseByKey = this.state.selectedItem
    if (this.state.selectedItem.ownerInfo) {
      this.responseByKey.name = this.state.selectedItem.ownerInfo.name
      this.responseByKey.email = this.state.selectedItem.ownerInfo.email
      this.responseByKey.phone = this.state.selectedItem.ownerInfo.phone
    }
    this.setState({
      anchorEl: null,
      updateItemDialog: true,
      dialogTitle: 'Edit Member Details',
      dialogAction: 'delete',
    })
  }

  showUpdateMemberDialog = () => {
    this.responseByKey = this.state.selectedItem
    this.setState({
      anchorEl: null,
      updateItemDialog: true,
      dialogTitle: 'Edit Member Details',
      dialogAction: 'edit',
    })
  }

  onPopOverClick = (key) => {
    if (key === 'edit') {
      this.showUpdateMemberDialog()
    } else if (key === 'delete') {
      this.showDeleteItemDialog()
    }
  }

  editClick = (e, item) => {
    this.setState({
      anchorEl: e.currentTarget,
      selectedItem: item
    })
  }

  editClickClose = () => {
    this.setState({ anchorEl: null })
  }

  setValue = (value, key) => {
    this.responseByKey[key] = value
  }

  setUser = (item) => {
    if (item) {
      this.setState({
        selectedItem: item,
        updateItemDialog: true,
        dialogAction: 'edit',
        dialogTitle: 'Edit Member Details',
      })
    } else {
      this.setState({
        selectedItem: item,
        updateItemDialog: true,
        dialogAction: 'add',
        dialogTitle: 'Add a Member',
      })
    }
  }

  openDialog = (type , func) => {
    console.log(type, func)
    if(func === 'edit') {
      this.setState({
        dialogAction: 'edit',
        dialogTitle: "Edit Vehicle",
      });
      if (type === "guest") {
        this.setState({
          addGuestVehicleDialog: true,
        });
      } else {
        this.setState({
          addOwnDialog: true,
        });
      }
    }

    if(func === 'delete') {
      this.setState({
        dialogAction: "delete",
        dialogTitle: "Delete Vehicle",
      });
      if (type === "guest") {
        this.setState({
          addGuestVehicleDialog: true,
        });
      } else {
        this.setState({
          addOwnDialog: true,
        });
      }
    }
  }
  
  closeDialog = () => {
    this.setState({
      addGuestVehicleDialog: false,
      addOwnDialog: false,
      dialogAction: "",
      dialogTitle: "",
    })
  }

  render() {
    if (this.state.fetchState === ApiHelper.State.LOADING) {
      return UiHelper.componentLoadingView();
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      return UiHelper.errorView(this);
    } else {
      return this.readyView();
    }
  }

  readyView = () => {
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <div className={css(CommonStyles.outerMainContainer)}>
        <ActionHeaderbar
          inProcess={this.state.inProcess}
          title={this.org.name}
          items={this.state.items}
          buttonOneAction={this.showAddOwnVehicleDialog}
          buttonOneTitle={'ADD OWN VEHICLE'}
          buttonTwoAction={this.showAddGuestVehicleDialog}
          buttonTwoTitle={'ADD GUEST VEHICLE'}
          statPrefix={'Guests'}
          statSuffix={this.state.items.length > 1 ? 'Members' : 'Member'}
        />

        <div className={css(CommonStyles.innerMainContainer)}>
          <div className={css(Styles.innerMainContent)}>
            <Stats
              title={this.org.name}
              logoUrl={this.state.logoUrl}
              guests={this.state.items}
            />

            {this.state.items &&
              <Vehicle
               vehicles={this.state.items}
               showGuestVehicleDialog={this.state.addGuestVehicleDialog}
               showOwnVehicleDialog={this.state.addOwnDialog}
               dialogTitle={this.state.dialogTitle}
               dialogAction={this.state.dialogAction}
               openDialog={this.openDialog}
               closeDialog={this.closeDialog}
               fetchItems={this.fetchItems}
               orgId={this.orgId}
              />
            }

          </div>
        </div>

        <PopOverV2
          id={id}
          open={open}
          items={orgData.popOverFields}
          anchorEl={this.state.anchorEl}
          onPopOverClick={this.onPopOverClick}
          editClickClose={this.editClickClose}
        />
      </div>
    )
  }
}