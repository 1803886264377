/* eslint-disable */ 
import React, { Component } from 'react';
import { css } from 'aphrodite';
import { Styles } from './Styles';
import { CommonStyles } from '../../styles/Styles';
import Tooltip from '@material-ui/core/Tooltip';

export class EventDownloadReport extends Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <Tooltip title='DOWNLOAD EVENT REPORTS'>
        <div
          onClick={() => this.props.downloadReport()}
          className={css(CommonStyles.headerMemberAddContainer, Styles.headerMemberStyle, Styles.headerMemberAddContainerSecondSpecific)}>
          <img src={require('../../assets/images/download-img1.svg')} className={css(Styles.filterIcon)} />
          {/*<p className={css(Styles.switchControlLabel)}>DOWNLOAD</p>*/}
        </div>
      </Tooltip>
    )
  }
}