/* eslint-disable */ 
import { CognitoMgr } from './CognitoMgr';
import { AuthenticationDetails, CognitoUser, CognitoUserSession, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { CognitoAuth } from 'amazon-cognito-auth-js';
import { Controller } from '../Controller';
import { ApiHelper } from '../helpers/ApiHelper';
import { AuthError } from '../errors';
import { ApiError } from '../errors';
import { Helper } from '../helpers/Helper';
import { AppConfig } from '../../AppConfig';

export class LoginMgr {

  constructor() {
    this.cognitoMgr = new CognitoMgr();
    this.auth = null
  }

  bootstrap(callback) {
    if (this.getCurrentUser()) {
      this.fetchUserToken(this.getCurrentUser(), function (err) {
        if (err) callback(new AuthError(Helper.getString('authenticationErr'), err));
        this._fetchAppUser(callback);
      }.bind(this));
    } else {
      callback(null, null)
    }
  }

  signUp(newUserId, phone, email, password, callback) {
    const attributeList = [];
    if (phone) {
      const dataPhoneNumber = {
        Name: 'phone_number',
        Value: phone
      };
      const attributePhoneNumber = new CognitoUserAttribute(dataPhoneNumber);
      attributeList.push(attributePhoneNumber);
    }

    if (email) {
      const dataEmail = {
        Name: 'email',
        Value: email
      };
      const attributeEmail = new CognitoUserAttribute(dataEmail);
      attributeList.push(attributeEmail);
    }

    this.cognitoMgr.getUserPool().signUp(newUserId, password, attributeList, null, function (err, result) {
      if (err) {
        const action = this._nextAction(err);
        if (action) {
          callback(null, action);
        } else {
          callback(new AuthError(Helper.getString('signupFailire'), err));
        }
        return;
      }
      callback(null, {
        code: 'SUCCESS',
        message: Helper.getString('otpSent')
      });
    }.bind(this));
  }

  confirmRegistration(newUserId, verificationCode, callback) {
    const cognitoUser = new CognitoUser({
      Username: newUserId,
      Pool: this.cognitoMgr.getUserPool()
    });

    cognitoUser.confirmRegistration(verificationCode, false, function (err, result) {
      if (err) {
        const action = this._nextAction(err);
        if (action) {
          callback(null, action);
        } else {
          callback(new AuthError(Helper.getString('authenticateUserFailire'), err));
        }
        return;
      }
      callback(null, {
        code: 'SUCCESS'
      });
    }.bind(this));
  }

  signIn(username, password, callback) {
    const thisObj = this
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.cognitoMgr.getUserPool()
    });
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (session, userConfirmationNecessary) {
        //console.log('authenticateUser onSuccess:', userConfirmationNecessary);
        thisObj._storeToken(session);
        thisObj._fetchAppUser(function (err, data) {
          if (err) {
            callback(err); return;
          }
          callback(null, { code: 'SUCCESS' });
        });
      },
      onFailure: function (err) {
        //console.log('authenticateUser: onFailure:', err);
        const action = thisObj._nextAction(err);
        if (action) {
          callback(null, action);
        } else {
          callback(new AuthError(Helper.getString('authenticateUserFailire'), err));
        }
      },
      newPasswordRequired: function (userAttributes, requiredAttributes) {
        //console.log('newPasswordRequired:', userAttributes, requiredAttributes);
        callback(null, {
          code: 'NEW_PASSWORD_REQUIRED',
          message: Helper.getString('setNewPassword'),
          receivedUserAttributes: userAttributes,
          cognitoUser: cognitoUser // required for the session
        }
        );
      },
      mfaRequired: function (challengeName, challengeParameters) {
        //console.log('newPasswordRequired:', challengeName, challengeParameters);
        callback(new AuthError(Helper.getString('mfaRequired')));
      },
      customChallenge: function (challengeParameters) {
        //console.log('newPasswordRequired:', challengeParameters);
        callback(new AuthError(Helper.getString('customChallenge')));
      }
    });
  }

  getCurrentUser() {
    return this.cognitoMgr.getCurrentUser();
  }

  parseCognitoWebResponse(curUrl) {
    this.auth.parseCognitoWebResponse(curUrl);
  }

  fetchUserToken(cognitoUser, callback) {
    if (!cognitoUser) {
      callback(new AuthError(Helper.getString('userNull')));
    }

    cognitoUser.getSession(function (err, session) {
      if (err) {
        //console.log('getSession error:', err);
        // callback(new AuthError(Helper.getString('getSessionErr'), err));
        Controller.get().logout()
        callback(null);
        return
      }
      if (!session.isValid()) {
        callback(new AuthError(Helper.getString('sessionInValid')))
        return
      }
      //console.log('Valid Token ready:');
      //let curTime = Date.now() / 1000
      //console.log('Access Token Expiry (s):', session.accessToken.payload.exp - curTime);
      //console.log('Id Token Expiry (s):', session.idToken.payload.exp - curTime);
      this._storeToken(session);
      callback(null);
    }.bind(this));
  }

  getSession() {
    if(!this.auth && AppConfig.SSO) {
      this._initAuthSdk()
    }
    this.auth.getSession()
  }

  signOut() {
    if(!this.auth && AppConfig.SSO) {
      this._initAuthSdk()
      this.auth.signOut()
    } else if(this.auth) {
      this.auth.signOut()
    }
    const cognitoUser = this.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          console.log('cognitoUser.getSession' + err);
        }
        else {
          Controller.get().loginMgr()._storeToken(session);
          let token = Controller.get().userMgr().getUserToken();
          const options = {
            endPoint: ApiHelper.makeUrlPath(['miscs', 'logout']),
            jsonBody: {
              token: token
            },
            method: 'POST'
          }
          ApiHelper.call(options, function (err, data) {
            if (err) {
              console.log('ApiHelper.Logout', err)
            }
          })
          cognitoUser.setSignInUserSession(session)
          cognitoUser.globalSignOut({
            onSuccess: function () {
              console.log("Logout success")
            },
            onFailure: function (err) {
              console.log("Logout failure", err)
            }
          })
          cognitoUser.signOut();
        }
      });
    }
    this._storeToken(null);
  }

  localSignout = () => {
    this.user = Controller.get().userMgr()
    ApiHelper.call(
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['miscs/locallogout', this.user.appUser._id], { locallogout: true }) },
      function (err, result) {
        if (err) { } else {
        }
      }.bind(this))
  }

  forgotPassword(username, callback) {
    const thisObj = this
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.cognitoMgr.getUserPool()
    });
    cognitoUser.forgotPassword({
      onSuccess: function (data) {
        //console.log('forgotPassword onSuccess:', data);
        callback(null, {
          code: 'SUCCESS',
          message: Helper.getString('passwordResetSent')
        });
      },
      onFailure: function (err) {
        //console.log('forgotPassword onFailure:', err);
        const action = thisObj._nextAction(err);
        if (action) {
          callback(null, action);
        } else {
          callback(new AuthError(Helper.getString('authenticateUserFailire'), err));
        }
      },
      inputVerificationCode: function (data) {
        //console.log('forgotPassword inputVerificationCode:', data);
        callback(null, {
          code: 'SUCCESS',
          message: Helper.getString('passwordResetSentSpecific') + data.CodeDeliveryDetails.Destination
        }
        );
      }
    });
  }

  confirmResetPassword(verificationCode, username, password, callback) {
    const thisObj = this
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.cognitoMgr.getUserPool()
    });

    cognitoUser.confirmPassword(verificationCode, password, {
      onSuccess: function () {
        callback(null, {
          code: 'SUCCESS',
          message: Helper.getString('passwordRestLogin')
        });
      },
      onFailure: function (err) {
        //console.log('confirmPassword onFailure:', err);
        const action = thisObj._nextAction(err);
        if (action) {
          callback(null, action);
        } else {
          callback(new AuthError(Helper.getString('authenticateUserFailire'), err));
        }
      }
    });
  }

  completeNewPasswordChallenge(username, newPassword, cognitoUser, userAttributes, callback) {
    const thisObj = this

    // the api doesn't accept this field back
    delete userAttributes.email_verified;
    delete userAttributes.sms_verified;

    cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
      onSuccess: function (session) {
        callback(null, {
          code: 'SUCCESS',
          message: Helper.getString('passwordSetLogin')
        });
      },
      onFailure: function (err) {
        //console.log('completeNewPasswordChallenge onFailure:', err);
        const action = thisObj._nextAction(err);
        if (action) {
          callback(null, action);
        } else {
          callback(new AuthError(Helper.getString('completeNewPasswordChallengeFailire'), err));
        }
      },
      mfaRequired: function (challengeName, challengeParameters) {
        //console.log('completeNewPasswordChallenge:', challengeName, challengeParameters);
        callback(new AuthError(Helper.getString('completeNewPasswordChallengeMfaRequired')));
      },
      customChallenge: function (challengeParameters) {
        //console.log('completeNewPasswordChallenge:', challengeParameters);
        callback(new AuthError(Helper.getString('completeNewPasswordChallengeCustomChallenge')));
      }
    });
  }

  restoreLoginSession(callback) {
    this.cognitoMgr.getUserPool().storage.sync(callback);
  }

  _storeToken(session) {
    if (session) {
      Controller.get().userMgr().setUserToken(session.getAccessToken().getJwtToken());
    } else {
      Controller.get().userMgr().setUserToken(null);
      Controller.get().userMgr().setAppUserCloud(null);
      Controller.get().userMgr().signOut()
    }
  }

  _fetchAppUser(callback) {
    let userEndpoint = ApiHelper.makeUrlPath(['users', 'me']);
    let currentOrg = Controller.get().userMgr().currentOrg;

    if (currentOrg) {
      userEndpoint = ApiHelper.makeUrlPath(['users', 'me'], { orgId: currentOrg._id });
    }

    ApiHelper.call({ method: 'GET', endPoint: userEndpoint }, function (err, data) {
      if (err) {
        callback(new ApiError(err)); return;
      }
      //Controller.get().userMgr().setAppUser(data);
      Controller.get().userMgr().setAppUserCloud(data)
      callback(null, data)
    })
  }

  _nextAction(err) {
    //console.log('err', err);
    if (err.code === 'UserNotFoundException') {
      return {
        code: 'USER_NOT_FOUND',
        message: Helper.getString('userNotFoundException')
      }
    } else if (err.code === 'NotAuthorizedException') {
      return {
        code: 'WRONG_CREDENTIALS',
        message: Helper.getString('notAuthorizedException')
      }
    } else if (err.code === 'CodeMismatchException') {
      return {
        code: 'WRONG_VERIFICATION_CODE',
        message: Helper.getString('codeMismatchException')
      }
    } else if (err.code === 'InvalidPasswordException') {
      return {
        code: 'PASSWORD_NOT_AS_PER_POLICY',
        message: Helper.getString('invalidPasswordException')
      }
    } else if (err.code === 'InvalidParameterException') { // Short passwords trigger this, should have been InvalidPasswordException
      return {
        code: 'PASSWORD_NOT_AS_PER_POLICY',
        message: Helper.getString('invalidPasswordException')
      }
    } else if (err.code === 'NetworkingError') {
      return {
        code: 'NETWORKING_ERROR',
        message: Helper.getString('networkingError')
      }
    } else if (err.code === 'AliasExistsException') {
      return {
        code: 'USER_EXISTS',
        message: Helper.getString('aliasExistsException')
      }
    } else if (err.code === 'UsernameExistsException') {
      return {
        code: 'USER_EXISTS',
        message: Helper.getString('usernameExistsException')
      }
    } else {
      return {
        code: 'INTERNAL_ERROR',
        message: Helper.getString('internalError')
      }
    }
  }

  _initAuthSdk(callback) {
    console.log('_initAuthSdk.....')
    if(this.auth) return callback(null, this.auth.getCachedSession())
    const authData = {
      ClientId: AppConfig.COGNITO_USER_POOL_CLIENT_ID,
      AppWebDomain: AppConfig.SSO ? AppConfig.SSO.COGNITO_LOGIN_DOMAIN : null,
      TokenScopesArray: AppConfig.SSO ? AppConfig.SSO.TOKEN_SCOPES_ARR : [],
      RedirectUriSignIn: AppConfig.SSO ? AppConfig.SSO.REDIRECT_URI_SIGNIN : null,
      RedirectUriSignOut: AppConfig.SSO ? AppConfig.SSO.REDIRECT_URI_SIGNOUT : null,
      IdentityProvider: AppConfig.SSO ? AppConfig.SSO.IDP_PROVIDER : null,
      UserPoolId: AppConfig.COGNITO_USER_POOL,
    };

    this.auth = new CognitoAuth(authData);

    const thisObj = this;
    this.auth.userhandler = {
      onSuccess: function (session) {
        console.log('Sign in success', session);
        thisObj._storeToken(session);
        return thisObj._fetchAppUser(callback)
      },
      onFailure: function (err) {
        console.log('Sign in failure', err);
        return callback(err)
      }
    };
    this.auth.useCodeGrantFlow();
  }
}
