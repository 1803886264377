import { parseNumber, formatNumber } from 'libphonenumber-js'

export class PhoneNumberHelper {
  static formatNumber(number, defaultCountry) {
    const parsed = parseNumber(number, defaultCountry);
    if (!parsed || !parsed.country || !parsed.phone) {
      return null
    }
    //console.log('parsed number:', parsed);
    return formatNumber(parsed, 'E.164');
  }
}