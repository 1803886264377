import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import AvatarEditor from 'react-avatar-editor'
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ApiHelper } from '../common/helpers/ApiHelper'
import { Helper } from '../common//helpers/Helper';

import { AppTheme } from '../styles/AppTheme';
import { UploadHelper } from '../common/helpers/UploadHelper';
import { ColourPalette } from '../styles/AppColourPalette'
import Icon from '@material-ui/core/Icon';

const RESIZE_DEFAULT = 512
export class ImageUploaderCustom extends Component {
  constructor(props) {
    super(props);
    this.width = this.props.width || RESIZE_DEFAULT
    this.height = this.props.height || RESIZE_DEFAULT
    this.borderRadius = (this.props.borderRadius === 0) ? 0 : RESIZE_DEFAULT / 2
    this.currentUrl = this.props.url
    this.state = {
      message: '',
      fetchState: ApiHelper.State.READY,
      files:[]
    };
  }

  componentDidMount() {
  }

  render() {
    const ratio = this.width / this.height
    let height = 65
    let width = height * ratio
    if (width > 200) { // too wide
      width = 200
      height = width / ratio
    }
    const EditorStyles = StyleSheet.create({
      editor: {
        backgroundColor: 'white',
        height,
        width
      },
    })
    return (
      <div className={css(Styles.container, this.props.imageUploaderContainer)}>
        <div className={css(Styles.imgContainer)}>
          <AvatarEditor
            ref={this.setEditorRef.bind(this)}
            className={css(EditorStyles.editor)}
            image={this.state.dataURL || this.currentUrl}
            width={this.width}
            height={this.height}
            border={1}
            borderRadius={this.borderRadius}
            color={[0, 0, 0, 0.5]} // RGBA
          //scale={1}
          //rotate={0}
          />
        </div>
        <div className={css(Styles.textContainer)}>
          <input
            //className={css(Styles.fileInput)}
            id='contained-button-file'
            onChange={this.onFileChange.bind(this)}
            type='file'
            // multiple={this.props.multi ? true:false}
            style={{ display: 'none' }}
            accept='image/png, image/jpeg' />
          {/*<Button
          className={css(Styles.textButton)}
          size='small'
          onClick={this.onUpload.bind(this)}>
          {this.state.buttonState === 'loading' ?
            <CircularProgress size={24} className={css(Styles.progressIcon)} />
            :
            'Upload'.toUpperCase()
          }
        </Button>*/}
          <label htmlFor='contained-button-file'>
            <div className={css(Styles.attachFile)}>
              <p>Attach file</p>
              <Icon className={css(Styles.searchIcon)}>attach_file</Icon>
              {this.renderShowFileName()}
            </div>
            {/*<IconButton
            color='primary'
          >
            <i className='material-icons'>attach_file</i>
          </IconButton>*/}
          </label>
          <div className={css(Styles.actionButtons)}>
            <Button
              variant='contained'
              component='span'
              onClick={this.onUpload.bind(this)}
              className={css(Styles.upadateActions)}>
              Upload
              {this.props.inProcess &&
                <CircularProgress size={24} className={css(Styles.marginLeft)} />
              }
            </Button>
            {/* <Button
            variant='contained'
            component='span'
            onClick={this.handleUploadDialogClose}
            className={css(Styles.cancelActions)}>
            Cancel
          </Button> */}
          </div>
          {this.renderMessage()}
        </div>
      </div>
    )
  }

  renderShowFileName() {
    if (this.state.file &&
      this.state.file.name) {
      return (
        <p className={css(Styles.fileName)}>{this.state.file.name}</p>
      )
    }
  }
  renderMessage() {
    let messageClass = css(Styles.textUpload, Styles.text)
    if (this.state.fetchState === ApiHelper.State.ERROR) {
      messageClass = css(Styles.textError, Styles.text)
    } else if (this.state.fetchState === ApiHelper.State.READY) {
      messageClass = css(Styles.textSuccess, Styles.text)
    }
    return (<p className={messageClass}>{this.state.message}</p>)
  }

  setEditorRef(editor) {
    this.editor = editor
  }

  onUpload() {
    if (!this.editor) {
      return this.onError('Unable to edit the image')
    }
    if (!this.state.file) {
      return this.onError('Choose a file')
    }
    this.setState(
      {
        message: '',
        fetchState: ApiHelper.State.READY
      }
    )
    const scaledCanvas = this.editor.getImageScaledToCanvas()
    scaledCanvas.toBlob((blob) => {
      const options = {
        type: this.props.uploadType || 'media',
        ext: this.props.uploadExt || 'jpg' // toBlob gives png by default
      }
      UploadHelper.fetchUploadUrl({}, options, (err, signedUploadInfo) => {
        if (err) {
          return this.onError(err)
        }
        //console.log('fetchUploadUrl:', err, signedUploadInfo)
        const uploadOptions = {
          pickerResult: blob,
          signedUploadInfo: signedUploadInfo,
          onEvent: (type, percent) => {
            //console.log('progress:', type, percent)
            if (type === 'progress') {
              return this.onUploadProgress(percent)
            } else if (type === 'load') {
              return this.onUploadComplete(signedUploadInfo)
            }
            else if (type === 'error') {
              return this.onError('Upload failed. You can try again')
            }
          }
        }
        UploadHelper.uploadFormDataWithProgress(uploadOptions)
      })
    }, 'image/png', 0.7)
  }

  readmultifiles = (files, n) => {
    return this.readFile(0, files, n);
  }

  readFile = (index, files, n) => {
    var reader = new FileReader();
    if (index >= files.length) return;
    var file = files[index];
    reader.onload = function (e) {
      var bin = e.target.result;
      this.setState(
        {
          files: [...this.state.files, file],
          dataURL: e.target.result,
          message: '',
          fetchState: index == n ? ApiHelper.State.READY : ""
        }, () => this.readFile(index + 1, files, n)
      )

    }.bind(this)
    reader.readAsDataURL(file);
  }

  onFileChange(e) {
    // if(this.props.multi){
    //   const files = e.target.files
    //   this.readmultifiles(files, files.length-1)
    // }else{
    const file = e.target.files[0]
    // TODO: validate Extension & Size
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState(
        {
          file: file,
          dataURL: e.target.result,
          message: '',
          fetchState: ApiHelper.State.READY
        }
      )
    }
    reader.readAsDataURL(file)
    // }
  }

  onUploadProgress(percent) {
    //console.log('percent:', percent);
    this.setState({
      message: 'Uploading: ' + percent + ' %',
      fetchState: ApiHelper.State.LOADING
    })
  }

  onUploadComplete(signedUploadInfo) {
    this.setState({
      message: 'Upload successful',
      fetchState: ApiHelper.State.READY
    })
    if (this.props.onUploadSuccess) {
      this.props.onUploadSuccess(this.props.uploadId, signedUploadInfo)
    }
  }

  onError(err) {
    //console.log('err:', err);
    this.setState({
      message: Helper.getErrorMsg(err),
      fetchState: ApiHelper.State.ERROR
    })
  }
}

const Styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    //alignItems: 'flex-start',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10
  },
  fileInput: {
    width: '100%'
  },
  textButton: {
    backgroundColor: 'black',
    color: 'white',
    maxWidth: 100,
    minHeight: 24,
    textAlign: 'center',
    marginTop: 5,
    fontSize: 10
  },
  text: {
    marginTop: 3,
    marginBottom: 3,
    fontSize: 10
  },
  textUpload: {
    color: 'white',
  },
  textError: {
    color: 'red',
  },
  textSuccess: {
    color: 'green',
  },
  editor: {
    width: 65,
    height: 65,
  },
  upadateActions: {
    backgroundColor: ColourPalette.color3,
    color: 'white',
    marginRight: 20,
  },
  cancelActions: {
    backgroundColor: '#337AB7',
    color: 'white'
  },
  attachFile: {
    display: 'flex',
    alignItems: 'center'
  },
  actionButtons: {
    display: 'flex',
    marginBottom: 20
  },
  marginLeft: {
    marginLeft: 10,
  },
  errMsg: {
    color: 'red',
    fontSize: 14
  },
  fileName: {
    color: AppTheme.white,
    fontSize: 14
  },
})
