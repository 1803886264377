import React, { Component } from 'react';
import {
  css
} from 'aphrodite';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { CommonStyles } from '../styles/Styles';

export class PopOverV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() { }

  render() {
    return (
      <Popover
        id={this.props.id}
        open={this.props.open}
        classes={{ paper: css(CommonStyles.menuItemPopoverContainer, this.props.boxShadowLight ? CommonStyles.boxShadowLight:'') }}
        anchorEl={this.props.anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={this.props.editClickClose}
      >
        {this.props.items.map((item, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => this.props.onPopOverClick(item.key)}
              className={css(CommonStyles.menuItemContainer, CommonStyles.menuItemPopoverContent)}>
              <p className={css(CommonStyles.margin)}>{item.title}</p>
            </MenuItem>
          )
        })}
      </Popover>
    )
  }
}