import React, { Component } from 'react';
import {
  css
} from 'aphrodite';
import { Controller } from '../../common/Controller';
import { Styles } from './Styles'
import { CommonStyles } from '../../styles/Styles';
import 'rc-slider/assets/index.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { AppConfig } from '../../AppConfig';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';

export class EventRefreshController extends Component {
  constructor(props) {
    super(props);
    this.refreshValue = Controller.get().userMgr().getSettings()
    this.state = {
      autoUpdate: this.getInitRefreshVal()
    }
    this.props.eventRefreshHandler(this.state.autoUpdate)
  }

  getInitRefreshVal = () => {
    if (this.refreshValue &&
      this.refreshValue.userSettings &&
      this.refreshValue.userSettings.eventRefresh) {
      return (this.refreshValue.userSettings.eventRefresh === 'ON' ? true : false)
    } else {
      return AppConfig.autoUpdateEvent
    }
  }

  onAutoUpdateChange = (event) => {
    let refreshValue = null
    if (event.target.checked) {
      refreshValue = 'ON'
    } else {
      refreshValue = 'OFF'
    }
    this.setState({ autoUpdate: event.target.checked }, () => Controller.get().userMgr().setUserSettings({ eventRefresh: refreshValue }))
    this.props.eventRefreshHandler(event.target.checked)
  }

  render() {
    return (
      <Tooltip title={'EVENT AUTO REFRESH'}>
        <div
          className={css(CommonStyles.headerMemberAddContainer, Styles.headerMemberAddContainerSecond)} >
          <p className={css(Styles.switchControlLabel)}> Event</p>
          <FormControlLabel
            color='secondary'
            className={css(Styles.formControlLabelContainer)}
            control={
              <Switch
                classes={{
                  track: this.state.autoUpdate && css(Styles.switchControlLabeltrack),
                  checked: css(Styles.switchControlLabelChecked),
                  thumb: css(Styles.switchControlLabelThumb),
                }}
                className={css(Styles.formControlSwitch)}
                checked={this.state.autoUpdate}
                onChange={this.onAutoUpdateChange}
              />
            }
          />
          {/*<p className={css(Styles.switchControlLabel)}>ON</p>*/}
        </div>
      </Tooltip>
    )
  }

}
