import React, { Component } from 'react'
import IdleTimer from 'react-idle-timer'
import { AppConfig } from '../AppConfig';
import { Controller } from '../common/Controller';


export class IdleTimeout extends Component {
  constructor(props) {
    super(props)
    this.idleTimer = null
    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)
  }

  render() {
    return (
      <div>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={AppConfig.IDLE_TIMEOUT}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
      </div>
    )
  }

  handleOnAction (event) {
  }

  handleOnActive (event) {
  }

  handleOnIdle (event) {
    // this.props.logout()
    console.log("IDLE TIMEOUT")
    Controller.get().logout()
  }
}