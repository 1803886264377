import { Controller } from '../Controller';
import { Helper } from './Helper';
import { AppConfig } from '../../AppConfig';
import { AuthError, ApiDataError, ApiError, NotFoundError } from '../errors';

export class ApiHelper {

  static State = Object.freeze({
    LOADING: 1,
    READY: 2,
    ERROR: 3
  });

  static makeUrlPath(paths, queryParams) {
    let url = AppConfig.ENDPOINT;
    for (const path of paths) {
      url = url + '/' + path;
    }
    if (queryParams) {
      const qs = Helper.encodeQueryString(queryParams);
      if (qs) {
        url += '?' + qs;
      }
    }
    return url;
  }

  static makeUrlPathAws(paths, queryParams) {
    let url = AppConfig.ES_ENDPOINT;
    for (const path of paths) {
      url = url + '/' + path;
    }
    if (queryParams) {
      const qs = Helper.encodeQueryString(queryParams);
      if (qs) {
        url += '?' + qs;
      }
    }
    return url;
  }

  static preAuthAPI(options, user, callback) {
    //console.log('PreAuthAPI...');
    if (options.authWithKey) {
      callback(null);
      return;
    }
    if (AppConfig.allowLocal) {
      if (!user) {
        return callback('ERROR: User not Logged in!');
      }
      if (user.type === 'local') {
        Controller.get().userMgr().setloggedInUserTkn(user.token);
        return callback(null);
      }
      user.getIdToken().then((data) => {
        Controller.get().userMgr().setloggedInUserTkn(data);
        return callback(null);
      }).catch((err) => {
        Controller.get().userMgr().setloggedInUserTkn(null);
        return callback(err);
      })
    } else {
      const cognitoUser = Controller.get().loginMgr().getCurrentUser()
      if (!cognitoUser) {
        callback(Helper.getString('loginErr'));
        return
      }
      Controller.get().loginMgr().fetchUserToken(cognitoUser, function (err) {
        if (err) {
          console.error(err)
          callback(err);
          return;
        }
        // Token should by now be cached in
        callback(null);
      })
    }
  }

  static call(options, callback) {
    ApiHelper._fetch(options).then((response) => {
      response.json().then((responseJson) => {
        return callback(null, responseJson)
      }).catch((parseError) => {
        return callback(new ApiDataError())
      });
    }).catch((error) => {

      //console.log('error:', error)
      if (error.status === 401) {
        return callback(new AuthError())
      }
      if (error.status === 404) {
        return callback(new NotFoundError(Helper.getString('noItemsFound'), error))
      }
      // if(error && (typeof error == "string") && error.includes("User not Logged in")){
      //    Controller.get().logout()
      //    return callback(new ApiError())
      // }
      if (!error.json) {
        return callback(new ApiError())
      }
      error.json().then((errorJson) => {
        return callback(errorJson)
      }).catch((parseError) => {
        return callback(new ApiDataError())
      });
    });
  }

  static _fetch(options) {
    //console.log('_fetch:', options.method, options.endPoint)
    return new Promise((resolve, reject) => {
      ApiHelper.preAuthAPI(options, Controller.get().userMgr().getloggedInUser(), function (err) {
        if (err) {
          //console.log('Auth Failed');
          reject(err);
        } else {
          let input = {
            method: options.method,
            headers: {//TODO: check why we have to give this
              Accept: 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            }
          };
          if (AppConfig.ADDITIONAL_HEADERS && Object.keys(AppConfig.ADDITIONAL_HEADERS).length > 0 ){
            input.headers = {...input.headers, ...AppConfig.ADDITIONAL_HEADERS}
          }
          if (options.jsonBody) {
            input.body = JSON.stringify(options.jsonBody)
          }
          if (options.authWithKey) {
            input.headers.Authorization = 'Key ' + AppConfig.APPAK;
          } else if(AppConfig.allowLocal) {
            input.headers.Authorization = 'Bearer ' + Controller.get().userMgr().getloggedInUserTkn();
          } else {
            input.headers.Authorization = 'Bearer ' + Controller.get().userMgr().getUserToken();
          }
          //console.log('fetch:', options.method, options.endPoint, input)
          fetch(options.endPoint, input).then((response) => {
            //console.log('_fetch response:', response);
            if (response.ok) {
              resolve(response);
            } else {
              console.error('_fetch not ok:', response);
              reject(response);
            }
          }).catch((error) => {
            console.error('_fetch error:', error);
            reject(error);
          });
        }
      });
    });
  }

  static publicApiCall(options, callback) {
    ApiHelper._publicFetch(options).then((response) => {
      response.json().then((responseJson) => {
        return callback(null, responseJson)
      }).catch((parseError) => {
        return callback(new ApiDataError())
      });
    }).catch((error) => {
      console.log('----error:', error)
      if (error.status === 401) {
        return callback(new AuthError('Invalid credentials', error))
      }
      if (!error.json) {
        return callback(new ApiError())
      }
      error.json().then((errorJson) => {
        return callback(errorJson)
      }).catch((parseError) => {
        return callback(new ApiDataError())
      });
    });
  }

  static _publicFetch(options) {
    //console.log('_fetch:', options.method, options.endPoint)
    return new Promise((resolve, reject) => {
      let input = {
        method: options.method,
        headers: { //TODO: has to be given to avoid 400 response from backend
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        }
      };
      if (AppConfig.ADDITIONAL_HEADERS && Object.keys(AppConfig.ADDITIONAL_HEADERS).length > 0 ){
        input.headers = {...input.headers, ...AppConfig.ADDITIONAL_HEADERS}
      }
      if (options.jsonBody) {
        input.body = JSON.stringify(options.jsonBody)
      }
      //console.log('_publicFetch:', options.method, options.endPoint, input)
      fetch(options.endPoint, input).then((response) => {
        //console.log('_fetch response:', response);
        if (response.ok) {
          resolve(response);
        } else {
          console.error('_fetch not ok:', response);
          reject(response);
        }
      }).catch((error) => {
        console.error('_fetch error:', error);
        reject(error);
      });
    });
  }
}