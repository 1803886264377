/* eslint-disable */ 
import React, { Component } from 'react';
import {
  css
} from 'aphrodite';
import Icon from '@material-ui/core/Icon';
import { Controller } from '../common/Controller';
import { Styles } from './Styles'
import { CommonStyles } from '../styles/Styles';
import { AppHelper } from '../helpers/AppHelper';

export class TableListV2 extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  getRowItem = (field, item) => {
    if (field.type === 'text') {
      return (
        <div className={css(CommonStyles.tableInnerContent)}>
          <p className={css(CommonStyles.paragraphMargin)}>{item[field.key]}</p>
        </div>
      )
    } else if (field.type === 'image') {
      return (
        <div className={css(CommonStyles.tableInnerContent)}>
          <div className={css(Styles.imgOuterContainer)}>
            <img
              alt={'Location Image'}
              src={item.logoUrl ? item.logoUrl : require('../assets/images/location-img.svg')}
              onError={(e) => {
                e.target.src = require('../assets/images/location-img.svg')
              }} className={css(Styles.ImgContent)} />
          </div>
        </div>
      )
    } else if (field.type === 'list') {
      return (
        <div className={css(CommonStyles.tableInnerContent)}>
          {field.key && item[field.key] && item[field.key].map(option => {
            return (
              <div className={css(Styles.menuHeaderRoleConatiner)}>
                <p className={css(Styles.menuHeaderRole)}>{option == "REGULAR" ? "MANAGER" : option}</p>
              </div>
            )
          })}
        </div>
      )
    } else if (field.type === 'multiple') {
      return (
        <div className={css(CommonStyles.tableRowContainerVariant)}>
          {field.items && field.items.map((f, index) => {
            return this.getRowItem(f, item)
          })}
        </div>
      )
    }
  }

  render() {
    return (
      <div className={css(Styles.innerContainer)}>
        <div className={css(CommonStyles.innerTableHeaderContainer)}>
          {this.props.tableColumnTitles.map(title => {
            return (
              <div key={title.key} className={css(CommonStyles.innerTableHeaderContent)}>
                <p className={css(CommonStyles.paragraphMargin)}>{title.text}</p>
              </div>
            )
          })}
          <div className={css(CommonStyles.innerTableHeaderSortContent)}></div>
        </div>
        {this.props.items !== undefined &&
          this.props.items.map(item => {
            return (
              <div key={item.key}
                className={this.props.disableRowClick ? css(Styles.innerTableContentDisableClick) : css(Styles.innerTableContent)}
                onClick={() => this.props.onRowItemClick(item)}>
                {this.props.tableColumnTitlesData.map(field => {
                  return this.getRowItem(field, item)
                })}
                {this.props.showHealthStatus && AppHelper.isHealthy(item, this.props.type) &&
                  <div className={css(Styles.statusContainer)}>
                    <Icon className={css(Styles.healthyIcon)}>fiber_manual_record</Icon>
                  </div>
                }
                {this.props.showHealthStatus && !AppHelper.isHealthy(item, this.props.type) &&
                  <div className={css(Styles.statusContainer)}>
                    <Icon className={css(Styles.unHealthyIcon)}>fiber_manual_record</Icon>
                  </div>
                }
                <div className={css(CommonStyles.innerTableEditContent)}>
                  {(Controller.get().userMgr().isProductUser() || Controller.get().userMgr().isAdminUser() || Controller.get().userMgr().isResidentUser()) &&
                    <div
                      aria-describedby={this.props.id}
                      onClick={(e) => this.props.editClick(e, item)}
                      className={css(Styles.editContent)}>
                      <img
                        alt={'More'}
                        src={require('../assets/images/more-rounded.svg')} />
                    </div>
                  }
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }
}