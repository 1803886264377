/* eslint-disable */ 
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import {
  AppTheme,
  AppStyles
} from '../styles/AppTheme';

export class Breadcrumbs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (!this.props.items || this.props.items.length === 0) {
      return ''
    }
    return (
      <div className={css(Styles.outerContainer)}>
        {this.props.items.map((item, index) => {
          const last = (index === this.props.items.length - 1);
          if (!last) {
            return (
              <div className={css(Styles.innerContainer)}>
                <Link
                  to={{ pathname: item.to }}
                  className={css(Styles.link)}>
                  <p className={css(Styles.titleContainer)}>{item.title}</p>
                </Link>
                <div className={css(Styles.iconContainer)}>
                  <img src={require('../assets/images/expand-more-icon2.svg')} className={css(Styles.iconContent)} />
                </div>
              </div>
            )
          } else {
            return (
              <div className={css(Styles.innerContainer)}>
                <p className={css(Styles.titleContainer, Styles.activeTitle)}>{item.title}</p>
              </div>
            )
          }
        })}
      </div>
    )
  }
}

const Styles = StyleSheet.create({
  outerContainer: {
    display: 'flex',
    marginTop: 5,
    alignItems: 'center',
    '@media (max-width: 768px)': {
      marginBottom: 5,
    }
  },
  innerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    margin: '0px 10px'
  },
  iconContent: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  titleContainer: {
    margin: 0,
    fontSize: 12,
    opacity: 0.7,
    color: AppTheme.white,
    '@media (max-width: 767px)': {
      fontSize: 10,
    }
  },
  activeTitle: {
    opacity: 1,
  },
  link: {
    textDecoration: 'none',
  },
})