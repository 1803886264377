/* eslint-disable */ 
import React, { Component } from 'react'
import { css } from 'aphrodite'
import MomentUtils from '@date-io/moment'
import momentTz from 'moment-timezone'
import moment from 'moment'
import { Styles } from './Styles'
import { AppTheme } from '../../styles/AppTheme'
import { CommonStyles } from '../../styles/Styles'
import 'rc-slider/assets/index.css'
import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'
import InputAdornment from '@material-ui/core/InputAdornment'
import { MuiPickersUtilsProvider, DateTimePicker, DatePicker, TimePicker } from '@material-ui/pickers'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

export class DateRangePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDate: (this.props.fromDateTs && this.props.fromDateTs) ? true : false,
      fromDateTs: this.props.fromDateTs ? this.props.fromDateTs : null,
      toDateTs: this.props.toDateTs ? this.props.toDateTs : null,
      fromTimeTs: this.props.fromTimeTs ? this.props.fromTimeTs : null,
      toTimeTs: this.props.toTimeTs ? this.props.toTimeTs : null,
      dateRangeOptionsPopover: null,
      datePickerPopover: null,
    }
    this.dateFields = {
      fromDateTs: {
        key: 'fromDateTs',
        label: 'From Date',
        type: 'text'
      },
      fromTimeTs: {
        key: 'fromDateTs',
        label: 'From Time',
        type: 'text'
      },
      toDateTs: {
        key: 'toDateTs',
        label: 'To Date',
        type: 'text'
      },
      toTimeTs: {
        key: 'toDateTs',
        label: 'To Time',
        type: 'text'
      }
    }
  }

  generateTimeField = (formField) => {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <TimePicker
          autoOk
          variant='inline'
          color={AppTheme.white}
          id={formField.key}
          name={formField.key}
          margin='normal'
          value={this.state[formField.key] ? momentTz(this.state[formField.key]) : null}
          onChange={e => this.onTimeChange(e, formField.key)}
          label={formField.label}
          format='HH:mm'
          InputProps={{
            disableUnderline: true,
            classes: {
              root: css(Styles.headerTextFieldContentInput, Styles.textFieldContentInputText),
              input: css(Styles.input)
            },
            endAdornment:
              <InputAdornment position='end'>
                <IconButton>
                  <img src={require('../../assets/images/timeIcon.svg')}
                    className={css(Styles.controlImg, Styles.dateControlImg)} />
                </IconButton>
              </InputAdornment>
          }}
          InputLabelProps={{
            classes: {
              root: css(Styles.headerTextFieldContent),
            }
          }}
          className={css(Styles.timeInputCss)}
        />
      </MuiPickersUtilsProvider>
    )
  }

  generateDateField = (formField) => {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          autoOk
          variant='inline'
          color={AppTheme.white}
          id={formField.key}
          name={formField.key}
          margin='normal'
          value={this.state[formField.key] ? momentTz(this.state[formField.key]) : null}
          onChange={e => this.onTimeChange(e, formField.key)}
          label={formField.label}
          format='MMM DD YYYY'
          InputProps={{
            disableUnderline: true,
            classes: {
              root: css(Styles.headerTextFieldContentInput, Styles.textFieldContentInputText),
              input: css(Styles.input)
            },
            endAdornment:
              <InputAdornment position='end'>
                <IconButton>
                  <img src={require('../../assets/images/dateIcon-img.svg')}
                    className={css(Styles.controlImg, Styles.dateControlImg)} />
                </IconButton>
              </InputAdornment>
          }}
          InputLabelProps={{
            classes: {
              root: css(Styles.headerTextFieldContent),
            }
          }}
          className={css(Styles.dateInputCss)}
        />
      </MuiPickersUtilsProvider>
    )
  }

  onTimeChange = (e, key) => {
    this.setState({
      [key]: moment(e).valueOf()
    }, () => {
      this.props.timeChangeHandler(this.state.fromDateTs, this.state.toDateTs,)
    });
  }

  showRecentEvent = () => {
    this.setState({
      showDate: false,
      fromDateTs: null,
      toDateTs: null,
    }, () => this.props.timeChangeHandler(this.state.fromDateTs, this.state.toDateTs, 'recent'))
    this.closeDateRangeOptions()
  }

  showDateRangeOptions = (e) => {
    this.setState({ dateRangeOptionsPopover: e.currentTarget })
  }

  closeDateRangeOptions = () => {
    this.setState({ dateRangeOptionsPopover: null })
  }

  renderDateRangeOptions = (datePopoverId, open) => {
    return (
      <Popover
        id={datePopoverId}
        open={open}
        classes={{ paper: css(CommonStyles.menuItemPopoverContainer, Styles.menuItemPopoverContainer) }}
        anchorEl={this.state.dateRangeOptionsPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={this.closeDateRangeOptions}
      >
        <div>
          <MenuItem
            onClick={() => this.showRecentEvent()}
            className={css(CommonStyles.menuItemContainer, CommonStyles.menuItemPopoverContent)}>
            <p className={css(CommonStyles.margin)}>RECENT EVENTS</p>
          </MenuItem>
          <MenuItem
            onClick={(e) => this.showDatePickerPopover(e)}
            className={css(CommonStyles.menuItemContainer, CommonStyles.menuItemPopoverContent)}>
            <p className={css(CommonStyles.margin)}>SELECT A DATE RANGE</p>
          </MenuItem>
        </div>
      </Popover>
    )
  }

  showDatePickerPopover = (e) => {
    this.setState({
      dateRangeOptionsPopover: null,
      datePickerPopover: e.currentTarget,
      showDate: true
    })
  }

  closeDatePicker = () => {
    this.setState({ datePickerPopover: null })
  }

  closeAll = () => {
    this.setState({ datePickerPopover: null })
  }

  renderDatePicker = (datePopoverId, open) => {
    const top = window.innerWidth > 767 ? 115 : 160
    return (
      <Popover
        anchorReference='anchorPosition'
        anchorPosition={{ top: top, left: 330 }}
        id={datePopoverId}
        open={open}
        classes={{ paper: css(CommonStyles.menuItemPopoverContainer, Styles.menuItemPopoverContainer) }}
        anchorEl={this.state.datePickerPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={this.closeDatePicker}
      >
        <div className={css(Styles.dateControlLabel)}>
          <Icon
            fontSize={'small'}
            className={css(Styles.closeIconDate)}
            onClick={() => this.closeAll()}>close</Icon>
          <div className={css(Styles.dateControlLabelSubContainer)}>
            {this.generateDateField(this.dateFields.fromDateTs)}
            <div className={css(Styles.dateControlLabelTimeSubContainer)}>
              {this.generateTimeField(this.dateFields.fromTimeTs)}
            </div>
          </div>
          <div className={css(Styles.dateControlLabelSubContainer)}>
            {this.generateDateField(this.dateFields.toDateTs)}
            <div className={css(Styles.dateControlLabelTimeSubContainer)}>
              {this.generateTimeField(this.dateFields.toTimeTs)}
            </div>
          </div>
        </div>
      </Popover>
    )
  }

  renderDateRangePicker = (datePopoverId) => {
    return (
      <div
        aria-describedby={datePopoverId}
        className={css(CommonStyles.headerMemberAddContainer, Styles.headerMemberAddContainerSecond, Styles.headerMemberAddContainerSecondSpecific)}
        onClick={(e) => this.showDateRangeOptions(e)}>

        {!this.state.showDate &&
          <p className={css(Styles.dateContainer)}>Recent events</p>
        }

        {this.state.showDate && this.state.fromDateTs && this.state.toDateTs &&
          <p
            className={css(Styles.dateContainer)}>
            {moment(this.state.fromDateTs).format('MMM DD, YYYY, HH:mm')}
            &nbsp; To &nbsp;
            {moment(this.state.toDateTs).format('MMM DD, YYYY, HH:mm')}</p>
        }

        {(!this.state.fromDateTs || !this.state.toDateTs) && this.state.showDate &&
          <p className={css(Styles.dateContainer)}>Select a date range</p>
        }

        <div className={css(Styles.dateClose)}>
          <Icon className={css(Styles.icons)}>arrow_drop_down</Icon>
        </div>
      </div>
    )
  }

  render() {
    const datePickerPopover = Boolean(this.state.datePickerPopover)
    const dateRangeOptionsPopover = Boolean(this.state.dateRangeOptionsPopover)
    const datePopoverId = datePickerPopover ? ('date-popover') : (dateRangeOptionsPopover ? 'date-popover' : undefined)
    //console.log('datePickerPopover', datePickerPopover)
    return (
      <div>
        {this.renderDateRangePicker(datePopoverId)}

        {this.renderDatePicker(datePopoverId, datePickerPopover)}

        {this.renderDateRangeOptions(datePopoverId, dateRangeOptionsPopover)}
      </div>
    )
  }
}
