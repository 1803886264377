import React, { Component } from 'react';
import {
  css,
  StyleSheet
} from 'aphrodite';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import { CommonStyles } from '../../styles/Styles';
import { AppTheme } from '../../styles/AppTheme';
import { ColourPalette } from '../../styles/AppColourPalette';

export class Toggler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      togglerMode: this.props.defaultMode ? this.props.defaultMode : false
    }
  }

  onChange = (event) => {
    this.setState({ togglerMode: event.target.checked })
    this.props.onChange(event.target.checked)
  }

  getStatus = () => {
    return this.state.togglerMode ? ' ON' : ' OFF'
  }

  render() {
    return (
      <Tooltip title={this.props.toolTip}>
        <div
          className={css(CommonStyles.headerMemberAddContainer, Styles.headerMemberAddContainerSecond)} >
          <FormControlLabel
            color='secondary'
            className={css(Styles.formControlLabelContainer)}
            label={this.props.title}
            labelPlacement={'start'}
            control={
              <Switch
                classes={{
                  track: this.state.togglerMode && css(Styles.switchControlLabeltrack),
                  checked: css(Styles.switchControlLabelChecked),
                  thumb: css(Styles.switchControlLabelThumb),
                }}
                className={css(Styles.formControlSwitch)}
                checked={this.state.togglerMode}
                onChange={this.onChange}
              />
            }
          />
        </div>
      </Tooltip>
    )
  }
}

const Styles = StyleSheet.create({
  headerMemberAddContainerSecond: {
    justifyContent: 'flex-start',
    padding: 0,
    paddingLeft: 10,
    paddingRight: 10,
    '@media(max-width: 768px)': {
      marginTop: 10,
      paddingRight: 0,
      justifyContent: 'center',
    }
  },
  switchControlLabel: {
    color: AppTheme.white,
    margin: 0,
    fontSize: 14,
    opacity: 0.6
  },
  formControlLabelContainer: {
    margin: 0,
    height: AppTheme.actionHeaderbarHeight
  },
  switchControlLabelChecked: {
    padding: 10,
    // color: ColourPalette.color3,
  },
  switchControlLabelThumb: {
    width: 18,
    height: 18
  },
  switchControlLabeltrack: {
    // backgroundColor: ColourPalette.color3
    
  },
  formControlSwitch: {
    width: 55,
    margin: '0px 3px'
  },
})