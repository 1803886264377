/* eslint-disable */ 
import React from 'react';
import { Grid, Paper, Card, CardContent } from '@material-ui/core';
import { StyleSheet, css } from 'aphrodite';
import { AppTheme } from '../styles/AppTheme';

export const StatCard = function (props) {
  const { title, text, subText, cardIndex, arrow, cardFooter, uniqueId, config } = props;
  const cardColor = 'c' + String((cardIndex % 4) + 1)
  return (
    <Grid item xs={6} sm={3} className={css(Styles.container)}>
      <div className={css(Styles.paperTitle)} elevation={0}>
        <p className={css(Styles.paragraphMargin)}>{title}</p>
        {
          cardFooter && (uniqueId && config[uniqueId] && config[uniqueId].isEditable) && (
            <div className={css(Styles.paperFooter)} elevation={0}>
              {arrow === 'down' && (
                <i class={'material-icons ' + css(Styles.arrow, Styles.arrowDown)}>keyboard_arrow_down</i>
              )}
              {arrow === 'up' && <i class={'material-icons ' + css(Styles.arrow, Styles.arrowUp)}>keyboard_arrow_up</i>}
              {cardFooter()}
            </div>
          )}
      </div>
      <Card className={css(Styles.card, Styles[cardColor])} elevation={0}>
        <CardContent className={css(Styles.cardcontent)}>
          {/* <div className={css(Styles.text)}>{text}</div> */}
          <div className={css(Styles.subText)}>&nbsp;&nbsp;{subText}</div>
        </CardContent>
      </Card>

    </Grid>
  );
};

const Styles = StyleSheet.create({
  container: {
    color: '#474747',
  },
  paperFooter: {
    //margin: 10,
    marginBottom: 0,
    display: 'flex',
    verticalAlign: 'top',
    justifyContent: 'flex-end'
  },
  paperTitle: {
    fontWeight: 'bold',
    color: AppTheme.white,
    opacity: 0.6,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    minHeight: 30
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 10,
    padding: '32px 16px',
    '@media(max-width: 768px)': {
      padding: '16px 8px',
    }
  },
  cardcontent: {
    /*padding: '32px 24px',*/
    display: 'flex',
    flex: 1,
    alignItems: 'flex-end',
    width: '100%',
    padding: 0,
    textAlign: 'left'
  },
  text: {
    display: 'inline-block',
    fontSize: 48,
    lineHeight: '48px',
    fontWeight: 'bold',
    maxWidth: '70%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    cursor: 'default',
    '@media(max-width: 768px)': {
      fontSize: 20
    }
  },
  subText: {
    display: 'inline-block',
    fontSize: 24,
    fontWeight: 'bold',
    flex: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    cursor: 'default',
    '@media(max-width: 768px)': {
      fontSize: 12
    }
  },
  arrowUp: {
    color: 'green'
  },
  arrowDown: {
    color: 'red'
  },
  c1: {
    background: '#FFD7BB'
  },
  c2: {
    background: '#FFE1EE'
  },
  c3: {
    background: '#A9E3FF'
  },
  c4: {
    background: '#b0dfe5'//'#3BDE86'
  },
  paragraphMargin: {
    margin: 0,
    flex: 1,
    cursor: 'default',
    '@media(max-width: 767px)': {
      fontSize: 14
    }
  },
});
