/* eslint-disable */ 
import {
  StyleSheet,
  css
} from 'aphrodite';
import { AppTheme } from '../../styles/AppTheme';

export const Styles = StyleSheet.create({
  innerMainContent: {
    display: 'flex',
    width: '90%',
    flexDirection: 'column',
    margin: '40px 0px 50px 0px',
  },
  orgDetailContainer: {
    display: 'flex',
    '@media(max-width: 767px)': {
      flexDirection: 'column'
    }
  },
  orgDetailInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: .5,
  },
  orgDetailInnerContainerSecondary: {
    marginTop: 15,
    '@media(max-width: 767px)': {
      marginLeft: 0,
      marginTop: 10
    }
  },
  orgDetailContainerTilte: {
    display: 'flex',
    backgroundColor: AppTheme.primaryBackground,
    padding: 15,
    borderRadius: '8px 8px 0px 0px'
  },
  orgDetailContainerTiltContent: {
    margin: 0,
    color: AppTheme.white,
    opacity: 0.6
  },
  orgDetailInnerContent: {
    backgroundColor: '#202633',
    display: 'flex',
    padding: 15,
    borderRadius: '0px 0px 8px 8px',
    flex: 1
  },
})