/* eslint-disable */ 
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { ValidationHelper } from '../helpers/ValidationHelper';
import { AppTheme } from '../styles/AppTheme';
import {
  StyleSheet,
  css
} from 'aphrodite';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ImageUploaderCustom } from './ImageUploaderCustom';

export class ImageUploaderComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value
    }
  }

  onSelect = e => {
    this.setState({ value: e.target.value });
    this.props.setValue(e.target.value, this.props.formField.key)
  };


  render() {
    return (
      <FormControl
        style={this.props.inputCss}
        className={css(Styles.inputCss)}>
        <p className={css(Styles.inputLabel)}>
          {this.props.formField.label}
        </p>

        <ImageUploaderCustom
          uploadId={this.props.formField.key}
          url={this.props.value}
          imageUploaderContainer={Styles.imageUploaderContainer}
          onUploadSuccess={this.props.handleUploadSuccess.bind(this)}
          width={this.props.formField.imageWidth}
          height={this.props.formField.imageHeight}
          uploadType={this.props.formField.uploadType}
          uploadAccept={this.props.formField.uploadAccept}
          uploadExt={this.props.formField.uploadExt}
          multi={this.props.multi ? true:false}
        //borderRadius={0}
        />

        <FormHelperText
          className={css(Styles.errorMsgStyle)}>
          {!this.props.enableValidation ? '' : ValidationHelper.validateFields(this.props.formField, this.state.value)}
        </FormHelperText>

      </FormControl>
    )
  }
}

const Styles = StyleSheet.create({
  inputCss: {
    margin: 8,
    // flex: 1,
    // color: AppTheme.white,
    color: "#000",

    minHeight: AppTheme.formFieldHeight
  },
  inputLabel: {
    // color: AppTheme.white,
    opacity: .6,
    zIndex: 1,
    marginLeft: 8,
    marginTop: 0,
    marginBottom: 5,
    pointerEvents: 'none',
    '@media(max-width: 767px)': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      right: 22,
    }
  },
  inputLabelShrink: {
    right: 'unset'
  },
  innerContainer: {
    color: AppTheme.white
  },
  selectMenu: {
    // backgroundColor: AppTheme.secondaryColor,
    // color: AppTheme.white,
    backgroundColor:"#eeeeee",
    // color: AppTheme.white,
    color:"#000",
    paddingLeft: 8,
    borderRadius: 4
  },
  textFieldContentInputView: {
    // backgroundColor: AppTheme.primaryBackground,
    // backfaceVisibility:"rgb(238, 238, 238) !important",
    // color:"#000",
    backgroundColor:"rgb(238, 238, 238) !important",
    color:"#000",
  },
  selectIcon: {
    // color: AppTheme.white,
    color:"#000",

    opacity: 0.7
  },
  selectIconView: {
    color: AppTheme.primaryBackground
  },
  errorMsgStyle: {
    color: AppTheme.white,
    paddingLeft: 8
  },
  imageUploaderContainer: {
    // backgroundColor: AppTheme.secondaryColor,
    padding: 10,
    background:"#eee",
    color:"#000",
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    overflow: 'hidden'
  }
})