import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import async from 'async';
import { UiHelper } from '../common/helpers/UiHelper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { ActionHeaderbar } from '../components/ActionHeaderbar';
import { SettingsComponent } from '../components/SettingsComponent';
import { CommonStyles } from '../styles/Styles';
import { Controller } from '../common/Controller';
import { AppConfig } from '../AppConfig';
import { SimpleTabs } from '../components/Tab';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
export class Settings extends Component {
  constructor(props) {
    super(props);
    this.orgId = this.props.match.params.id ? this.props.match.params.id : null;
    this.orgListView = (this.props.location && this.props.location.state) ? this.props.location.state.orgListView : null
    this.state = {
      fetchState: ApiHelper.State.LOADING,
      showWFH:true,	
      showOffice:false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.fetchItems();
  }

  fetchItems = () => {
    const thisObj = this;

    if (!Controller.get().userMgr().isAdminUser() && !Controller.get().userMgr().isProductUser()){
      this.setState({
        fetchState: ApiHelper.State.ERROR,
        errMsg: Helper.getString('permissionErr')
      })
      return
    }

    async.parallel(
      [
        function (callback) {
          ApiHelper.call(
            { method: 'GET', endPoint: ApiHelper.makeUrlPath(['orgs', thisObj.orgId]) }, callback)
        },
      ],
      function (err, results) {
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err)
          })
          return
        }

        for (const f of AppConfig.orgTypes) {
          if (f.key === results[0].type) {
            this.data = f
            break;
          }
        }
        if(!results[0]['officeSettings']){
          results[0]['officeSettings']= {['settings'] : {}}
        }
        this.setState({
          org: results[0],
          fetchState: ApiHelper.State.READY,
        })
      }.bind(this))
  }


  render() {
    if (this.state.fetchState === ApiHelper.State.LOADING) {
      return UiHelper.componentLoadingView();
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      return UiHelper.errorView(this);
    } else {
      return this.readyView();
    }
  }

  handleTabPanelChange = (value) =>{	
    if(value == 0){	
      this.setState({	
        showWFH : true,	
        showOffice :false	
      })	
    }else{	
      this.setState({	
        showWFH :false,	
        showOffice :true	
      })	
    }	
  }	

  readyView = () => {
    return (
      <div className={css(CommonStyles.outerMainContainer)}>
        <ActionHeaderbar
          backPathName={this.orgListView ? '/orgs' : '/orgs/' + this.orgId}
          statSuffix={'FEATURES & SETTINGS'}
          title={this.state.org.name}
        />
        <div className={css(CommonStyles.innerMainContainer)}>
          <div className={css(Styles.innerMainContent)}>
            {this.state.org.type == 'wfh' && <SimpleTabs numberOfTabs={['Work From Home', 'Work From Office']} handleTabPanelChange={this.handleTabPanelChange}></SimpleTabs>}

            {this.data && this.data.features && this.state.showWFH &&
              <SettingsComponent
                data={this.data}
                item={this.state.org}
                itemId={this.orgId}
                itemName={'orgs'}
                apiBodyPrefix={'orgData'}
              />
            }
            {	
              this.state.showOffice &&              	
             <SettingsComponent	
              data={this.data}	
              item={this.state.org.officeSettings}	
              itemId={this.orgId}	
              itemName={'orgs'}	
              apiBodyPrefix={'orgData'}	
              showOffice = {this.state.showOffice}	
            />	
            }
            {!this.data &&
              < p className={css(Styles.emptyDataContainer)}>{Helper.getString('noItemsFound')}</p>
            }
          </div>
        </div>
      </div>
    )
  }
}


const Styles = StyleSheet.create({
  innerMainContent: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    margin: '40px 0px 50px 0px',
    '@media(max-width: 768px)': {
      width: '90%',
    }
  },
  emptyDataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    flex: 1,
  },
  formControlLabelContainer: {
    margin: '1rem 0px !important'
  },
  switchControlLabel: {
    color: '#377bac',
    margin: 0,
    fontSize: 14,
    opacity: 0.6
  },
  switchControlLabelChecked: {
    padding: 10,
    color: '#377bac',
  },
  switchControlLabelThumb: {
    width: 18,
    height: 18
  },
  switchControlLabeltrack: {
    backgroundColor: '#377bac'
  },
  frMainContainer:{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
})
