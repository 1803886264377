/* eslint-disable */ 
import React, { Component } from 'react';
import { css } from 'aphrodite';
import { Styles } from './Styles';
import { CommonStyles } from '../../styles/Styles';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';

export class ViewChartButton extends Component {
  constructor(props) {
    super(props);
  }

  setStyle = () => {
    if (this.props.showChart) {
      return css(CommonStyles.headerMemberAddContainer,
        Styles.headerMemberStyle, Styles.filterModeOn)
    } else {
      return css(CommonStyles.headerMemberAddContainer,
        Styles.headerMemberStyle)
    }
  }

  render = () => {
    return (
      <Tooltip title={'CHART'}>
        <div
          onClick={() => this.props.showChartHandler(!this.props.showChart)}
          className={this.setStyle()}>
          <Icon
            className={css(Styles.barChartIcon)}>
            bar_chart
          </Icon>
          {/*<p className={css(Styles.switchControlLabel)}>{this.props.showStats ? 'COLLAPSE SUMMARY' : 'VIEW SUMMARY'}</p>*/}
        </div>
      </Tooltip>
    )
  }
}