/* eslint-disable */ 
import Dialog from '@material-ui/core/Dialog';
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';

import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


export class CustomShieldDialog extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (

            <Dialog
                className={css(Styles.dialogDimensions)}
                maxWidth={'md'}
                fullScreen open={this.props.configDialog}
                TransitionComponent={Transition}
                disableBackdropClick={true}

            >
                <Toolbar className={css(Styles.closeIcon)}>
                    <IconButton edge="start" color="inherit" aria-label="close" onClick={() => {
                        this.props.closeConfigDialog()
                    }}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <iframe src={this.props.shieldURL} height="100%" width="100%"></iframe>
            </Dialog>
        )
    }
}


const Styles = StyleSheet.create({
    dialogDimensions: {
        maxWidth: '90%',
        maxHeight: '90%',
        left: '5%',
        top: '5%'
    },
    closeIcon: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
})