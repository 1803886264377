/* eslint-disable */ 
import React, { Component } from 'react';
import {
  css
} from 'aphrodite';
import { Button, Divider } from '@material-ui/core';
import { Controller } from '../../common/Controller';
import { Styles } from './Styles'
import { CommonStyles } from '../../styles/Styles';
import 'rc-slider/assets/index.css';
import Popover from '@material-ui/core/Popover';
import { AppTheme } from '../../styles/AppTheme';
import { adminData } from '../../data/AdminData.js'
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { AppHelper } from '../../helpers/AppHelper';

export class EventFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterPopover: null,
      priorityFilters: this.props.priorityFilters || Controller.get().userMgr().getPriorityFilters(),
      tagFilters: this.props.tagFilters || Controller.get().userMgr().getTagFilters(),
      filterTagValue: this.props.filterTagValue || {}
    }
  }

  onChange = (event) => {
    this.setState({
      filterTagValue: { ...this.state.filterTagValue, [event.target.name]: event.target.value }
    })
  }

  filterByPriority = (e, item) => {
    const index = this.state.priorityFilters.indexOf(item.value);
    const priorityFilter = this.state.priorityFilters

    if (index > -1) {
      priorityFilter.splice(index, 1);
    } else {
      priorityFilter.push(item.value);
    }

    this.setState({ priorityFilters: priorityFilter })
  }

  filterByTags = (e, item) => {
    const index = this.state.tagFilters.indexOf(item.key);
    const tagFilters = this.state.tagFilters
    if (index > -1) {
      tagFilters.splice(index, 1);
    }
    else {
      tagFilters.push(item.key);
    }
    this.setState({ tagFilters: tagFilters })
  }

  applyFilter = () => {
    if (
      (this.state.priorityFilters && this.state.priorityFilters.length > 0)
      ||
      (this.state.tagFilters && this.state.tagFilters.length > 0)
    ) {
      this.setState({
        filterMode: true
      })
    } else {
      this.setState({
        filterMode: false
      })
    }
    this.closeFilterPopover()
    const updatedFilterTagValue = {}
    for (const f of this.state.tagFilters) {
      if (this.state.filterTagValue[f]) {
        updatedFilterTagValue[f] = this.state.filterTagValue[f]
      }
    }
    this.setState({
      filterTagValue: updatedFilterTagValue
    })
    this.props.filterHandler(this.state.tagFilters, this.state.priorityFilters, updatedFilterTagValue)
  }

  renderFilterPopover = (id, open) => {
    //////console.log('id', id)
    //////console.log('open', open)
    const position = window.innerWidth > 767 ? 'right' : 'center'
    const tags = this.props.place ? AppHelper.getTags(this.props.place.type) : []
    return (
      <Popover
        id={id}
        open={open}
        classes={{ paper: css(Styles.filterPopoverContainer) }}
        anchorEl={this.state.filterPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: position,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: position,
        }}
        onClose={this.closeFilterPopover}
      >
        <div>
          <div className={css(Styles.filterContainerHeader)}>
            <p className={css(Styles.filterContentHeader)}>Filters</p>
          </div>

          <div className={css(Styles.filterContent)}>
            <div className={css(Styles.filterInnerContent)}>
              <p className={css(Styles.filterIncidentTitle)}>by Priority</p>

              <div className={css(Styles.editAlert)}>
                {adminData.priorityValues.map((item, i) => {
                  return (
                    <div key={i} className={css(Styles.editAlertContainer, Styles.headerEditAlertContainer)}>
                      <div className={css(Styles.editAlertDotContainer, Styles.headerEditAlertDotContainer)}>
                        <div className={css(Styles.editAlertDotContent)} style={{ backgroundColor: item.color }} />
                      </div>

                      <div className={css(Styles.editAlertLabel, Styles.headerEditAlertLabel)}
                        onClick={(e) => this.filterByPriority(e, item)}
                        style={{
                          backgroundColor:
                            this.state.priorityFilters.indexOf(item.value) > -1 ?
                              item.color : AppTheme.primaryColor
                        }}
                      >
                        <p className={css(CommonStyles.margin)}>{item.label}</p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>

            {tags && tags.length > 0 &&
              <Divider className={css(Styles.divider)} />
            }

            {tags && tags.length > 0 &&
              <div className={css(Styles.filterInnerContent)}>
                <p className={css(Styles.filterIncidentTitle)}>by Tag</p>

                <div className={css(Styles.editAlert, Styles.editAlertTag)}>
                  {tags.map((item, i) => {
                    return (
                      <div key={i} className={css(Styles.tagFilterOuterContainer)}>
                        <div className={css(Styles.tagFilterContainer)}>
                          <div className={css(Styles.headerTagContainer)}>
                            <div
                              className={this.state.tagFilters.indexOf(item.key) > -1 ?
                                css(Styles.menuHeaderRoleConatiner) :
                                css(Styles.menuHeaderRoleConatiner, Styles.menuHeaderRoleStyleConatiner)}
                              onClick={(e) => this.filterByTags(e, item)}>
                              <p className={css(Styles.menuHeaderRole)}>{item.key}</p>
                            </div>
                          </div>

                        <div hidden={item.type == 'selector'}>
                          <TextField
                            placeholder={'Enter Value'}
                            value={this.state.filterTagValue[item.key]}
                            onChange={e => this.onChange(e)}
                            name={item.key}
                            className={css(Styles.inputCssTextField)}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                root: css(Styles.headerTextFieldContentInput),
                              }
                            }}
                            InputLabelProps={{
                              classes: {
                                root: css(Styles.headerTextFieldContent),
                              }
                            }}
                          />
                        </div>
                        </div>
                      </div>
                    )
                  })}
                </div>

              </div>
            }

            <Divider className={css(Styles.divider)} />

            {!this.props.showApplyFilters && <div className={css(Styles.saveFilterContainer)}>
              <div className={css(Styles.buttonContainer)}>
                <Button
                  variant='contained'
                  onClick={() => this.applyFilter()}
                  className={css(Styles.buttonStyle, Styles.buttonStyleApply)}>
                  APPLY
                </Button>
              </div>
            </div>}
          </div>

        </div>
      </Popover>
    )
  }

  showFilterPopover = (e) => {
    //////console.log('showFilterPopover')
    this.setState({ filterPopover: e.currentTarget })
  }

  closeFilterPopover = () => {
    this.setState({ filterPopover: null })
  }

  setFilterStyles = () => {
    if (this.state.filterMode) {
      return css(CommonStyles.headerMemberAddContainer, Styles.headerMemberStyle, Styles.headerMemberAddContainerSecondSpecific, Styles.filterModeOn)
    } else {
      return css(CommonStyles.headerMemberAddContainer, Styles.headerMemberStyle, Styles.headerMemberAddContainerSecondSpecific)
    }
  }

  render = () => {
    const filteropen = Boolean(this.state.filterPopover);
    const filterid = filteropen ? 'filter-popover' : undefined;

    return (
      <div>
        <Tooltip title={'FILTER EVENTS'}>
          <div
            onClick={(e) => this.showFilterPopover(e)}
            className={this.setFilterStyles()}>

            <img src={require('../../assets/images/filter.svg')} className={css(Styles.filterIcon)} />

            {/*<p className={css(Styles.switchControlLabel)}>FILTER</p>*/}
          </div>
        </Tooltip>
        {this.renderFilterPopover(filterid, filteropen)}
      </div>
    )
  }
}