/* eslint-disable */ 
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import moment from 'moment';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { UiHelper } from '../common/helpers/UiHelper';
import { Controller } from '../common/Controller';
import { ActionHeaderbar } from '../components/ActionHeaderbar';
import { LiveImage } from '../components/LiveImage';
import { CommonStyles } from '../styles/Styles';
import { AppTheme } from '../styles/AppTheme';

export class CamLive extends Component {
  constructor(props) {
    super(props);
    this.placeId = this.props.match.params.id ? this.props.match.params.id : null;
    this.eventId = (this.props.location.state && this.props.location.state.eventId) ? this.props.location.state.eventId : null
    this.state = {
      fetchState: ApiHelper.State.LOADING,
      cams: [],
      place: null,
      selectedCam: null,
      urlJpeg: null,
      selectedCamId: this.props.match.params.camid ? this.props.match.params.camid : null,
      fullScreenMode: Controller.get().userMgr().getFullScreenMode() ? 'EXIT FULLSCREEN' : 'FULLSCREEN',
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.fetchItems();
  }

  componentWillUnmount() { }

  fetchItems = () => {
    ApiHelper.call(
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['places', this.placeId]) },
      function (err, result) {
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err)
          })
          return
        }
        this.processResult(result)
      }.bind(this))
  }

  processResult = (result) => {

    for (const c of result.cams) {
      c.key = c._id
    }

    if (this.state.selectedCamId) {
      if (result && result.cams.length > 0) {
        let camFound = false;
        for (const c of result.cams) {
          if (c._id === this.state.selectedCamId) {
            camFound = true;
            this.setData(c, result)
          }
        }
        if (!camFound) {
          this.setError()
        }
      }
    } else if (result && result.cams.length > 0 && !this.state.selectedCam) {
      this.setData(result.cams[0], result)
    } else {
      this.setError()
    }
  }

  setData = (cam, place) => {
    this.setState({
      selectedCam: cam,
      urlJpeg: cam.urlJpeg,
      selectedCamId: cam._id,
      place: place,
      cams: place.cams,
      fetchState: ApiHelper.State.READY
    })
  }

  setError = () => {
    this.setState({
      fetchState: ApiHelper.State.ERROR,
      errMsg: Helper.getString('noCameras')
    })
  }

  setFullscreen = () => {
    if (this.state.fullScreenMode === 'FULLSCREEN') {
      this.setState({ fullScreenMode: 'EXIT FULLSCREEN' })
      Controller.get().userMgr().setFullScreenMode(true)
    } else {
      this.setState({ fullScreenMode: 'FULLSCREEN' })
      Controller.get().userMgr().setFullScreenMode(false)
    }
    window.location.reload();
  };

  onCamSelect = (value) => {
    for (const cam of this.state.cams) {
      if (cam._id === value) {
        this.setState({
          selectedCam: cam,
          urlJpeg: cam.urlJpeg,
        });
        this.props.history.replace('/places/' + this.placeId + '/cams/' + cam._id)
        break;
      }
    }
  };

  handleBackPathName = () => {
    if (this.eventId) {
      return '/events/' + this.eventId
    } else {
      return '/places/' + this.placeId + '/cams'
    }
  }

  render() {
    if (this.state.fetchState === ApiHelper.State.LOADING) {
      return UiHelper.componentLoadingView();
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      return UiHelper.errorView(this);
    } else {
      return this.readyView();
    }
  }

  readyView() {
    return (
      <div className={css(CommonStyles.outerMainContainer, Styles.outerMainContainer)}>
        <ActionHeaderbar
          camLiveIndicator={true}
          backPathName={this.handleBackPathName()}
          inProcess={this.state.inProcess}
          showPicker={true}
          pickerItem={{
            key: 'selectedCamId',
            label: 'Select Cam',
            options: this.state.cams,
            value: this.state.selectedCamId
          }}
          onPickerSelect={this.onCamSelect} />

        {this.state.selectedCam &&
          <div className={css(CommonStyles.innerMainContainer)}>
            <div className={css(Styles.innerContainer)}>
              <div className={css(Styles.innerContainerHeader)}>
                <div className={css(Styles.innerContainerHeaderDetails)}>
                  <p className={css(Styles.innerContainerCamTime)}>
                    {moment(this.state.selectedCam.latestEventTs).format('MMM DD, YYYY, h:mm:ss A')}
                  </p>

                  <div className={css(Styles.innerCamNameContainer)}>
                    <p className={css(Styles.innerContainerCamName)}>{this.state.selectedCam.name}</p>
                  </div>
                </div>

                <div className={css(Styles.innerContainerheaderFunctions)}>
                  <div className={css(Styles.eventEditImgContainer)}>
                    <div
                      className={css(Styles.eventEditImg)}
                      onClick={() => this.setFullscreen()}>
                      <img
                        src={require('../assets/images/fullscreen-live-img.svg')}
                        className={css(Styles.eventEditImgContent)} />
                    </div>

                    <p className={css(Styles.eventInnerControlImgTitle)}>{this.state.fullScreenMode}</p>
                  </div>
                </div>
              </div>

              {this.state.selectedCam && this.state.selectedCam.urlJpeg &&
                <div
                  className={css(Styles.eventContainerImg)}
                  style={{ height: window.innerWidth > 768 ? window.innerHeight - 252 : 'auto' }}> {/*TODO: find a better way to calculate the hieght*/}
                  <LiveImage
                    urlJpeg={this.state.urlJpeg} />
                </div>
              }
              {this.state.selectedCam && !this.state.selectedCam.urlJpeg &&
                <p className={css(Styles.liveNotAvailable)}>Live Stream not available for this cam</p>
              }
            </div>
          </div>
        }
      </div>
    )
  }
}

const Styles = StyleSheet.create({
  outerMainContainer: {
    minHeight: 'unset'
  },
  innerContainer: {
    width: '90%',
    marginBottom: 30
  },
  innerContainerHeader: {
    alignItems: 'center',
    display: 'flex',
    margin: '30px 0px 20px 0px'
  },
  innerContainerHeaderDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  innerContainerCamTime: {
    opacity: 0.3,
    color: AppTheme.white,
    fontSize: AppTheme.fontSizeContent,
    margin: 0,
    cursor: 'default'
  },
  innerCamNameContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
  },
  innerContainerCamName: {
    opacity: 0.7,
    color: AppTheme.white,
    fontSize: 23,
    margin: 0,
    cursor: 'default'
  },
  innerContainerheaderFunctions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flex: 1
  },
  eventEditImgContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 20
  },
  eventEditImg: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    width: 27,
    height: 27
  },
  eventEditImgContent: {
    width: '100%'
  },
  eventInnerControlImgTitle: {
    fontSize: 10,
    opacity: .3,
    color: AppTheme.white,
    textAlign: 'center',
    marginTop: 5,
    marginBottom: 0,
    cursor: 'default'
  },
  eventContainerImg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  liveNotAvailable: {
    marginTop: 150,
    color: 'white',
    textAlign: 'center'
  }
})