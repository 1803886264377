/* eslint-disable */ 
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { ValidationHelper } from '../helpers/ValidationHelper';
import { AppTheme } from '../styles/AppTheme';
import moment from 'moment';
import {
  StyleSheet,
  css
} from 'aphrodite';

export class TextInputComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value 
      // || this.props.formField.defaultValue
    }
  }
  componentDidMount(){
    if(!this.props.value){
      this.setState({ value: this.props.formField.defaultValue});
      this.props.setValue(this.props.formField.defaultValue, this.props.formField.key)
    }else{
      this.setState({ value: this.props.value});
      this.props.setValue(this.props.value, this.props.formField.key)
    }
  }

  change = e => {
      this.setState({ value: e.target.value });
      this.props.setValue(e.target.value, this.props.formField.key)
  }

  render() {
    if(this.props.hidden  && this.props.hidden == true){
      return (<></>)
    }
    return (
      <TextField
        id={this.props.formField.key + ' ' + new Date()} //TODO
        label={this.props.formField.hint || this.props.formField.label}
        className={css(Styles.inputCss)}
        style={this.props.inputCss}
        value={this.state.value}
        onChange={e => this.change(e)}
        margin='normal'
        type={this.props.formField.inputHide && !this.props.formField.inputDate ? 'password' :!this.props.formField.inputHide && this.props.formField.inputDate?"datetime-local": this.props.formField.type}
        helperText={!this.props.enableValidation ? '' : ValidationHelper.validateFields(this.props.formField, this.state.value)}
        disabled={this.props.disabled}
        FormHelperTextProps={{ classes: { root: css(Styles.errorMsgStyle) } }}
        InputProps={{
          disableUnderline: true,
          classes: {
            root: this.props.action === 'viewInfo' ?
              css(Styles.textFieldContentInput, Styles.textFieldContentInputView) : css(Styles.textFieldContentInput)
          }
        }}
        InputLabelProps={{
          classes: {
            root: css(Styles.textFieldContent),
            shrink: css(Styles.textFieldContentShrink),
          },
          shrink: this.props.formField.inputDate,
        }}
      />
    )
  }
}

const Styles = StyleSheet.create({
  inputCss: {
    margin: 8,
    // flex: 1,
    color: "#000",
    // color: AppTheme.white,
    minHeight: AppTheme.formFieldHeight
  },
  errorMsgStyle: {
    color: AppTheme.white,
    paddingLeft: 8
  },
  textFieldContentInput: {
    borderRadius: 4,
    // backgroundColor: AppTheme.secondaryColor,
    paddingLeft: 8,
    // color: AppTheme.white,
    backgroundColor:"rgb(238, 238, 238) !important",
    color:"#000",
  },
  textFieldContentInputView: {
    // backgroundColor: AppTheme.primaryBackground,
    backgroundColor:"rgb(238, 238, 238) !important",
    color:"#000",
  },
  textFieldContent: {
    // color: AppTheme.white,
    zIndex: 1,
    paddingLeft: 8,
    // opacity: .6,
    '@media(max-width: 767px)': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      right: 22,
    }
  },
  textFieldContentShrink: {
    right: 'unset',
    opacity:1,
    color:"#000"
  }
})