import React, { Component } from 'react';
import { AppTheme } from '../styles/AppTheme';
import {
  StyleSheet,
  css
} from 'aphrodite';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { ColourPalette } from '../styles/AppColourPalette';
import FormControl from '@material-ui/core/FormControl';

export class TogglerComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      lockLabel: this.props.value === true ? 'ON' : 'OFF'
    }
  }

  onSelect = e => {
    this.setState({ value: e.target.checked });
    if (e.target.checked) {
      this.setState({ lockLabel: 'ON' })
    } else {
      this.setState({ lockLabel: 'OFF' })
    }
    this.props.setValue(e.target.checked, this.props.formField.key)
  };


  render() {
    return (
      <FormControl
        style={this.props.inputCss}
        className={css(Styles.inputCss)}>
        <p className={css(Styles.inputLabel)}>
          {this.props.formField.label}
        </p>
        <FormControlLabel
          className={css(Styles.formToggle)}
          control={
            <Switch
              classes={{
                track: this.state.value && css(Styles.switchControlLabeltrack),
                checked: css(Styles.switchControlLabelChecked),
              }}
              checked={this.state.value}
              onChange={this.onSelect}
              value={this.state.value}
              size={'small'}
            />
          }
          classes={{
            label: css(Styles.inputLabel),
          }}
          label={this.state.lockLabel}
        />
      </FormControl>
    )
  }
}

const Styles = StyleSheet.create({
  inputCss: {
    margin: 8,
    // flex: 1,
    color: "#000",
    // color: AppTheme.white,
    minHeight: AppTheme.formFieldHeight
  },
  inputLabel: {
    // color: AppTheme.white,
    opacity: .6,
    zIndex: 1,
    marginLeft: 8,
    marginTop: 0,
    marginBottom: 8,
    pointerEvents: 'none',
    '@media(max-width: 767px)': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      right: 22,
    }
  },
  switchControlLabeltrack: {
    // backgroundColor: ColourPalette.color3,
  },
  switchControlLabelChecked: {
    // color: ColourPalette.color3,
  },
  formControlLabel: {
    fontWeight: 'bolder',
    fontSize: 17,
    color: AppTheme.white
  },
  formToggle: {
    marginLeft: 0
  }
})