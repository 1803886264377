/* eslint-disable */ 
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Login } from '../common/components/Login';
import { LoginLocal } from '../components/LoginLocal';
import { AppConfig } from '../AppConfig';

export class LoginPage extends Component {
  render() {
    if (AppConfig.allowLocal) {
      return (
        <LoginLocal
          loginPanelStyle={Styles.loginPanel}
          showTerms={false}>
        </LoginLocal>)
    } else {
      return (
        <Login
          loginPanelStyle={Styles.loginPanel}
          showTerms={false}>
        </Login>)
    }
  }
}
const Styles = StyleSheet.create({
  loginPanel: {
  },
})