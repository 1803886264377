import React, { Component, Fragment } from "react";
import { TableListV2 } from "./TableListV2";
import { orgData } from "../screens/GuestUsersDetails/Data";
import { PopOverV2 } from "./PopOverV2";
import { appData } from "../data/AppData";
import { Controller } from "../common/Controller";
import { CustomFormFieldsDialogV2 } from "./CustomFormFieldsDialogV2";
import { ApiHelper } from "../common/helpers/ApiHelper";
import { Helper } from "../common/helpers/Helper";

export class Vehicle extends Component {
  constructor(props) {
    super(props);

    this.uId = Controller.get()
      .userMgr()
      .getUser()._id;

    this.responseByKey = {};
    this.state = {
      anchorEl: null,
      selectedItem: {},
      inProcess: false,
    };
  }

  editVehicleClick = (e, item) => {
    this.setState({
      anchorEl: e.currentTarget,
      selectedItem: item,
    });
  };

  onVehiclePopOverClick = (key) => {
    if (key === "edit-vehicle") {
      this.showVehicleDialog();
    } else if (key === "delete-vehicle") {
      this.showDeleteVehicleDialog();
    }
  };

  showDeleteVehicleDialog = () => {
    this.responseByKey = this.state.selectedItem;
    if (this.responseByKey.guestInfo) {
      this.props.openDialog("guest", "delete");
    } else {
      this.props.openDialog("own", "delete");
    }
  };

  showVehicleDialog = () => {
    this.responseByKey = this.state.selectedItem;
    this.responseByKey.alertEnabled = this.responseByKey.alertSubscribers.includes(
      this.uId
    );
    this.setState({
      anchorEl: null,
      dialogAction: "edit",
    });

    if (this.responseByKey.guestInfo) {
      this.props.openDialog("guest", "edit");
    } else {
      this.props.openDialog("own", "edit");
    }
  };

  showConfirmDeleteView = () => {
    this.setState({
      confirmDeleteView: true,
      inProcess: false,
    });
  };

  resetToDefaultValues = () => {
    this.responseByKey = {};
    this.setState({
      anchorEl: null,
      updateMemberDialog: false,
      verifyMemberDialog: false,
      addOwnDialog: false,
      addGuestVehicleDialog: false,
      bulkUserUploadDialog: false,
      inProcess: false,
      dialogAction: '',
      dialogTitle: '',
      selectedItem: {},
      anchorEl: null,
      confirmDeleteView: false,
      errMsg: null,
      auditTrailEnabled: false
    });
    this.props.closeDialog();
  };

  postOwnVehicle = () => {
    this.setState({
      inProcess: true,
    });
    let options = {};

    options = {
      method: "POST",
      endPoint: ApiHelper.makeUrlPath(["vehicles"]),
      jsonBody: {
        vehicleData: {
          ...this.responseByKey,
          orgId: this.props.orgId,
        },
      },
    };

    Helper.removeNullValues(options.jsonBody.vehicleData);
    ApiHelper.call(
      options,
      function(err, data) {
        if (err) {
          const msg = err.message
            ? err.message
            : "An error occurred, please try again. Contact customer care if problem persists.";
          this.setState({
            errMsg: msg,
            errCode: err.respCode,
            inProcess: false,
          });
          return;
        } else {
          this.resetToDefaultValues();
          if (this.props.orgView) {
            this.props.fetchItemsForOrgView();
          } else {
            this.props.fetchItems();
          }
        }
      }.bind(this)
    );
  };

  postGuestVehicle = () => {
    this.setState({
      inProcess: true,
    });
    let options = {};

    options = {
      method: "POST",
      endPoint: ApiHelper.makeUrlPath(["guest/vehicle"]),
      jsonBody: {
        vehicleData: {
          ...this.responseByKey,
          orgId: this.props.orgId,
        },
      },
    };

    Helper.removeNullValues(options.jsonBody.vehicleData);
    ApiHelper.call(
      options,
      function(err, data) {
        if (err) {
          const msg = err.message
            ? err.message
            : "An error occurred, please try again. Contact customer care if problem persists.";
          this.setState({
            errMsg: msg,
            errCode: err.respCode,
            inProcess: false,
          });
          return;
        } else {
          this.resetToDefaultValues();
          if (this.props.orgView) {
            this.props.fetchItemsForOrgView();
          } else {
            this.props.fetchItems();
          }
        }
      }.bind(this)
    );
  };

  deleteVehicle = () => {
    const id = this.state.selectedItem ? this.state.selectedItem.userlpId : null
    if (!id) {
      return
    }
    this.setState({
      inProcess: true,
      errMsg: null
    })
    const options = {
      method: 'DELETE',
      endPoint: ApiHelper.makeUrlPath(['vehicles', id], { orgId: this.props.orgId }),
    }
    const that =  this;
    ApiHelper.call(options, function (err, data) {
      if (err) {
        this.setState({
          errMsg: Helper.getString(err),
          inProcess: false,
          confirmDeleteView: false,
        })
        return
      } else {
        that.resetToDefaultValues();
        if (this.props.orgView) {
          this.props.fetchItemsForOrgView()
        } else {
          this.props.fetchItems();
        }
      }
    }.bind(this))
  }

  renderGuestVehicleDialog = () => {
    const formFields = [];
    for (const key in orgData.userFields) {
      formFields.push(orgData.userFields[key]);
    }
    if (this.props.dialogAction === "edit") {
      formFields[4]["disabled"] = true;
      if (this.responseByKey.alertSubscribers) {
        this.responseByKey.alertEnabled = this.responseByKey.alertSubscribers.includes(
          this.uId
        );
      }
    } else {
      formFields[4]["disabled"] = false;
    }
    return (
      <CustomFormFieldsDialogV2
        onClose={this.resetToDefaultValues}
        dialogOpen={this.props.showGuestVehicleDialog}
        dialogTitle={this.props.dialogTitle}
        confirmDeleteView={this.state.confirmDeleteView}
        inProcess={this.state.inProcess}
        selectedItem={this.state.selectedItem}
        deleteItem={() => this.deleteVehicle()}
        action={this.props.dialogAction}
        showConfirmDeleteView={this.showConfirmDeleteView}
        responseByKey={this.responseByKey}
        onSuccess={this.postGuestVehicle}
        formFields={formFields}
        enableValidation={this.props.dialogAction === "delete" ? false : true}
        formDisabled={this.props.dialogAction === "delete" ? true : false}
      ></CustomFormFieldsDialogV2>
    );
  };

  renderOwnVehicleDialog = () => {
    const formFields = [];
    for (const key in orgData.ownVehicleField) {
      formFields.push(orgData.ownVehicleField[key]);
    }

    if (this.props.dialogAction === "edit") {
      formFields[0]["disabled"] = true;
      if (this.responseByKey.alertSubscribers) {
        this.responseByKey.alertEnabled = this.responseByKey.alertSubscribers.includes(
          this.uId
        );
      }
    } else {
      formFields[0]["disabled"] = false;
    }
    return (
      <CustomFormFieldsDialogV2
        onClose={this.resetToDefaultValues}
        dialogOpen={this.props.showOwnVehicleDialog}
        dialogTitle={this.props.dialogTitle}
        confirmDeleteView={this.state.confirmDeleteView}
        inProcess={this.state.inProcess}
        selectedItem={this.state.selectedItem}
        action={this.props.dialogAction}
        showConfirmDeleteView={this.showConfirmDeleteView}
        responseByKey={this.responseByKey}
        deleteItem={() => this.deleteVehicle()}
        onSuccess={this.postOwnVehicle}
        formFields={formFields}
        enableValidation={this.props.dialogAction === "delete" ? false : true}
        formDisabled={this.props.dialogAction === "delete" ? true : false}
      ></CustomFormFieldsDialogV2>
    );
  };

  editVehicleClickClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
      <Fragment>
        <TableListV2
          tableColumnTitles={orgData.tableColumnTitles}
          tableColumnTitlesData={orgData.tableColumnTitles}
          items={this.props.vehicles}
          onRowItemClick={() => null}
          editClick={this.editVehicleClick}
          id={id}
        />
        <PopOverV2
          id={id}
          open={open}
          items={appData.vehiclePopOverFields}
          anchorEl={this.state.anchorEl}
          onPopOverClick={this.onVehiclePopOverClick}
          editClickClose={this.editVehicleClickClose}
        />

        {this.renderGuestVehicleDialog()}
        {this.renderOwnVehicleDialog()}
      </Fragment>
    );
  }
}
