/* eslint-disable */ 
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import async from 'async';
import moment from 'moment';
import { UiHelper } from '../common/helpers/UiHelper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { AppHelper } from '../helpers/AppHelper';
import { Controller } from '../common/Controller';
import { CustomFormFieldsDialogV2 } from '../components/CustomFormFieldsDialogV2';
import { ActionHeaderbar } from '../components/ActionHeaderbar';
import { PopOverV2 } from '../components/PopOverV2';
import { Tiles } from '../components/Tiles';
import { appData } from '../data/AppData';
import { CommonStyles } from '../styles/Styles';
import { AppConfig } from '../AppConfig';

export class Places extends Component {
  constructor(props) {
    super(props);
    this.orgId = this.props.match.params.id ? this.props.match.params.id : null;
    this.responseByKey = {}
    this.unseenPlaceTile = {}
    this.eventUpdateIntervalFnId = null;
    this.state = {
      updateMemberDialog: false,
      confirmDeleteView: false,
      inProcess: false,
      dialogAction: '',
      dialogTitle: '',
      selectedItem: {},
      anchorEl: null,
      fetchState: ApiHelper.State.LOADING,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.setFields()
    this.fetchItems();
  }

  setFields = () => {
    this.placeFields = []
    this.defaultPlaceFields = AppConfig.DEFAULT_FIELDS ? (AppConfig.DEFAULT_FIELDS.placeFields || []) : []
  }

  componentWillUnmount() {}

  fetchItems = () => {
    const orgId = this.orgId;
    async.parallel(
      [
        function (callback) {
          ApiHelper.call(
            { method: 'GET', endPoint: ApiHelper.makeUrlPath(['places'], { orgId }) }, callback)
        },
        function (callback) {
          ApiHelper.call(
            { method: 'GET', endPoint: ApiHelper.makeUrlPath(['orgs', orgId]) }, callback)
        },
      ],
      function (err, results) {
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err)
          })
          return
        }

        for (const item of results[0]) {
          if (item.loc && item.loc.length === 2) {
            item.lat = item.loc[0]
            item.long = item.loc[1]
          }
        }

        this.setState({
          items: results[0],
          org: results[1],
          fetchState: ApiHelper.State.READY,
        })
      }.bind(this))
  }

  resetToDefaultValues = () => {
    this.responseByKey = {}
    this.setFields()
    this.setState({
      updateMemberDialog: false,
      inProcess: false,
      dialogAction: '',
      dialogTitle: '',
      selectedItem: {},
      anchorEl: null,
      confirmDeleteView: false,
      errMsg: null
    })
  }

  showAddMemberDialog = () => {
    this.setState({
      updateMemberDialog: true,
      dialogTitle: 'Add a ' + AppHelper.getPlaceAltName(this.state.org),
      dialogAction: 'add'
    })
  }

  showConfirmDeleteView = () => {
    this.setState({
      confirmDeleteView: true,
      inProcess: false,
      dialogAction: '',
    })
  }

  showDeleteMemberDialog = () => {
    this.responseByKey = this.state.selectedItem
    this.setState({
      anchorEl: null,
      updateMemberDialog: true,
      dialogTitle: 'Delete a ' + AppHelper.getPlaceAltName(this.state.org),
      dialogAction: 'delete',
    })
  }

  showUpdateMemberDialog = () => {
    this.responseByKey = this.state.selectedItem
    this.setState({
      anchorEl: null,
      updateMemberDialog: true,
      dialogTitle: 'Edit a ' + AppHelper.getPlaceAltName(this.state.org),
      dialogAction: 'edit',
    })
  }

  showViewMemberDialog = () => {
    this.responseByKey = this.state.selectedItem
    this.setState({
      anchorEl: null,
      updateMemberDialog: true,
      dialogTitle: AppHelper.getPlaceAltName(this.state.org),
      dialogAction: 'viewInfo',
    })
  }

  onPopOverClick = (key) => {
    if (key === 'edit') {
      this.showUpdateMemberDialog()
    } else if (key === 'delete') {
      this.showDeleteMemberDialog()
    } else if (key === 'downloadReport') {
      Controller.get().pushRouteWithParams('/orgs/' + this.orgId + '/reports',
        {
          selectedPlaceIds: [this.state.selectedItem._id]
        })
    } else if (key === 'viewInfo') {
      this.showViewMemberDialog()
    }
  }

  editClick = (e, item) => {
    if (!e) {
      const e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    let nItem = {}
    nItem = Object.assign(nItem, item)
    this.setState({
      anchorEl: e.currentTarget,
      selectedItem: nItem
    })
  }

  editClickClose = () => {
    this.setState({ anchorEl: null })
  }

  processFields = () => {
    this.placeFields = []
    let typeFound = false
    if (this.state.org) {
      for (const f of AppConfig.orgTypes) {
        if (f.key === this.state.org.type) {
          typeFound = true
          if (f.placeFieldConfig) {
            this.placeFields = this.defaultPlaceFields.concat(f.placeFieldConfig)
          } else {
            this.placeFields = this.defaultPlaceFields.concat(appData.placeFields)
            this.placeFields = this.placeFields.filter((v, i, a) => a.findIndex(t => (t.key === v.key)) === i)
          }
          break;
        }
      }
      if (!typeFound) {
        this.placeFields = this.defaultPlaceFields.concat(appData.placeFields)
        this.placeFields = this.placeFields.filter((v, i, a) => a.findIndex(t => (t.key === v.key)) === i)
        this.responseByKey.type = 'general' //NOTE: Setting general as default type
      }
    } else {
      this.placeFields = this.defaultPlaceFields || appData.placeFields
    }

    for (const oField of this.placeFields) {
      if (oField.key === 'type') {
        const options = []
        for (const pType of AppConfig.placeTypes) {
          options.push({
            name: pType.name,
            key: pType.key
          })
        }
        oField.options = options
      }
    }
  }

  renderUpdateMemberDialog = () => {
    this.processFields()
    return (
      <CustomFormFieldsDialogV2
        onClose={this.resetToDefaultValues}
        dialogOpen={this.state.updateMemberDialog}
        dialogTitle={this.state.dialogTitle}
        confirmDeleteView={this.state.confirmDeleteView}
        inProcess={this.state.inProcess}
        selectedItem={this.state.selectedItem}
        deleteItem={() => this.deleteMember()}
        action={this.state.dialogAction}
        showConfirmDeleteView={this.showConfirmDeleteView}
        responseByKey={this.responseByKey}
        onSuccess={this.addMember}
        formFields={this.placeFields}
        errMsg={this.state.errMsg}
        enableValidation={(this.state.dialogAction === 'delete' || this.state.dialogAction === 'viewInfo') ? false : true}
        formDisabled={(this.state.dialogAction === 'delete' || this.state.dialogAction === 'viewInfo') ? true : false}>
      </CustomFormFieldsDialogV2>
    )
  }

  addMember = () => {
    this.setState({
      inProcess: true
    })

    let options = {}
    const id = this.state.selectedItem ? this.state.selectedItem._id : null

    const placeData = { orgId: this.orgId }
    const loc = []
    for (const pField of this.placeFields) {
      if (pField.key === 'loc') {
        placeData[pField.key] = [this.responseByKey.long, this.responseByKey.lat]
        continue
      }
      placeData[pField.key] = this.responseByKey[pField.key]
    }

    if (!id) {
      options = {
        method: 'POST',
        endPoint: ApiHelper.makeUrlPath(['places']),
        jsonBody: {
          placeData: placeData
        }
      }
    } else {
      options = {
        method: 'PUT',
        endPoint: ApiHelper.makeUrlPath(['places', id]),
        jsonBody: {
          placeData: placeData
        }
      }
    }

    Helper.removeNullValues(options.jsonBody.placeData)

    ApiHelper.call(options, function (err, data) {
      if (err) {
        this.setState({
          errMsg: Helper.getErrorMsg(err),
          errCode: err.respCode,
          inProcess: false,
        })
        return
      } else {
        this.resetToDefaultValues()
        this.fetchItems();
      }
    }.bind(this))
  }

  deleteMember = () => {
    const id = this.state.selectedItem ? this.state.selectedItem._id : null
    if (!id) {
      return
    }
    this.setState({
      inProcess: true
    })
    const options = {
      method: 'DELETE',
      endPoint: ApiHelper.makeUrlPath(['places', id]),
    }
    ApiHelper.call(options, function (err, data) {
      if (err) {
        this.setState({
          errMsg: Helper.getErrorMsg(err),
          errCode: err.respCode,
          inProcess: false,
        })
        return
      } else {
        this.resetToDefaultValues()
        this.fetchItems();
      }
    }.bind(this))
  }

  onRowItemClick = (item) => {
    Controller.get().pushRouteWithParams(
      '/places/' + item._id + '/cams',
      {
        item: item
      }
    )
  }

  highlight = (item) => {
    let unseenEvent = false
    item.cams.map(cam => {
      if ((item.latestEventTs && moment(cam.lastSeenTs).valueOf() < moment(cam.latestEventTs).valueOf()) || !cam.lastSeenTs) {
        unseenEvent = true
      }
    })
    this.unseenPlaceTile[item._id] = unseenEvent
  }

  onSettingClick = (e, item) => {
    if (!e) {
      const e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    Controller.get().pushRouteWithParams('/orgs/' + this.orgId + '/places/' + item._id +  '/settings')
  }

  render() {

    if (sessionStorage.getItem('_mwl') == '6479eae5-d5a9-4931-9206-38237e06d067') {
      sessionStorage.setItem('_mwl', 'b582e928-3c56-4b3d-a4da-5335faa5ca25')
      Controller.get().loginMgr().signOut()
    }

    if (this.state.fetchState === ApiHelper.State.LOADING) {
      return UiHelper.componentLoadingView();
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      return UiHelper.errorView(this);
    } else {
      return this.readyView();
    }
  }

  readyView = () => {
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
      <div className={css(CommonStyles.outerMainContainer)}>
        <ActionHeaderbar
          backPathName={Controller.get().userMgr().isProductUser() ? '/orgs/' + this.state.org._id : null}
          title={this.state.org.name}
          items={this.state.items}
          statPrefix={(this.state.items && this.state.items.length > 1) ? AppHelper.getPlaceAltName(this.state.org) + 's' : AppHelper.getPlaceAltName(this.state.org)}
          buttonOneAction={this.showAddMemberDialog}
          buttonOneTitle={'ADD NEW ' + AppHelper.getPlaceAltName(this.state.org).toUpperCase()}
          inProcess={this.state.inProcess}></ActionHeaderbar>


        <div className={css(CommonStyles.innerMainContainer)}>
          {this.state.items.length !== 0 &&
            <div className={css(Styles.innerMainContent)}>
              <Tiles
                type={'place'}
                defaultIcon={'place'}
                items={this.state.items}
                editClick={this.editClick}
                onSettingClick={this.onSettingClick}
                onRowItemClick={this.onRowItemClick}
                id={id} />
            </div>
          }
          {this.state.items.length === 0 &&
            <p className={css(CommonStyles.emptyView)}>{Helper.getString('cmEmptyView')}</p>
          }
          
        </div>

        {this.renderUpdateMemberDialog()}

        <PopOverV2
          id={id}
          open={open}
          items={(Controller.get().userMgr().isProductUser() || Controller.get().userMgr().isAdminUser()) ? appData.placeTilePopOverFields : appData.regularPopOverFields}
          anchorEl={this.state.anchorEl}
          onPopOverClick={this.onPopOverClick}
          editClickClose={this.editClickClose}
        />
      </div>
    )
  }
}


const Styles = StyleSheet.create({
  innerMainContent: {
    display: 'flex',
    width: '90%',
    flexDirection: 'column'
  },
})
