import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import async from 'async';
import { UiHelper } from '../common/helpers/UiHelper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { ActionHeaderbar } from '../components/ActionHeaderbar';
import { SettingsComponent } from '../components/SettingsComponent';
import { CommonStyles } from '../styles/Styles';
import { AppConfig } from '../AppConfig';

export class PlaceSettings extends Component {
  constructor(props) {
    super(props);
    this.orgId = this.props.match.params.id ? this.props.match.params.id : null;
    this.placeId = this.props.match.params.placeid ? this.props.match.params.placeid : null;
    this.state = {
      fetchState: ApiHelper.State.LOADING,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.fetchItems();
  }

  fetchItems = () => {
    const thisObj = this;
    async.parallel(
      [
        function (callback) {
          ApiHelper.call(
            { method: 'GET', endPoint: ApiHelper.makeUrlPath(['places', thisObj.placeId]) }, callback)
        },
      ],
      function (err, results) {
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err)
          })
          return
        }


        for (const f of AppConfig.placeTypes) {
          if (f.key === results[0].type) {
            this.data = f
            break;
          }
        }

        this.setState({
          place: results[0],
          fetchState: ApiHelper.State.READY,
        })
      }.bind(this))
  }


  render() {
    if (this.state.fetchState === ApiHelper.State.LOADING) {
      return UiHelper.componentLoadingView();
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      return UiHelper.errorView(this);
    } else {
      return this.readyView();
    }
  }

  readyView = () => {
    return (
      <div className={css(CommonStyles.outerMainContainer)}>
        <ActionHeaderbar
          backPathName={'/orgs/' + this.orgId + '/places'}
          statSuffix={'FEATURES & SETTINGS'}
          title={this.state.place.name}
        />
        <div className={css(CommonStyles.innerMainContainer)}>
          <div className={css(Styles.innerMainContent)}>
            {this.data && this.data.features &&
              <SettingsComponent
                data={this.data}
                item={this.state.place}
                itemId={this.placeId}
                itemName={'places'}
                apiBodyPrefix={'placeData'}
              />
            }
            {!this.data &&
              < p className={css(Styles.emptyDataContainer)}>{Helper.getString('noItemsFound')}</p>
            }
          </div>
        </div>
      </div>
    )
  }
}


const Styles = StyleSheet.create({
  innerMainContent: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    margin: '40px 0px 50px 0px',
    '@media(max-width: 768px)': {
      width: '90%',
    }
  },
  emptyDataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    flex: 1,
  },
})
