
export class RandHelper {
  static shuffleString(str) {
    return str.split('').sort(function () { return 0.5 - Math.random() }).join('')
  }
  static genRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  static genRandomString(len, allowedChars) {
    let result = '';
    for (let i = 0; i < len; i++) {
      const pos = Math.floor(Math.random() * allowedChars.length);
      result += allowedChars.substring(pos, pos + 1);
    }
    return result;
  }
  static generateTempCred() {
    const uppers = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const parts = []
    parts[0] = RandHelper.genRandomString(3, uppers)
    parts[1] = RandHelper.genRandomString(3, uppers.toLocaleLowerCase())
    parts[2] = RandHelper.genRandomString(3, '0123456789')
    const pos = '012'
    const f = RandHelper.genRandomString(1, pos)
    const s = RandHelper.genRandomString(1, pos.replace(f, ''))
    const t = pos.replace(f, '').replace(s, '')
    return RandHelper.shuffleString(
      parts[Number(f)] + parts[Number(s)] + parts[Number(t)] + RandHelper.genRandomString(1, '@_!')
    );
  }
}