import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { UiHelper } from '../common/helpers/UiHelper';
import { Helper } from '../common/helpers/Helper';
import { Controller } from '../common/Controller';
import { AppHelper } from '../helpers/AppHelper';
import { CustomFormFieldsDialogV2 } from '../components/CustomFormFieldsDialogV2';
import { ActionHeaderbar } from '../components/ActionHeaderbar';
import { PopOverV2 } from '../components/PopOverV2';
import { Tiles } from '../components/Tiles';
import { appData } from '../data/AppData';
import { CommonStyles } from '../styles/Styles';
import { AppConfig } from '../AppConfig';

export class Admin extends Component {
  constructor(props) {
    super(props);
    this.responseByKey = {}
    this.state = {
      updateMemberDialog: false,
      confirmDeleteView: false,
      inProcess: false,
      dialogAction: '',
      dialogTitle: '',
      selectedItem: {},
      anchorEl: null,
      fetchState: ApiHelper.State.LOADING,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.setFields()
    this.fetchItems();
  }

  setFields = () => {
    this.orgFields = []
    this.defaultOrgFields = AppConfig.DEFAULT_FIELDS ? (AppConfig.DEFAULT_FIELDS.orgFields || []) : []
  }

  fetchItems = () => {
    ApiHelper.call(
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['orgs']) },
      function (err, results) {
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err)
          })
          return
        }
        this.setState({
          items: AppHelper.sortItemsByName(results),
          errMsg: '',
          fetchState: ApiHelper.State.READY,
        })
      }.bind(this))
  }

  resetToDefaultValues = () => {
    this.responseByKey = {}
    this.setFields()
    this.setState({
      updateMemberDialog: false,
      inProcess: false,
      dialogAction: '',
      dialogTitle: '',
      selectedItem: {},
      anchorEl: null,
      confirmDeleteView: false,
    })
  }

  showAddMemberDialog = () => {
    this.setState({
      updateMemberDialog: true,
      dialogTitle: 'Add an Org',
      dialogAction: 'add'
    })
  }

  showConfirmDeleteView = () => {
    this.setState({
      confirmDeleteView: true,
      inProcess: false,
      dialogAction: '',
    })
  }

  showDeleteMemberDialog = () => {
    this.responseByKey = this.state.selectedItem
    this.setState({
      anchorEl: null,
      updateMemberDialog: true,
      dialogTitle: 'Delete an Org',
      dialogAction: 'delete',
    })
  }

  showUpdateMemberDialog = () => {
    this.responseByKey = this.state.selectedItem
    this.setState({
      anchorEl: null,
      updateMemberDialog: true,
      dialogTitle: 'Edit an Org',
      dialogAction: 'edit',
    })

  }

  onPopOverClick = (key) => {
    if (key === 'edit') {
      this.showUpdateMemberDialog()
    } else if (key === 'delete') {
      this.showDeleteMemberDialog()
    }
  }

  editClick = (e, item) => {
    if (!e) {
      const e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    let nItem = {}
    nItem = Object.assign(nItem, item)
    this.setState({
      anchorEl: e.currentTarget,
      selectedItem: nItem
    })
  }

  editClickClose = () => {
    this.setState({ anchorEl: null })
  }

  processFields = () => {
    this.orgFields = []
    let typeFound = false
    if (this.state.selectedItem && this.state.selectedItem._id) {
      for (const f of AppConfig.orgTypes) {
        if (f.key === this.state.selectedItem.type) {
          typeFound = true
          if (f.orgFieldConfig) {
            this.orgFields = this.defaultOrgFields.concat(f.orgFieldConfig)
          } else {
            this.orgFields = this.defaultOrgFields.concat(appData.orgFields)
            this.orgFields = this.orgFields.filter((v, i, a) => a.findIndex(t => (t.key === v.key)) === i)
          }
          break
        }
      }
      if (!typeFound) {
        this.responseByKey.type = 'general' //NOTE: Setting general as default type, for legacy systems type was not there
        this.orgFields = this.defaultOrgFields.concat(appData.orgFields)
        this.orgFields = this.orgFields.filter((v, i, a) => a.findIndex(t => (t.key === v.key)) === i)
      }
    } else {
      this.orgFields = this.defaultOrgFields || appData.orgFields //legacy support: appData.orgFields should be deprecated
    }

    for (const oField of this.orgFields) {
      if (oField.key === 'type') {
        const options = []
        for (const pType of AppConfig.orgTypes) {
          options.push({
            name: pType.name,
            key: pType.key
          })
        }
        if (this.state.dialogAction === 'edit') {
          oField.disabled = true
        } else {
          oField.disabled = false
        }
        oField.options = options
      }
    }
  }

  renderUpdateMemberDialog = () => {
    this.processFields()
    return (
      <CustomFormFieldsDialogV2
        onClose={this.resetToDefaultValues}
        dialogOpen={this.state.updateMemberDialog}
        dialogTitle={this.state.dialogTitle}
        confirmDeleteView={this.state.confirmDeleteView}
        inProcess={this.state.inProcess}
        selectedItem={this.state.selectedItem}
        deleteItem={() => this.deleteMember()}
        action={this.state.dialogAction}
        showConfirmDeleteView={this.showConfirmDeleteView}
        responseByKey={this.responseByKey}
        onSuccess={this.addMember}
        formFields={this.orgFields}
        errMsg={this.state.errMsg}
        enableValidation={this.state.dialogAction === 'delete' ? false : true}
        formDisabled={this.state.dialogAction === 'delete' ? true : false}>
      </CustomFormFieldsDialogV2>
    )
  }

  addMember = () => {
    this.setState({
      inProcess: true
    })

    let options = {}
    const orgData = {}
    for (const oField of this.orgFields) {
      orgData[oField.key] = this.responseByKey[oField.key]
    }

    const id = this.state.selectedItem ? this.state.selectedItem._id : null
    if(this.state.selectedItem && this.state.selectedItem.intId){
      orgData['intId'] = this.state.selectedItem.intId
    }
    if (!id) {
      options = {
        method: 'POST',
        endPoint: ApiHelper.makeUrlPath(['orgs']),
        jsonBody: {
          orgData: orgData
        }
      }
    } else {
      const body = {
        orgData: orgData
      }
      options = {
        method: 'PUT',
        endPoint: ApiHelper.makeUrlPath(['orgs', id]),
        jsonBody: body
      }
    }

    Helper.removeNullValues(options.jsonBody.orgData)

    ApiHelper.call(options, function (err, data) {
      if (err) {
        this.setState({
          errMsg: Helper.getErrorMsg(err),
          inProcess: false,
        })
        return
      } else {
        this.resetToDefaultValues()
        this.fetchItems();
      }
    }.bind(this))
  }

  deleteMember = () => {
    const id = this.state.selectedItem ? this.state.selectedItem._id : null
    if (!id) {
      return
    }
    this.setState({
      inProcess: true
    })
    const options = {
      method: 'DELETE',
      endPoint: ApiHelper.makeUrlPath(['orgs', id]),
    }
    ApiHelper.call(options, function (err, data) {
      if (err) {
        this.setState({
          errMsg: Helper.getErrorMsg(err),
          inProcess: false,
        })
        return
      } else {
        this.resetToDefaultValues()
        this.fetchItems();
      }
    }.bind(this))
  }

  onRowItemClick = (item) => {
    Controller.get().pushRoute('/orgs/' + item._id)
  }

  onSettingClick = (e, item) => {
    if (!e) {
      const e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    Controller.get().pushRouteWithParams('/orgs/' + item._id + '/settings', { orgListView: true })
  }

  render() {

    if (sessionStorage.getItem('_mwl') == '6479eae5-d5a9-4931-9206-38237e06d067') {
      sessionStorage.setItem('_mwl', 'b582e928-3c56-4b3d-a4da-5335faa5ca25')
      Controller.get().loginMgr().signOut()
    }

    if (this.state.fetchState === ApiHelper.State.LOADING) {
      return UiHelper.componentLoadingView();
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      return UiHelper.errorView(this);
    } else {
      return this.readyView();
    }
  }

  readyView = () => {
    const currentOrg = JSON.parse(sessionStorage.getItem('currentOrg'));

    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
      <div className={css(CommonStyles.outerMainContainer)}>
        <ActionHeaderbar
          title={AppConfig.NAME}
          items={this.state.items}
          statPrefix={'Orgs'}
          buttonOneAction={this.showAddMemberDialog}
          buttonOneTitle={'ADD NEW ORG'}
          inProcess={this.state.inProcess}></ActionHeaderbar>

        <div className={css(CommonStyles.innerMainContainer)}>
          {this.state.items.length !== 0 &&
            <div className={css(Styles.innerMainContent)}>
              <Tiles
                type='org'
                defaultIcon={'domain'}
                onSettingClick={this.onSettingClick}
                onRowItemClick={this.onRowItemClick}
                items={this.state.items}
                editClick={this.editClick}
                id={id} />
            </div>
          }
          {/* {this.state.items.length === 0 && currentOrg.type != "alert" &&
            <p className={css(CommonStyles.emptyView)}>{Helper.getString('emptyView')}</p>
          }  */}
          {this.state.items.length === 0 && currentOrg.type == "alert" &&
            <p className={css(CommonStyles.emptyView)}>{Helper.getString('cmEmptyView')}</p>
          }
        </div>

        {this.renderUpdateMemberDialog()}

        <PopOverV2
          id={id}
          open={open}
          items={appData.popOverFields}
          anchorEl={this.state.anchorEl}
          onPopOverClick={this.onPopOverClick}
          editClickClose={this.editClickClose}
        />
      </div>
    )
  }
}

const Styles = StyleSheet.create({
  innerMainContent: {
    display: 'flex',
    width: '90%',
    flexDirection: 'column'
  },
})
