import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { ApiHelper } from '../common/helpers/ApiHelper'
import { Helper } from '../common//helpers/Helper';
import { AppTheme } from '../styles/AppTheme';
import { UploadHelper } from '../common/helpers/UploadHelper';
import { ColourPalette } from '../styles/AppColourPalette'
import Icon from '@material-ui/core/Icon';
import async from 'async'

const RESIZE_DEFAULT = 512
export class ImageUploaderCustomV2 extends Component {
  constructor(props) {
    super(props);
    this.width = this.props.width || RESIZE_DEFAULT
    this.height = this.props.height || RESIZE_DEFAULT
    this.borderRadius = (this.props.borderRadius === 0) ? 0 : RESIZE_DEFAULT / 2
    this.currentUrl = this.props.url
    this.state = {
      file: '',
      message: '',
      fetchState: ApiHelper.State.READY,
      files: [],
      urlFiles:[],
      inProcess: false,
      // urlFiles:this.props.enableFR && Array.isArray(this.props.url) ? this.props.url : [],
      previousFiles:!this.props.enableFR && Array.isArray(this.props.url) ? this.props.url : [],
      errMessage:''
    };
    this.checkedImage = -1
    this.selectedImgArr = []
    this.imgArr = []
    if (this.props.enableFR) {
      this.setFrEventImage()
      this.imgArr = this.props.url
    }

  }

  setFrEventImage = () => {
    let len = this.props.url && this.props.url.length
    if (len == 0) {
      this.setState({
        errMessage: "Image size too small please try another Image",
      })
      return
    }
    let fncs = []
    for (let index = 0; index < this.props.url.length; index++) {
      fncs.push((callback) => {
        const element = this.props.url[index];
        this.setFrEventImage2(element, index, callback)
      })
    }

    async.parallel(fncs, (err, res) => {
      if (this.selectedImgArr.length > 0) {
        this.setState({
          urlFiles: this.props.enableFR && Array.isArray(this.selectedImgArr) ? this.selectedImgArr : [],
          errMessage: ''
        })

      } else {
        this.setState({
          errMessage: "Image size too small please try another Image",
        })
      }
    })
  }

  setFrEventImage2 = (element, index, callback) => {
    var img = document.createElement("img");
    img.src = element;
    img.onload = () => {
      img.style.visibility = 'hidden';
      document.body.appendChild(img);
      console.log(img.clientWidth);
      console.log(img.clientHeight);
      let indexOfImg = this.imgArr.indexOf(element);

      if ((img.clientWidth >= 100 && img.clientHeight >= 100) && indexOfImg > -1) {
        this.selectedImgArr.push(element)
      }
      callback(null, element)
    }

  }

  handleCheckboxChange = (i, f) =>{
    this.checkedImage = i
    this.props.setValue(f,'selectedImage')
  }

  render() {
    const ratio = this.width / this.height
    let height = 65
    let margin = 10
    let width = height * ratio
    if (width > 200) { // too wide
      width = 200
      height = width / ratio
    }
    const EditorStyles = StyleSheet.create({
      editor: {
        backgroundColor: 'white',
        height,
        width,
        margin
      },
    })
    return (
      <>
        <div>

          <div className={css(Styles.textContainer)}>
            <input
              className={css(Styles.fileInput)}
              id='contained-button-file'
              onChange={this.onFileChange.bind(this)}
              type='file'
              multiple={this.props.multi ? false : false}
              style={{ display: 'none' }}
              accept='image/png, image/jpeg'
            />

            <label hidden={this.props.enableFR} htmlFor='contained-button-file' style={{ color: "#000" }}>
              <div className={css(Styles.attachFile)}>
                <p>Attach file</p>
                <Icon className={css(Styles.searchIcon, Styles.upadateActions, Styles.uploadIcon)}>attach_file</Icon>
              </div>

            </label>
            {(this.state.file || (this.state.files && this.state.files.length > 0 && !this.props.enableFR)) &&
              <div className={css(Styles.actionButtons)}>
                <Button
                  variant='contained'
                  component='span'
                  onClick={this.onUpload.bind(this)}
                  className={css(Styles.upadateActions)}>
                  Upload
                  {(this.props.inProcess || this.state.inProcess) &&
                    <CircularProgress size={24} className={css(Styles.marginLeft)} />
                  }
                </Button>

              </div>}
            {this.renderMessage()}
          </div>
        </div>
        {
          this.state.files && this.state.files.length > 0 && <div>Current Image</div>
        }

        {
          <p>{this.state.errMessage}</p>
        }
        <div>

          {

            this.state.files && this.state.files.length > 0 && this.state.files.map((f, i) => {
              return (
                // <p key={i}>{i + 1 + ". " + f.name}</p>
                <img key={i} style={{ objectFit: "scale-down", imageRendering:"pixelated" }}
                  className={css(EditorStyles.editor)}
                  src={URL.createObjectURL(f)}
                  width={this.width}
                  height={this.height}
                  // border={1}
                  // borderRadius={this.borderRadius}
                  // color={[0, 0, 0, 0.5]}
                />
              )
            })
          }
        </div>

        <div>

          {

            this.state.urlFiles && this.state.urlFiles.length > 0 && this.state.urlFiles.map((f, i) => {
              return (
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox color={AppTheme.primaryBase} checked={this.checkedImage == i || (i == 0 && this.checkedImage == 0)} onChange={() => this.handleCheckboxChange(i, f)} key={i} />
                  }
                  label={
                    <>
                      <img style={{ objectFit: "scale-down", imageRendering:"pixelated" }}
                        className={css(Styles.editor)}
                        src={f}
                        width={this.width}
                        height={this.height}
                        // border={1}
                        // borderRadius={this.borderRadius}
                        // color={[0, 0, 0, 0.5]}
                      />
                    </>
                  }
                />

              )
            })
          }
        </div>
        {
          this.state.previousFiles && this.state.previousFiles.length > 0 && <div> Previous Images</div>
        }
        <div>

          {
            this.state.previousFiles && this.state.previousFiles.length > 0 && this.state.previousFiles.map((f, i) => {
              return (

                <img
                  style={{ objectFit: 'scale-down', imageRendering:"pixelated" }}
                  className={css(EditorStyles.editor)}
                  src={f}
                  width={this.width}
                  height={this.height}
                  border={1}
                  borderRadius={this.borderRadius}
                  color={[0, 0, 0, 0.5]}
                />
              )
            })
          }
        </div>
      </>
    )
  }

  renderShowFileName() {
    if (this.state.file &&
      this.state.file.name) {
      return (
        <p className={css(Styles.fileName)}>{this.state.file.name}</p>
      )
    }
  }

  renderMessage() {
    let messageClass = css(Styles.textUpload, Styles.text)
    if (this.state.fetchState === ApiHelper.State.ERROR) {
      messageClass = css(Styles.textError, Styles.text)
    } else if (this.state.fetchState === ApiHelper.State.READY) {
      messageClass = css(Styles.textSuccess, Styles.text)
    }
    return (<p className={messageClass}>{this.state.message}</p>)
  }

  setEditorRef(editor) {
    this.editor = editor
  }

  setMultipleFiles() {
    if (!this.state.files || this.state.files.length == 0) {
      this.setState({
        inProcess: false
      })
      return
    }
    for (let index = 0; index < this.state.files.length; index++) {
      const element = this.state.files[index];
      this.setState({
        file: element
      }, () => this.onUpload('', true))
      if (index == this.state.files.length - 1) {
        this.setState({
          inProcess: false
        })
      }
    }
  }

  onUpload(obj, multi) {
    this.setState({
      inProcess: true
    })
    if (this.props.multi && !multi) {
      this.setMultipleFiles()
    } else {

      // if (!this.editor) {
      //   this.setState({
      //     inProcess: false
      //   })
      //   return this.onError('Unable to edit the image')
      // }
      if (!this.state.file) {
        this.setState({
          inProcess: false
        })
        return this.onError('Choose a file')
      }
      this.setState(
        {
          message: '',
          fetchState: ApiHelper.State.READY
        }
      )
      // const scaledCanvas = this.editor.getImageScaledToCanvas()
      // const blob = window.atob(this.state.dataURL.split(',')[1]);
      var reader = new FileReader();


      const options = {
        type: this.props.uploadType || 'media',
        // ext: this.props.uploadExt || 'jpg' // toBlob gives png by default
        ext: this.state.dataURL.substring(this.state.dataURL.indexOf("/") + 1, this.state.dataURL.indexOf(";"))

      }

      UploadHelper.fetchUploadUrl({}, options, (err, signedUploadInfo) => {
        if (err) {
          this.setState({
            inProcess: false
          })
          return this.onError(err)
        }
        const uploadOptions = {
          pickerResult: this.state.file,
          signedUploadInfo: signedUploadInfo,
          onEvent: (type, percent) => {
            console.log('progress:', type, percent)
            if (type === 'progress') {
              return this.onUploadProgress(percent)
            } else if (type === 'load') {
              return this.onUploadComplete(signedUploadInfo)
            }
            else if (type === 'error') {
              this.setState({
                inProcess: false
              })
              return this.onError('Upload failed. You can try again')
            }
          }
        }
        UploadHelper.uploadFormDataWithProgress(uploadOptions)

      })
    };

  }

  readmultifiles = (files, n) => {
    return this.readFile(0, files, n);
  }

  readFile = (index, files, n) => {
    var reader = new FileReader();
    if (index >= files.length) return;
    var file = files[index];
    reader.onload = function (e) {
      var bin = e.target.result;
      this.setState(
        {
          files: [...this.state.files, file],
          dataURL: e.target.result,
          message: '',
          fetchState: index == n ? ApiHelper.State.READY : ""
        }, () => this.readFile(index + 1, files, n)
      )

    }.bind(this)
    reader.readAsDataURL(file);
  }

  onFileChange(e, f) {
    // if (this.props.multi) {
    //   const files = e.target.files
    //   this.readmultifiles(files, files.length - 1)
    // } else {
    let file
    if (f) {
      file = f
    } else {
      file = e.target.files[0]
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState(
        {
          file: file,
          // files: [...this.state.files, file],
          files: [file],
          dataURL: e.target.result,
          message: '',
          fetchState: ApiHelper.State.READY
        }
      )
    }
    reader.readAsDataURL(file)
    // }
  }

  onUploadProgress(percent) {
    //console.log('percent:', percent);
    this.setState({
      message: 'Uploading: ' + percent + ' %',
      fetchState: ApiHelper.State.LOADING
    })
  }

  onUploadComplete(signedUploadInfo) {
    this.setState({
      message: 'Upload successful',
      fetchState: ApiHelper.State.READY,
      inProcess: false
    })
    if (this.props.onUploadSuccess) {
      this.props.onUploadSuccess(this.props.uploadId, signedUploadInfo)
    }
  }

  onError(err) {
    //console.log('err:', err);
    this.setState({
      message: Helper.getErrorMsg(err),
      fetchState: ApiHelper.State.ERROR
    })
  }
}

const Styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    //alignItems: 'flex-start',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
    cursor: 'pointer !important',
    width: '20%'
  },
  fileInput: {
    width: '100%',
    marginLeft: 10,
  },
  textButton: {
    backgroundColor: 'black',
    color: 'white',
    maxWidth: 100,
    minHeight: 24,
    textAlign: 'center',
    marginTop: 5,
    fontSize: 10
  },
  text: {
    marginTop: 3,
    marginBottom: 3,
    fontSize: 10
  },
  textUpload: {
    color: 'white',
  },
  textError: {
    color: 'red',
  },
  textSuccess: {
    color: 'green',
  },
  editor: {
    width: '100px',
    height: '100px',
  },
  upadateActions: {
    backgroundColor: ColourPalette.color3,
    color: 'white',
    marginRight: 20,
  },
  uploadIcon: {
    marginLeft: '0.5rem',
    borderRadius: '40%',
    padding: '0.2rem',
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
  },
  cancelActions: {
    backgroundColor: '#337AB7',
    color: 'white'
  },
  attachFile: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  actionButtons: {
    display: 'flex',
    marginBottom: 20
  },
  marginLeft: {
    marginLeft: 10,
  },
  errMsg: {
    color: 'red',
    fontSize: 14
  },
  fileName: {
    color: AppTheme.white,
    fontSize: 14
  },
  label: {
    display: 'flex',
    width: '18%',
    background: ' rgb(107, 95, 253) !important',
    borderRadius: '1rem',
    paddingLeft: '2.3rem',
    color:"#000",
    marginBottom: '0.5rem'
  },
  
})
