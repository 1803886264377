/* eslint-disable */
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { ValidationHelper } from '../helpers/ValidationHelper';
import { AppTheme } from '../styles/AppTheme';
import {
  StyleSheet,
  css
} from 'aphrodite';
import Autocomplete from '@material-ui/lab/Autocomplete';

export class AutoComplete extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value
      // || this.props.formField.defaultValue
    }
  }
  // componentDidMount(){
  //   if(!this.props.value){
  //     this.setState({ value: this.props.formField.defaultValue});
  //     this.props.setValue(this.props.formField.defaultValue, this.props.formField.key)
  //   }else{
  //     this.setState({ value: this.props.value});
  //     this.props.setValue(this.props.value, this.props.formField.key)
  //   }
  // }

  change = (event, value) => {
    if (value) {
      this.setState({ value: value });
      this.props.setValue(value.label, 'name');
      this.props.setValue(value.value, '_id');
    } else {
      this.setState({ value: event.target.value });
      this.props.setValue(event.target.value, 'name');
    }
  }

  render() {
    if (this.props.hidden && this.props.hidden == true) {
      return (<></>)
    }
    const options = this.props.autoCompleteData.map((option) => ({
      value: option._id, 
      label: option.name, 
    }))
    return (
      <Autocomplete
        freeSolo={true}
        debug
        // onChange={e => this.change(e)}
        onChange={this.change}
        id={this.props.formField.key + ' ' + new Date()}
        disableClearable={true}
        style={{ width: "96%" }}
        options={options}
        getOptionLabel={(option) => option.label}
        // options={this.props.autoCompleteData.map((option) => (option.name))}
        renderInput={(params) => (
          <TextField
            {...params}
            label={this.props.formField.hint || this.props.formField.label}
            onChange={(e) => this.change(e)}
            className={css(Styles.inputCss)}
            value={this.state.value}
            margin='normal'
            helperText={!this.props.enableValidation ? '' : ValidationHelper.validateFields(this.props.formField, this.state.value)}
            disabled={this.props.disabled}
            FormHelperTextProps={{ classes: { root: css(Styles.errorMsgStyle) } }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              classes: {
                root: this.props.action === 'viewInfo' ?
                  css(Styles.textFieldContentInput, Styles.textFieldContentInputView) : css(Styles.textFieldContentInput)
              },
              type: 'search'
            }}
            InputLabelProps={{
              classes: {
                root: css(Styles.textFieldContent),
                shrink: css(Styles.textFieldContentShrink),
              },
              shrink: this.props.formField.inputDate,
            }}
          />
        )}
      />

    )
  }
}

const Styles = StyleSheet.create({
  inputCss: {
    margin: 8,
    // flex: 1,
    // color: AppTheme.white,
    color: "#000",

    minHeight: AppTheme.formFieldHeight
  },
  errorMsgStyle: {
    color: AppTheme.white,
    paddingLeft: 8
  },
  textFieldContentInput: {
    borderRadius: 4,
    // backgroundColor: AppTheme.secondaryColor,
    paddingLeft: 8,
    // color: AppTheme.white,
    backgroundColor: "rgb(238, 238, 238) !important",
    color: "#000",
  },
  textFieldContentInputView: {
    backgroundColor: AppTheme.primaryBackground,
  },
  textFieldContent: {
    // color: AppTheme.white,
    zIndex: 1,
    color: "#000",
    paddingLeft: 8,
    opacity: .6,
    '@media(max-width: 767px)': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      right: 22,
    }
  },
  textFieldContentShrink: {
    right: 'unset'
  }
})