import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import AvatarEditor from 'react-avatar-editor'
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ApiHelper } from '../helpers/ApiHelper';
import { Helper } from '../helpers/Helper';

import { UploadHelper } from '../helpers/UploadHelper';

const RESIZE_DEFAULT = 512
export class ImageUploader extends Component {
  constructor(props) {
    super(props);
    this.width = this.props.width || RESIZE_DEFAULT
    this.height = this.props.height || RESIZE_DEFAULT
    this.borderRadius = (this.props.borderRadius === 0) ? 0 : RESIZE_DEFAULT / 2
    this.currentUrl = this.props.url
    this.state = {
      message: '',
      fetchState: ApiHelper.State.READY
    };
  }

  componentDidMount() {
  }

  render() {
    const ratio = this.width / this.height
    let height = 65
    let width = height * ratio
    if (width > 200) { // too wide
      width = 200
      height = width / ratio
    }
    const EditorStyles = StyleSheet.create({
      editor: {
        backgroundColor: 'white',
        height,
        width
      },
    })
    return (
      <div
        className={css(Styles.container, this.props.imageUploaderContainer)}>
        <div className={css(Styles.imgContainer)}>
          <AvatarEditor
            ref={this.setEditorRef.bind(this)}
            className={css(EditorStyles.editor)}
            image={this.state.dataURL || this.currentUrl}
            width={this.width}
            height={this.height}
            border={1}
            borderRadius={this.borderRadius}
            color={[0, 0, 0, 0.5]} // RGBA
          />
        </div>
        <div className={css(Styles.textContainer)}>
          <input
            className={css(Styles.fileInput)}
            onChange={this.onFileChange.bind(this)}
            type='file'
            accept={this.props.uploadAccept || 'image/png, image/jpeg'} />
          <Button
            className={css(Styles.textButton)}
            size='small'
            onClick={this.onUpload.bind(this)}>
            {this.state.buttonState === 'loading' ?
              <CircularProgress size={24} className={css(Styles.progressIcon)} />
              :
              'Upload'.toUpperCase()
            }
          </Button>
          {this.renderMessage()}
        </div>
      </div>
    )
  }

  renderMessage() {
    let messageClass = css(Styles.textUpload, Styles.text)
    if (this.state.fetchState === ApiHelper.State.ERROR) {
      messageClass = css(Styles.textError, Styles.text)
    } else if (this.state.fetchState === ApiHelper.State.READY) {
      messageClass = css(Styles.textSuccess, Styles.text)
    }
    return (<p className={messageClass}>{this.state.message}</p>)
  }

  setEditorRef(editor) {
    this.editor = editor
  }

  onUpload() {
    if (!this.editor) {
      return this.onError('Unable to edit the image')
    }
    if (!this.state.file) {
      return this.onError('Choose a file')
    }
    this.setState(
      {
        message: '',
        fetchState: ApiHelper.State.READY
      }
    )
    const scaledCanvas = this.editor.getImageScaledToCanvas()
    scaledCanvas.toBlob((blob) => {
      const options = {
        type: this.props.uploadType || 'media',
        ext: this.props.uploadExt || 'jpg' // toBlob gives png by default
      }
      UploadHelper.fetchUploadUrl({}, options, (err, signedUploadInfo) => {
        if (err) {
          return this.onError(err)
        }
        //console.log('fetchUploadUrl:', err, signedUploadInfo)
        const uploadOptions = {
          pickerResult: blob,
          signedUploadInfo: signedUploadInfo,
          onEvent: (type, percent) => {
            //console.log('progress:', type, percent)
            if (type === 'progress') {
              return this.onUploadProgress(percent)
            } else if (type === 'load') {
              return this.onUploadComplete(signedUploadInfo)
            } else if (type === 'error') {
              return this.onError('Upload failed. You can try again')
            }
          }
        }
        UploadHelper.uploadFormDataWithProgress(uploadOptions)
      })
    }, 'image/png', 0.7)
  }

  onFileChange(e) {
    const file = e.target.files[0]
    // TODO: validate Extension & Size
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState(
        {
          file: file,
          dataURL: e.target.result,
          message: '',
          fetchState: ApiHelper.State.READY
        }
      )
    }
    reader.readAsDataURL(file)
  }

  onUploadProgress(percent) {
    //console.log('percent:', percent);
    this.setState({
      message: 'Uploading: ' + percent + ' %',
      fetchState: ApiHelper.State.LOADING
    })
  }

  onUploadComplete(signedUploadInfo) {
    this.setState({
      message: 'Upload successful',
      fetchState: ApiHelper.State.READY
    })
    if (this.props.onUploadSuccess) {
      this.props.onUploadSuccess(this.props.uploadId, signedUploadInfo)
    }
  }

  onError(err) {
    this.setState({
      message: Helper.getErrorMsg(err),
      fetchState: ApiHelper.State.ERROR
    })
  }
}

const Styles = StyleSheet.create({
  container: {
    backgroundColor: 'whitesmoke',
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10
  },
  fileInput: {
    width: '100%'
  },
  textButton: {
    backgroundColor: 'black',
    color: 'white',
    maxWidth: 100,
    minHeight: 24,
    textAlign: 'center',
    marginTop: 5,
    fontSize: 10
  },
  text: {
    marginTop: 3,
    marginBottom: 3,
    fontSize: 10
  },
  textUpload: {
    color: 'white',
  },
  textError: {
    color: '#FF682A',
  },
  textSuccess: {
    color: '#FF682A',
  },
  editor: {
    width: 65,
    height: 65,
  }
})
