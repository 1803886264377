/* eslint-disable */ 
import moment from 'moment';
import { Strings } from './Strings';
import { AppConfig } from '../../AppConfig';

export class Helper {
  static inArray(arr, value) {
    if (!arr) return false
    return arr.indexOf(value) >= 0 ? true : false
  }

  static encodeQueryString = (data) => {
    const ret = [];
    for (const d in data) {
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    }
    return ret.join('&');
  }

  static dateTimeToEpoch(dateStr, zoneinfo) {
    const date = moment(dateStr)
    return moment.tz(
      date.format('YYYY') + '-' + date.format('MM') + '-' + date.format('DD') + ' ' +
      date.format('hh') + ':' + date.format('mm') + ':' + date.format('ss') + ' ' + date.format('A'),
      'YYYY-MM-DD hh:mm:ss A', zoneinfo).valueOf()
  }

  static getString(key) {
    return Strings.getString(key) || key
  }

  static getErrorMsg(error) {
    let message;
    if (typeof error === 'string') {
      message = error;
    }
    if (!message) {
      if (error) {
        if (error.payload && error.payload.message) {
          message = error.payload.message
        }
        if (!message) {
          message = error.message;
        }
        if (!message) {
          message = error.description;
        }
        if (!message) {
          message = error.message;
        }
        if (!message) {
          message = error.description;
        }
      }
    }
    return message || 'An error occurred. Contact customer care team if the problem persists';
  }

  static validateAndFormatEmail(email) {
    const result = email.replace(/\s/g, '')
    if (!/\S+@\S+\.\S+/.test(result)) {
      throw new Error('Invalid email');
    }
    return result.toLowerCase()
  }

  static validateAndFormatEmailV2(email) {
    const result = email.replace(/\s/g, '')
    if (!/\S+@\S+\.\S+/.test(result)) {
      return false
    }
    return result.toLowerCase()
  }

  static validateEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  static isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
 }

  static removeNullValues(body) {
    const REMOVE_ATTRS = []
    for (const key in body) {
      if (body[key] === undefined || body[key] === null) {
        REMOVE_ATTRS.push(key)
        delete body[key]
      }
      if (body[key] === '') {
        REMOVE_ATTRS.push(key)
        delete body[key]
      }
    }
    body.REMOVE_ATTRS = REMOVE_ATTRS
  }

  static arrToDict(items, id) {
    const newDict = {}
    for (const item of items) {
      newDict[item[id]] = item
    }
    return newDict
  }
}
