import { StyleSheet } from 'aphrodite';
import { AppConfig } from '../AppConfig';

export const AppTheme = {
  primaryColor:"#fff",
  primaryBackground: '#323B4E',
  secondaryColor: '#161C26',
  secondaryColorVariant:'#1E2530',
  white: '#FFFFFF',
  black: '#000000',
  pagePadding: 5,
  fontSizeContent: 12,
  fontSizeTitle: 16,
  actionHeaderbarHeight: 33,
  formFieldHeight: 70,
  primaryBase: AppConfig.product == "alibi" ? "#00a7cf" :"#377bac",
  displayFooter:AppConfig.product == "alibi" ? "none" :"block"
  
}

export const AppStyles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: AppTheme.primaryBackground,
  },
  entityTableLinkIcon: {
    padding: 3,
    fontSize: 28,
    color: AppTheme.black,
    cursor: 'pointer'
  },
});
