/* eslint-disable */ 
import React, { Component } from 'react';
import {
  css
} from 'aphrodite';
import { Styles } from './Styles'
import 'rc-slider/assets/index.css';
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import { ChartCard } from '../../common/components/ChartCard'
import { Helper } from '../../common/helpers/Helper';


export class ChartComponent extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
  }

  outerHeaderStatCardStyle = () => {
    if (!this.props.orgLevel) {
      return css(Styles.outerHeaderStatCard, Styles.outerHeaderStatCardSpec)
    } else {
      return css(Styles.outerHeaderStatCard)
    }
  }

  render = () => {
    return (
      <SlideDown className={'my-dropdown-slidedown'}>

        {this.props.showChart ?
          <div className={css(Styles.outerHeaderStatCard)}>
            <div className={css(Styles.statContainer, Styles.statContainerSpec)}>
              <div className={css(Styles.statContainerTitle)}>
                <Icon
                  className={css(Styles.barChartIcon)}>
                  bar_chart
                </Icon>
                <p className={css(Styles.statTitle)}>Reports</p>
                <div className={css(Styles.statCollapse)}>
                  <Icon
                    onClick={() => this.props.showChartHandler(!this.props.showChart)}
                    className={css(Styles.statsUpIcon)}>
                    keyboard_arrow_up
                  </Icon>
                </div>
              </div>
              {(!this.props.data || !this.props.data.length) &&
                <div className={css(Styles.noItemsToShow)}>
                  <p>{Helper.getString('noItemsFound')}</p>
                </div>
              }
              {this.props.data && this.props.data.length > 0 &&
                <Grid container spacing={2} className={css(Styles.gridContainerVariant)} >
                  {
                    (this.props.data && this.props.data.length > 0) &&
                    this.props.data.map((item, index) => (
                      <ChartCard
                        key={index}
                        item={item}
                        show={item.labels.length > 0 && item.datasets.length > 0 && item.title != "Active time"}
                      />
                    ))
                  }
                </Grid>
              }
            </div>
          </div>
          :
          null
        }
      </SlideDown>
    )
  }
}