export const orgData = {
  tableColumnTitles: [
    {
      text: 'Name',
      key: 'name',
      type: 'text'
    },
    {
      text: 'License plate',
      key: 'lp',
      type: 'text'
    },
    {
      text: 'Type',
      key: 'type',
      type: 'text'
    },
    {
      text: 'Expiry',
      key: 'expireAt',
      type: 'text'
    }
  ],
  userFields: {
    name: {
      key: 'name',
      label: 'Name',
      type: 'input_text',
      placement: 'left',
      required: { value: true, err: 'Provide name of the guest' },
    },
    email: {
      key: 'email',
      label: 'Email Address',
      type: 'input_text',
      placement: 'right',
      required: { value: true, err: 'Provide your email' },
      email: { value: true, err: 'Provide a valid email' }
    },
    phone: {
      key: 'phone',
      label: 'Phone',
      type: 'input_text',
      placement: 'left',
      required: { value: true, err: 'Provide phone number' },
      phone: { value: true, err: 'Provide a valid phone' },
    },
    vehicleName: {
      key: 'vehicleName',
      label: 'Vehicle Name',
      type: 'input_text',
      placement: 'right',
      required: {value: true, err: 'Enter guest\'s vehicle name or model'}
    },
    lp: {
      key: 'lp',
      label: 'Vehicle Number',
      type: 'input_text',
      placement: 'left',
      required: { value: true, err: 'Provide guest\'s vehicle number' },
    },
    expireAt: {
      key: 'expireAt',
      label: 'Expire on',
      type: 'input_date',
      placement: 'right',
      required: { value: true, err: 'Provide maximum allowed date' },
      expireAt: { value: true, err: 'Provide a valid date' },
    },
    alertToggle: {
      key: 'alertEnabled',
      label: 'Email Alerts',
      type: 'toggler',
      placement: 'left',
      required: {value: false, err: 'Email Alerts'}
    }
  },
  ownVehicleField: {
    lp: {
      key: 'lp',
      label: 'Vehicle Number',
      type: 'input_text',
      placement: 'left',
      required: { value: true, err: 'Enter your license plate' },
    },
    vehicleName: {
      key: 'vehicleName',
      label: 'Vehicle Name',
      type: 'input_text',
      placement: 'right',
      required: {value: true, err: 'Enter your vehicle name'}
    },
    alertToggle: {
      key: 'alertEnabled',
      label: 'Email Alerts',
      type: 'toggler',
      placement: 'left',
      required: {value: false, err: 'Email Alerts'}
    }
  },
  popOverFields: [
    {
      key: 'delete',
      title: 'Delete'
    }
  ]
}