/* eslint-disable */ 
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { moment } from 'moment';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import { AppHelper } from '../helpers/AppHelper';
import { Helper } from '../common/helpers/Helper';
import { AppTheme } from '../styles/AppTheme';
import { ColourPalette } from '../styles/AppColourPalette';

export class Tiles extends Component {
  constructor(props) {
    super(props);
    this.unseenPlaceTile = {}
  }

  componentDidMount() { }

  highlight = (item) => {
    if (!item || !item.cams) return
    let unseenEvent = false
    item.cams.map(cam => {
      if ((item.latestEventTs && moment(cam.lastSeenTs).valueOf() < moment(cam.latestEventTs).valueOf()) || !cam.lastSeenTs) {
        unseenEvent = true
      }
    })
    this.unseenPlaceTile[item._id] = unseenEvent
  }

  render() {
    if (!this.props.items || this.props.items.length === 0) {
      return (<p>{Helper.getString('emptyView')}</p>)
    }

    return (
      <div className={css(Styles.innerContainer)}>
        <Grid container spacing={4}>
          {this.props.items.map((item, index) => {
            this.highlight(item)
            return (
              <Grid
                key={index}
                item xs={12} sm={4} md={3} className={css(Styles.tileContainer)}>
                <div
                  className={this.unseenPlaceTile[item._id] ? css(Styles.tileContent, Styles.tileContentHighlight) : css(Styles.tileContent)}
                  onClick={(e) => this.props.onRowItemClick(item)}>
                  <div className={css(Styles.imgOuterContainer)}>
                    {item.logoUrl &&
                      <div className={css(Styles.imgInnerContainer)}>
                        <img
                          src={item.logoUrl}
                          onError={(e) => {
                            e.target.src = require('../assets/images/error.svg')
                          }} className={css(Styles.imgContent)} />
                      </div>
                    }

                    {!item.logoUrl &&
                      <Icon
                        className={css(Styles.defaultIcon)}>
                        {this.props.defaultIcon}
                      </Icon>
                    }
                  </div>

                  <div className={css(Styles.tileDetailContainer)}>
                    <p className={css(Styles.tileDetailContainerName)}>{item.name}</p>

                    {(AppHelper.showSettings(item, this.props.type)) &&
                      <div
                        aria-describedby={this.props.id}
                        onClick={(e) => this.props.onSettingClick(e, item)}
                        className={css(Styles.editContent)}>
                        <Icon className={css(Styles.settingsIcon)}>settings</Icon>
                      </div>
                    }

                    {item.cams &&
                      <div
                        aria-describedby={this.props.id}
                        className={css(Styles.camIconContainer)}>
                        <p className={css(Styles.tileDetailContainerName)}>{item.cams.length}</p>
                        <Icon className={css(Styles.camIcon)}>camera_alt</Icon>
                      </div>
                    }

                    <div
                      aria-describedby={this.props.id}
                      onClick={(e) => this.props.editClick(e, item)}
                      className={css(Styles.editContent, Styles.bgBlack)} >
                      <img src={require('../assets/images/more-rounded.svg')} />
                    </div>
                  </div>
                </div>
              </Grid>
            )
          })}
        </Grid>
      </div>
    )
  }
}


const Styles = StyleSheet.create({
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 65,
    marginBottom: 50
  },
  tileContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  tileContent: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0 10px 10px 10px',
    // backgroundColor: AppTheme.secondaryColor,
    position: 'relative',
    // border: '2px solid' + AppTheme.secondaryColor,
    cursor: 'pointer',
  },
  imgOuterContainer: {
    width: '100%',
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: AppTheme.primaryBackground,
    backgroundColor:"snow",
    borderRadius: '0px 10px 0px 0px'
  },
  imgInnerContainer: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    display: 'flex',
  },
  imgContent: {
    maxWidth: '95%',
    maxHeight: '95%',
    borderRadius: '50%'
  },
  editContent: {
    cursor: 'pointer',
    width: 30,
    height: 30,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ':hover': {
      // backgroundColor: AppTheme.primaryBackground,
      background:"rgba(0, 0, 0, 0.07)"

    }
  },
  camIconContainer: {
    display: 'flex'
  },
  tileDetailContainer: {
    display: 'flex',
    color:"#000 !important",
    padding: 15,
    alignItems: 'center',
    // backgroundColor: AppTheme.secondaryColorVariant,
    backgroundColor:"#fff",
    borderRadius: '0px 0px 10px 10px'
  },
  bgBlack : {
    // backgroundColor:"#000"
  },
  tileDetailContainerName: {
    // color: AppTheme.white,
    color:"#000 !important",

    opacity: 0.7,
    fontSize: AppTheme.fontSizeTitle,
    margin: 0,
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  defaultIcon: {
    fontSize: 100,
    // color: ColourPalette.color1,
    color:AppTheme.primaryBase,

  },
  settingsIcon: {
    // color: AppTheme.white,
    color:AppTheme.primaryBase,

    fontSize: 18
  },
  camIcon: {
    // color: AppTheme.white,
    color:AppTheme.primaryBase,

    fontSize: 22,
    marginLeft: 5,
    // opacity: 0.7
  },
  tileContentHighlight: {
    // border: '2px solid #FF682A'
  },
})
