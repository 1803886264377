import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppTheme } from '../../styles/AppTheme';
import { Bar, Line } from 'react-chartjs-2';


export class ChartCard extends Component {
  constructor(props) {
    super(props);
    this.defaultErrMsg = 'An error occured.'
    this.state = {
      inProcess: false
    };
  }
  render() {
    return (
      <Grid item xs={12} sm={12} md={12} className={css(Styles.container)}>
       {this.props.show && !this.props.item.title.toLowerCase().includes('hourly') && <Grid item xs={12} sm={6}>
          {this.props.show && <div className={css(Styles.paperTitle)} elevation={0}>
            <p className={css(Styles.paragraphMargin)}>{this.props.item.title}</p>
          </div>}
          <div>
            {this.state.inProcess &&
              <div className={css(Styles.loader)}>
                <CircularProgress size={48} className={css(Styles.progress)} />
              </div>
            }
          </div>
           <Bar
            height={null}
            width={null}
            options={{
              aspectRatio: window.innerWidth < 767 ? 1 : 2,
              responsive: true,
              legend: {
                display: false,
              },
              type: 'bar',
              scales: {
                xAxes: [{
                  stacked: true,
                  maxBarThickness: 40,
                  position: 'left'
                }],
                yAxes: [{
                  stacked: true
                }]
              }
            }}
            data={this.props.item}
          />
        </Grid>}
        {this.props.show && this.props.item.title.toLowerCase().includes('hourly') &&  <Grid item xs={12} sm={12} md={12}>
          {this.props.show && <div className={css(Styles.paperTitle)} elevation={0}>
            <p className={css(Styles.paragraphMargin)}>{this.props.item.title}</p>
          </div>}
          <div>
            {this.state.inProcess &&
              <div className={css(Styles.loader)}>
                <CircularProgress size={48} className={css(Styles.progress)} />
              </div>
            }
          </div>
        
            <Line
              options={{
                aspectRatio: window.innerWidth < 767 ? 1 : 2,
                responsive: true,
                legend: {
                  display: false,
                },
                type: 'bar',
                scales: {
                  xAxes: [{
                    stacked: true,
                    maxBarThickness: 40,
                    position: 'left'
                  }],
                  yAxes: [{
                    stacked: true
                  }]
                }
              }}
              data={this.props.item}
            />
        </Grid>}
      </Grid>
    );
  }
};

const Styles = StyleSheet.create({
  container: {
    color: '#474747',
    backgroundColour: 'red',
  },
  paperFooter: {
    marginBottom: 0,
    display: 'flex',
    verticalAlign: 'top',
    justifyContent: 'flex-end'
  },
  paperTitle: {
    fontWeight: 'bold',
    color: AppTheme.white,
    opacity: 0.8,
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center'
  },
  subText: {
    display: 'inline-block',
    fontSize: 24,
    fontWeight: 'bold',
    flex: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    cursor: 'default',
    '@media(max-width: 768px)': {
      fontSize: 12
    }
  },
  arrowUp: {
    color: 'green'
  },
  arrowDown: {
    color: 'red'
  },
  videoContainerTile: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paragraphMargin: {
    margin: 0,
    flex: 1,
    cursor: 'default',
    '@media(max-width: 767px)': {
      fontSize: 14
    }
  },
  progress: {
    color: AppTheme.white
  },
  loader: {
    height: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});
