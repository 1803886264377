/* eslint-disable */ 
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { AppConfig } from '../AppConfig';
import moment from 'moment';
import * as urlParser from 'url-parse';
import * as qs from 'qs';

export class LiveImage extends Component {
  constructor(props) {
    super(props);
    this.updateImageIntervalFnId = null;
    this.imageFeedRefreshInterval = AppConfig.imageFeedRefreshInterval;
    this.state = {
      urlJpeg: this.props.urlJpeg,
    }
  }

  updateImageFeedUrl() {
    let urlJpeg = this.props.urlJpeg;
    if (urlJpeg) {
      const urlParsed = new urlParser(urlJpeg);
      const currQueryString = (urlParsed.query).substring(1);
      const queryParams = qs.parse(currQueryString);
      queryParams.timestamp = moment().valueOf();
      const modifiedQueryString = qs.stringify(queryParams);
      urlJpeg = urlParsed.origin + urlParsed.pathname + '?' + modifiedQueryString;
      this.setState({
        urlJpeg: urlJpeg
      })
    }
  }

  componentDidMount() {
    this.updateImageIntervalFnId = setInterval(() => {
      //console.log("updating live image...")
      this.updateImageFeedUrl();
    }, this.imageFeedRefreshInterval);
  }

  componentWillUnmount() {
    if (this.updateImageIntervalFnId) {
      clearInterval(this.updateImageIntervalFnId);
    }
    this.updateImageIntervalFnId = null
  }

  imgElement = React.createRef();

  render() {
    return (
      <img
        className={css(Styles.imageStyle)}
        id='target-image'
        src={this.state.urlJpeg}
        alt='Live Image Stream'
        ref={this.imgElement}
        onError={(e) => {
          e.target.src = require('../assets/images/error2.svg')
        }}
      />
    )
  }
}

const Styles = StyleSheet.create({
  imageStyle: {
    width: 'auto',
    height: '100%',
    '@media (max-width: 768px)': {
      width: '100%',
      height: 'auto'
    }
  }
})
