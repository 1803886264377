/* eslint-disable */ 
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import moment from 'moment';
import async from 'async';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import { UiHelper } from '../common/helpers/UiHelper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { ExcelDocUploader } from '../common/components/ExcelDocUploader';
import { CustomDialog } from '../components/CustomDialog';
import { Controller } from '../common/Controller';
import { CustomFormFieldsDialogV2 } from '../components/CustomFormFieldsDialogV2';
import { ActionHeaderbar } from '../components/ActionHeaderbar';
import { PopOverV2 } from '../components/PopOverV2';
import { AppConfig } from '../AppConfig';
import { AppHelper } from '../helpers/AppHelper';
import { appData } from '../data/AppData'
import { AppTheme } from '../styles/AppTheme';
import { ColourPalette } from '../styles/AppColourPalette';
import { CommonStyles } from '../styles/Styles';
import { StatsComponent } from './ClientPlace/StatsComponent';
import { CustomShieldDialog } from '../components/CustomShieldDialog';
const uuid = require('uuid');
let onlineAgentsCount = 0;
let offlineAgentsCount = 0;
let newAgentsCount = 0;
let totalAgents = 0;
export class Cams extends Component {
  constructor(props) {
    super(props);
    this.updateImageIntervalFnId = null;
    this.currentOrg = Controller.get().userMgr().getCurrentOrg()
    this.placeId = this.props.match.params.id ? this.props.match.params.id : null;
    this.responseByKey = {}
    this.parentAttributes = {}
    this.state = {
      updateMemberDialog: false,
      confirmDeleteView: false,
      inProcess: false,
      bulkCamUploadDialog: false,
      dialogAction: '',
      dialogTitle: '',
      selectedItem: {},
      anchorEl: null,
      fullScreenMode: Controller.get().userMgr().getFullScreenMode() ? 'EXIT FULLSCREEN' : 'FULLSCREEN',
      isHoveredID: null,
      colNumber: 4,
      showStats: false,
      fetchState: ApiHelper.State.LOADING,
      configDialog: false,
      shieldURL: "",
      agentStatus:["default"],
      agentCount:"",
      showSubscriptionDialog:false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const settings = Controller.get().userMgr().getSettings()
    let placeValue = null
    if (settings && settings.userSettings && settings.userSettings.placeStat) {
      if (settings.userSettings.placeStat === 'ON') {
        placeValue = true
      } else {
        placeValue = false
      }
      this.setState({
        showStats: placeValue
      })
    }

    this.setFields()

    this.fetchItems();

    this.updateImageIntervalFnId = setInterval(() => {
      this.fetchItems();
    }, AppConfig.imageFeedRefreshInterval);
  }

  setFields() {
    this.camFields = []
    this.defaultCamFields = AppConfig.DEFAULT_FIELDS ? (AppConfig.DEFAULT_FIELDS.camFields || []) : []
  }

  componentWillUnmount() {
    if (this.updateImageIntervalFnId) {
      clearInterval(this.updateImageIntervalFnId);
    }
    this.updateImageIntervalFnId = null
  }

  fetchItems = () => {
    const placeId = this.placeId
    async.parallel(
      [
        function (callback) {
          ApiHelper.call(
            { method: 'GET', endPoint: ApiHelper.makeUrlPath(['places', placeId]) }, callback)
        }
      ],
      function (err, results) {
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err)
          })
          return
        }
        for (const c of results[0].cams) {
          if (!c.flags) continue
          c.flags = AppHelper.arrayToCsv(c.flags)
        }

        this.setBreadcrumbs(results[0])

        this.setAttributes(results[0])
        totalAgents = results[0].cams

        onlineAgentsCount = 0;
        offlineAgentsCount = 0;
        newAgentsCount = 0;

        if (results[0].cams) {
          for (let cam of results[0].cams) {
            cam['agentStatus'] = AppHelper.getAgentStatus(cam)
            if(cam['agentStatus'] == "offline"){
              offlineAgentsCount++
            }else if(cam['agentStatus'] == "online"){
              onlineAgentsCount++
            }else if(cam['agentStatus'] == "newAgent"){
              newAgentsCount++
            }
          }
        }
         
        let agentStr = ""
          agentStr += "  Online : " + onlineAgentsCount
          agentStr += "  Offline : " + offlineAgentsCount
          agentStr += "  New : " + newAgentsCount

        this.setState({
          item: AppHelper.attachTsToURL(results[0]),
          org: results[0].org,
          orgId: results[0].orgId,
          agentCount : agentStr ? agentStr :"",
          fetchState: ApiHelper.State.READY,
        })

      }.bind(this))
  }


  setAttributes = (item) => {
    if (!item) return
    for (const p of AppConfig.placeTypes) {
      if (p.key === item.type) {
        this.parentAttributes = p.attributes
        break
      }
    }
  }

  setBreadcrumbs = (item) => {
    this.breadcrumbs = [
      {
        title: AppHelper.getPlaceAltName(item.org) + 's', //TODO
        to: '/orgs/' + item.orgId + '/places'
      },
      {
        title: item.name,
        to: ''
      },
    ]
  }

  handleHover = (e, index) => {
    this.setState({
      isHoveredID: index
    });
  }

  setColNumber = (value) => {
    this.setState({
      colNumber: value
    })
  }

  getInfoString = (item) => {
    if (!item.latestEventTs) {
      return 'No events yet'
    }
    const minutes = moment().diff(moment(item.latestEventTs), 'minutes')
    const seconds = moment().diff(moment(item.latestEventTs), 'seconds')
    if (seconds < 60) {
      return 'Last Event ' + seconds + ' seconds ago'
    } else if (minutes < 60) {
      return 'Last Event ' + minutes + ' minutes ago'
    } else {
      const hours = moment().diff(moment(item.latestEventTs), 'hours')
      if (hours > 24) {
        const days = moment().diff(moment(item.latestEventTs), 'days')
        return 'Last Event ' + days + ' days ago'
      } else {
        return 'Last Event ' + hours + ' hrs ago'
      }
    }
  }

  getLatestEvent = (item) => {
    return (
      <div className={css(Styles.latestEventContainer)}>
        <p className={css(Styles.latestEventContent)}>
          {this.getInfoString(item)}
        </p>
      </div>
    )
  }

  tileClick = (e, item) => {
    Controller.get().pushRoute({ pathname: '/places/' + item.placeId, search: '?camId=' + item._id })
  }

  liveClick = (e, item) => {
    if (!e) {
      const e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    Controller.get().pushRoute('/places/' + item.placeId + '/cams/' + item._id)
  }

  renderDefautIcon = () => {
    return (
      <div className={css(Styles.iconContainer)}>
        <Icon className={css(Styles.icons)} color='primary'>videocam</Icon>
      </div>
    )
  }

  renderLiveImg = (item) => {
    return (
      <img
        style={{ width: '100%' }}
        src={item.urlJpeg}
        alt='Live Image Stream'
        className={css(Styles.imgContent)}
      /*onError={(e) => {
        e.target.src = require('../assets/images/error.svg')
      }}*/
      />
    )
  }

  renderLatestEventImg = (item) => {
    return (
      <img
        style={{ width: '100%' }}
        src={item.latestEventImageUrl}
        alt='Latest Event'
        className={css(Styles.imgContent)}
        onError={(e) => {
          e.target.src = require('../assets/images/error2.svg')
        }}
      />
    )
  }

  renderProfileImage = (item) => {
    return (
      <img
        src={item.image}
        alt='Profile image'
        className={css(Styles.imgContentProPic)}
      />
    )
  }

  renderImg(item) {
    if (item.urlJpeg) {
      return this.renderLiveImg(item)
    } else if (item.image) {
      return this.renderProfileImage(item)
    } else if (!item.urlJpeg && item.latestEventImageUrl) {
      return this.renderLatestEventImg(item)
    } else {
      return this.renderDefautIcon()
    }
  }

  onSettingClick = (e, item) => {
    if (!e) {
      const e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    Controller.get().pushRouteWithParams('/places/' + this.placeId + '/cams/' + item._id + '/settings', { orgId: this.state.orgId })
  }

  getAgentStatus = (time) =>{
    if(!time){
      return false;
    }
    if(AppConfig.agentStatusTimer && AppConfig.agentStatusEnabled){
 
      const startDate = moment.utc(time);  
      const endDate = moment.utc();
      const duration = moment.duration(endDate.diff(startDate));

     if(duration.days() == 0 && duration.hours() == 0 && duration.minutes() <= AppConfig.agentStatusTimer){
         return true 
     }else{
       return false
     }
    }
  }

  getImageView = (item, index) => {
    let highlight = false
    if (moment(item.lastSeenTs).valueOf() < moment(item.latestEventTs).valueOf()) {
      highlight = true
    }
    if (!item.latestEventTs) {
      highlight = false
    }

    return (
      <Grid
        item xs={12}
        sm={this.state.colNumber}
        className={css(Styles.tileContainer)}
        onClick={(e) => this.tileClick(e, item)}>
        <div
          className={highlight ? css(Styles.tileContent, Styles.tileContentHighlight) : css(Styles.tileContent)}
          onMouseEnter={e => this.handleHover(e, index)}
          onMouseLeave={e => this.handleHover(e, null)}>
          {this.state.isHoveredID === index &&
            <div
              className={css(Styles.imgOuterContainer, Styles.imgOuterContainerHover)}>

              {this.renderImg(item)}

              <div className={css(Styles.imgOuterHover)}>
                <div className={css(Styles.hoverButtonOuterContainer)}>
                  <div
                    className={this.state.colNumber < 3 ?
                      css(Styles.hoverButtonContainer, Styles.hoverButtonStyle) : css(Styles.hoverButtonContainer)}
                    onClick={(e) => this.tileClick(e, item)}>
                    <p className={css(CommonStyles.margin)}>Events</p>
                  </div>
                  {(item.urlJpeg || this.state.org.type === 'wfh') &&
                    <div
                      className={this.state.colNumber < 3 ?
                        css(Styles.hoverButtonContainer, Styles.hoverButtonStyle) : css(Styles.hoverButtonContainer)}
                      onClick={(e) => this.liveClick(e, item)}>
                      <p className={css(CommonStyles.margin)}>Live</p>
                    </div>
                  }
                </div>
              </div>
            </div>
          }

          {this.state.isHoveredID !== index &&
            <div
              className={css(Styles.imgOuterContainer)}>
              {this.renderImg(item)}
            </div>
          }

          <div className={css(Styles.tileDetailOuterContainer)}>
            <div className={css(Styles.tileDetailNameContainer)}>
              <p className={css(Styles.tileDetailContainerName)}>{item.name}</p>
            </div>

            <div className={css(Styles.tileDetailContainer)}>
              {this.getLatestEvent(item)}
              <div className={css(Styles.editContainer)}>
                {this.state.org.type  && this.state.org.type.toLowerCase() != "society" && AppHelper.showSettings(item, 'cam') && Controller.get().userMgr().getCurrentOrg() && Controller.get().userMgr().getCurrentOrg().roles &&  Controller.get().userMgr().getCurrentOrg().roles.includes("ADMIN") &&
                  <div
                    aria-describedby={this.props.id}
                    onClick={(e) => this.onSettingClick(e, item)}
                    className={css(Styles.editContent)}>
                    <Icon className={css(Styles.settingsIcon)}>settings</Icon>
                  </div>
                }
                {/*<div
                  aria-describedby={this.props.id}
                  className={css(Styles.editContent)}>
                  {this.healthy &&
                    <Icon className={css(Styles.healthyIcon)}>fiber_manual_record</Icon>
                  }
                  {!this.healthy &&
                    <Icon className={css(Styles.unHealthyIcon)}>fiber_manual_record</Icon>
                  }
                </div>*/}
                <div onClick={(e) => this.editClick(e, item)} className={css(Styles.editContent, Styles.bgBlack)} >
                  <img src={require('../assets/images/more-rounded.svg')} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    )
  }

  setFullscreen = () => {
    if (this.state.fullScreenMode === 'FULLSCREEN') {
      // this.setState({ fullScreenMode: 'EXIT FULLSCREEN' })
      Controller.get().userMgr().setFullScreenMode(true)
    } else {
      // this.setState({ fullScreenMode: 'FULLSCREEN' })
      Controller.get().userMgr().setFullScreenMode(false)
    }
    window.location.reload();
  };

  setAgentStatus = (agentStatus) =>{
    this.setState({
      agentStatus:agentStatus,
    })
  }

  resetToDefaultValues = () => {
    this.responseByKey = {}
    this.setFields()
    this.setState({
      updateMemberDialog: false,
      bulkCamUploadDialog: false,
      inProcess: false,
      dialogAction: '',
      dialogTitle: '',
      selectedItem: {},
      anchorEl: null,
      confirmDeleteView: false,
      showSubscriptionDialog:false
    })
  }

  showAddMemberDialog = () => {
    let item = {}
    if (AppConfig.emailSuffix && this.state.org.type == 'alert') {
      let hexString = uuid();
      hexString = hexString.replace(/-/g, "");
      item = {
        email: (Buffer.from(hexString, 'hex').toString('base64')).replace(/[^a-zA-Z0-9 ]/g, '') + AppConfig.emailSuffix
      }
    }
    this.responseByKey = item
    this.setState({
      updateMemberDialog: true,
      dialogTitle: 'Add a ' + AppHelper.getCamAltName(this.state.org),
      dialogAction: 'add',
      selectedItem: item,
      errMsg: '',
    })
  }

  showBulkCamUploadDialog = () => {
    this.setState({
      bulkCamUploadDialog: true,
      dialogTitle: 'Add multiple ' + AppHelper.getCamAltName(this.state.org)
    })
  }

  showConfirmDeleteView = () => {
    this.setState({
      confirmDeleteView: true,
      inProcess: false,
      dialogAction: '',
    })
  }

  showDeleteMemberDialog = () => {
    this.responseByKey = this.state.selectedItem
    this.setState({
      anchorEl: null,
      updateMemberDialog: true,
      dialogTitle: 'Delete a ' + AppHelper.getCamAltName(this.state.org),
      dialogAction: 'delete',
    })
  }

  showUpdateMemberDialog = () => {
    this.responseByKey = this.state.selectedItem
    this.setState({
      anchorEl: null,
      updateMemberDialog: true,
      dialogTitle: 'Edit a ' + AppHelper.getCamAltName(this.state.org),
      dialogAction: 'edit',
      errMsg: ''
    })

  }

  onPopOverClick = (key) => {
    if (key === 'edit') {
      this.showUpdateMemberDialog()
    } else if (key === 'delete') {
      this.showDeleteMemberDialog()
    } else if (key === 'downloadReport') {
      Controller.get().pushRoute('/reports/' + this.placeId)
    } else if (key === 'viewInfo') {
      this.showViewMemberDialog()
    } else if (key === 'config') {
      this.showConfigDialog()
    }else if (key == "subscriptions"){
      this.showSubscriptionDialog()

    }
  }

  showSubscriptionDialog = () => {
    this.responseByKey = this.state.selectedItem

    this.setState({
      anchorEl: null,
      showSubscriptionDialog: true,
      dialogTitle: "Subscriptions",
      errMsg: ''
    })
  }

  showConfigDialog = () => {
    this.responseByKey = this.state.selectedItem
    this.fetchCamStatus()
  }
  fetchCamStatus = () => {
    const dashboard_camId = JSON.parse(JSON.stringify(this.responseByKey._id))
    let shieldURL = AppConfig.JM_PROXY_ENDPOINT + "/camera/" + dashboard_camId + "/config_ui/?disableLock=true&instance=" + dashboard_camId
    console.log("dashboard_camId ", dashboard_camId);
    console.log("setting shieldURL: ", shieldURL);
    this.setState({
      shieldURL: shieldURL,
      configDialog: true
    })
  }

  showViewMemberDialog = () => {
    this.responseByKey = this.state.selectedItem
    this.setState({
      anchorEl: null,
      updateMemberDialog: true,
      dialogTitle: AppHelper.getCamAltName(this.state.org),// TO CHECK whether it is camera or cam
      dialogAction: 'viewInfo',
    })
  }

  editClick = (e, item) => {
    if (!e) {
      const e = window.event;
      e.cancelBubble = true;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    this.setState({
      anchorEl: e.currentTarget,
      selectedItem: item
    })
  }

  editClickClose = () => {
    this.setState({ anchorEl: null })
  }

  processFields = () => {
    this.camFields = []
    let typeFound = false
    if (this.state.org) {
      for (const f of AppConfig.orgTypes) {
        if (f.key === this.state.org.type) {
          typeFound = true
          if (f.camFieldConfig) {
            this.camFields = f.camFieldConfig
          } else {
            this.camFields = this.defaultCamFields.concat(appData.camFields)
            this.camFields = this.camFields.filter((v, i, a) => a.findIndex(t => (t.key === v.key)) === i)
          }
          break;
        }
      }
      if (!typeFound) {
        this.camFields = this.defaultCamFields.concat(appData.camFields)
        this.camFields = this.camFields.filter((v, i, a) => a.findIndex(t => (t.key === v.key)) === i)
        this.responseByKey.type = 'general' //NOTE:Setting general as default type
      }
    } else {
      this.camFields = this.defaultCamFields || appData.camFields
    }

    for (const cField of this.camFields) {
      if (cField.key === 'type') {
        const options = []
        for (const pType of AppConfig.camTypes) {
          options.push({
            name: pType.name,
            key: pType.key
          })
        }
        cField.options = options
      }
    }
  }

  onExcelValidationSuccess = (data) => {
    console.log('onExcelValidationSuccess')
    this.addCameras(data)
  }

  renderBulkCamUploadDialog = () => {
    return (
      <CustomDialog
        onClose={this.resetToDefaultValues}
        disableBackdropClick={true}
        dialogOpen={this.state.bulkCamUploadDialog}
        dialogTitle={this.state.dialogTitle}
        errMsg={this.state.errMsg}
        inProcess={this.state.inProcess}>
        <div className={css(Styles.excelTipsOuterContainer)}>
          <div className={css(Styles.excelTipsContainer)}>
            <Icon fontSize={'small'} className={css(Styles.excelTipsIcon)}>info</Icon>
            <p className={css(Styles.excelTips)}>
              Please upload the file only in the specified format. You can download a sample file
              <a className={css(Styles.downloadLink)} href={this.state.org.type == 'wfh' ? require('../assets/templates/wfh-cams.xlsx') : require('../assets/templates/general-cams.xlsx')}> here.</a> </p>
          </div>
          <div className={css(Styles.excelTipsContainer)}>
            <Icon fontSize={'small'} className={css(Styles.excelTipsIcon)}>info</Icon>
            <p className={css(Styles.excelTips)}>Uploading file in any other format will lead to failure of upload.</p>
          </div>
          <div className={css(Styles.excelTipsContainer)}>
            <Icon fontSize={'small'} className={css(Styles.excelTipsIcon)}>info</Icon>
            <p className={css(Styles.excelTips)}>For mobile entries, dial code should be given in the exact format as specified in our sample file</p>
          </div>

          <ExcelDocUploader
            uploadId={'dataList'}
            uploadType = "Cams"
            orgType={this.state.org.type}
            onExcelValidationSuccess={this.onExcelValidationSuccess}
          />
        </div>
      </CustomDialog>
    )
  }

  renderUpdateMemberDialog = () => {
    this.processFields()
    return (
      <CustomFormFieldsDialogV2
        onClose={this.resetToDefaultValues}
        dialogOpen={this.state.updateMemberDialog}
        dialogTitle={this.state.dialogTitle}
        confirmDeleteView={this.state.confirmDeleteView}
        inProcess={this.state.inProcess}
        selectedItem={this.state.selectedItem}
        deleteItem={() => this.deleteMember()}
        action={this.state.dialogAction}
        showConfirmDeleteView={this.showConfirmDeleteView}
        responseByKey={this.responseByKey}
        onSuccess={this.addMember}
        errMsg={this.state.errMsg}
        formFields={this.camFields}
        enableValidation={this.state.dialogAction === 'delete' ? false : true}
        formDisabled={this.state.dialogAction === 'delete' ? true : false}>
      </CustomFormFieldsDialogV2>
    )
  }
  renderConfigDialog = () => {

    return (
      <CustomShieldDialog
        closeConfigDialog={this.closeConfigDialog}
        shieldURL={this.state.shieldURL}
        configDialog={this.state.configDialog}
      />

    )
  }

  renderSubscriptionDialog = () => {
    const { subscriptions } = this.responseByKey; // Destructure subscriptions from this.responseByKey
    if (!subscriptions) {
      return (
        <CustomDialog
          onClose={this.resetToDefaultValues}
          disableBackdropClick={true}
          dialogOpen={this.state.showSubscriptionDialog}
          dialogTitle={this.state.dialogTitle}
          errMsg={this.state.errMsg}
          inProcess={this.state.inProcess}>
          <div className={css(Styles.excelTipsOuterContainer)}>
            <div className={css(Styles.excelTipsContainer)}>
              <p>No subscriptions found</p>
            </div>
          </div>
        </CustomDialog>
      )
      
    }
    return (
      <CustomDialog
        onClose={this.resetToDefaultValues}
        disableBackdropClick={true}
        dialogOpen={this.state.showSubscriptionDialog}
        dialogTitle={this.state.dialogTitle}
        errMsg={this.state.errMsg}
        inProcess={this.state.inProcess}>
        <div className={css(Styles.excelTipsOuterContainer)}>
          <div>

            {subscriptions && Object.keys(subscriptions).map((key) => (
              <div key={key}>
                <span>{key}: </span>
                <span>{subscriptions[key]}</span>
              </div>
            ))}
          </div>
        </div>
      </CustomDialog>
    )
  }

  closeConfigDialog = () => {
    this.setState({
      configDialog: false,
      shieldURL: ""
    }, () => this.resetToDefaultValues())
  }

  addCameras = (items) => {
    this.setState({
      inProcess: true
    })
    const camData = []
    let cam = {}

    if (this.state.org.type == 'wfh') {
      for (const item of items) {
        cam = {
          email: item.email,
          name: item.name,
          phone: item.mobile,
          placeId: this.placeId
        }
        
        Helper.removeNullValues(cam)
        camData.push(cam)
      }
    }
    else {
      for (const item of items) {
        cam = {
          name: item.name,
          urlJpeg: item.urlJpeg,
          urlVideo: item.urlVideo,
          fps: item.fps,
          type: item.type,
          placeId: this.placeId,
          enabled: ['YES']
        }
        
        Helper.removeNullValues(cam)
        camData.push(cam)
      }
    }


    let options = {}
    options = {
      method: 'POST',
      endPoint: ApiHelper.makeUrlPath(['cams', 'bulk']),
      jsonBody: {
        camData: camData,
        placeId: this.placeId,
        action: 'ADD_MULTIPLE'
      }
    }

    ApiHelper.call(options, function (err, data) {
      if (err) {
        this.setState({
          errMsg: Helper.getErrorMsg(err),
          inProcess: false,
        })
        return
      } else {
        this.resetToDefaultValues()
        this.fetchItems();
      }
    }.bind(this))
  }

  addMember = () => {
    this.setState({
      inProcess: true
    })

    let options = {}

    const camData = { placeId: this.placeId }
    for (const cField of this.camFields) {
      camData[cField.key] = this.responseByKey[cField.key]
    }

    const id = this.state.selectedItem ? this.state.selectedItem._id : null
    if (!id) {
      options = {
        method: 'POST',
        endPoint: ApiHelper.makeUrlPath(['cams']),
        jsonBody: {
          camData: camData
        }
      }
    } else {
      const body = {
        camData: camData
      }
      options = {
        method: 'PUT',
        endPoint: ApiHelper.makeUrlPath(['cams', id]),
        jsonBody: body
      }
    }

    Helper.removeNullValues(options.jsonBody.camData)

    ApiHelper.call(options, function (err, data) {
      if (err) {
        this.setState({
          errMsg: Helper.getErrorMsg(err),
          inProcess: false,
        })
        return
      } else {
        this.resetToDefaultValues()
        this.fetchItems();
      }
    }.bind(this))
  }

  deleteMember = () => {
    const id = this.state.selectedItem ? this.state.selectedItem._id : null
    if (!id) {
      return
    }

    this.setState({
      inProcess: true
    })

    const options = {
      method: 'DELETE',
      endPoint: ApiHelper.makeUrlPath(['cams', id]),
    }

    ApiHelper.call(options, function (err, data) {
      if (err) {
        this.setState({
          errMsg: Helper.getErrorMsg(err),
          inProcess: false,
        })
        return
      } else {
        this.resetToDefaultValues()
        this.fetchItems();
      }
    }.bind(this))
  }

  onRowItemClick = (item) => {
    Controller.get().pushRouteWithParams(
      '/places/' + item._id + '/cams',
      {
        item: item
      }
    )
  }

  renderStats = () => {
    if (this.state.item && this.state.item.attributes && Object.keys(this.state.item.attributes).length > 0) {
      return (
        <StatsComponent
          mode={'place'}
          entityId={this.placeId}
          parentAttributes={this.parentAttributes}
          attributes={this.state.item.attributes}
          showStats={this.state.showStats}
          showStatsHandler={this.showStatsHandler}
          state={this.state}
          refresh={this.fetchItems}
        />
      )
    }
  }

  renderCams = () => {

    return (
      <div className={css(CommonStyles.innerMainContainer,  this.state.fullScreenMode == "EXIT FULLSCREEN" ?  CommonStyles.mgTop2 :'')}>
        <div className={css(Styles.innerContainer)}>
          {this.renderStats()}
          <Grid
            container
            spacing={4}>
            {this.state.item &&
              this.state.item.cams.length > 0 ?
              this.state.item.cams.map((item, index) => {
                return (
               this.state.agentStatus.includes("default" )|| !AppConfig.agentStatusEnabled ?this.getImageView(item, index) : ( this.state.agentStatus.includes(item.agentStatus)? this.getImageView(item, index):null)
                )
              })
              :
              <p className={css(CommonStyles.emptyView)}>{Helper.getString('emptyView')}</p>
            }
          </Grid>
        </div>
      </div>
    )
  }

  getActionHeaderbarTitle = () => {
    if (this.state.item && this.state.item.cams && this.state.item.cams.length === 1) {
      return 'Live Grid | ' + this.state.item.cams.length + ' ' + AppHelper.getCamAltName(this.state.org) + 's'
    } else if (this.state.item && this.state.item.cams && this.state.item.cams.length > 1) {
      return 'Live Grid | ' + this.state.item.cams.length + ' ' + AppHelper.getCamAltName(this.state.org) + 's'
    } else {
      return 'Live Grid | ' + AppHelper.getCamAltName(this.state.org)
    }
  }

  showStatsHandler = (value) => {
    let placeValue = null
    if (value) {
      placeValue = 'ON'
    } else {
      placeValue = 'OFF'
    }
    this.setState({ showStats: value }, () => Controller.get().userMgr().setUserSettings({ placeStat: placeValue }))
  }


  render() {
    if (this.state.fetchState === ApiHelper.State.LOADING) {
      return UiHelper.componentLoadingView();
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      return UiHelper.errorView(this);
    } else {
      return this.readyView();
    }
  }

  readyView = () => {
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
      <div className={css(CommonStyles.outerMainContainer)}>
        <ActionHeaderbar
          breadcrumbs={this.breadcrumbs}
          title={this.getActionHeaderbarTitle()}
          items={this.state.item.cams}
          buttonOneAction={this.showAddMemberDialog}
          buttonOneTitle={'ADD NEW ' + AppHelper.getCamAltName(this.state.org).toUpperCase()}
          camPage={true}
          buttonTwoTitle={'Upload from excel'}
          buttonTwoAction={this.showBulkCamUploadDialog}
          inProcess={this.state.inProcess}
          fullScreen={true}
          fullScreenMode={this.state.fullScreenMode}
          setFullscreen={this.setFullscreen}
          slider={true}
          showSummaryButton={this.state.item && this.state.item.attributes && Object.keys(this.state.item.attributes).length !== 0 ? true : false}
          showStatsHandler={this.showStatsHandler}
          showStats={this.state.showStats}
          sliderFunction={this.sliderFunction}
          setColNumber={this.setColNumber}
          setAgentStatus = {this.setAgentStatus}
          agentCount = {this.state.agentCount}
          orgType={this.state.org.type}
        />

        {this.renderCams()}

        {this.renderUpdateMemberDialog()}
        {(Controller.get().userMgr().isProductUser() || Controller.get().userMgr().isAdminUser()) ?
          this.renderConfigDialog() : ""}

        {(Controller.get().userMgr().isProductUser() || Controller.get().userMgr().isAdminUser()) ?
          this.renderSubscriptionDialog() : ""}

        <PopOverV2
          id={id}
          open={open}
          items={(Controller.get().userMgr().isProductUser() || Controller.get().userMgr().isAdminUser()) ? 
            (AppConfig.allowCamConfig ? appData.camConfigPopOverFields : appData.camConfigPopOverFieldsEECM) : appData.regularPopOverFields}
          anchorEl={this.state.anchorEl}
          onPopOverClick={this.onPopOverClick}
          editClickClose={this.editClickClose}
        />
        {this.state.bulkCamUploadDialog &&
          this.renderBulkCamUploadDialog()
        }        
      </div>
    )
  }
}


const Styles = StyleSheet.create({
  bgBlack : {
    // backgroundColor:"#000"
  },
  innerContainer: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 65,
    marginBottom: 50
  },
  tileContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  tileContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    borderRadius: '0 10px 10px 10px',
    // backgroundColor: AppTheme.secondaryColor,
    position: 'relative',
    // border: '2px solid' + AppTheme.secondaryColor,
    cursor: 'pointer'
  },
  tileContentHighlight: {
    // border: '2px solid #FF682A'
  },
  tileDetailOuterContainer: {
    display: 'flex',
    '@media(max-width: 767px)': {
      flexDirection: 'column'
    }
  },
  tileDetailNameContainer: {
    // backgroundColor: AppTheme.primaryBackground,
    backgroundColor:"#fff",
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    maxWidth: '30%',
    borderRadius: '0px 0px 0px 10px',
    '@media(max-width: 767px)': {
      borderRadius: 0,
      maxWidth: 'unset',
    }
  },
  tileDetailContainer: {
    display: 'flex',
    flex: 1,
    padding: 10,
    alignItems: 'center',
    // backgroundColor: AppTheme.secondaryColorVariant,
    backgroundColor:"#fff",


    borderRadius: '0px 0px 10px 0px',
    '@media(max-width: 767px)': {
      borderRadius: '0px 0px 10px 10px',
    }
  },
  settingsIcon: {
    // color: AppTheme.white,
    color: AppTheme.primaryBase,

    fontSize: 18
  },
  healthyIcon: {
    color: '#30f064',
    fontSize: 18
  },
  unHealthyIcon: {
    color: '#f04a4a',
    fontSize: 18
  },
  latestEventContent: {
    margin: 0,
    // color: AppTheme.white,
    color: "#000",

    opacity: 0.5,
    fontSize: 12
  },
  camIdText: {
    margin: 0,
    marginTop: 5,
    // color: AppTheme.white,
    color: "#000",

    opacity: 0.5,
    fontSize: 10
  },
  tileDetailContainerName: {
    // color: AppTheme.white,
    color:"#000 !important",

    fontSize: AppTheme.fontSizeTitle,
    margin: 0,
    // fontWeight: 'bold',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  editContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  editContent: {
    cursor: 'pointer',
    width: 30,
    height: 30,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ':hover': {
      // backgroundColor: AppTheme.secondaryColor
      background:"rgba(0, 0, 0, 0.07)"

    }
  },
  imgOuterContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: AppTheme.primaryBackground,
    borderRadius: '0px 10px 0px 0px'
  },
  imgContent: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '0px 10px 0px 0px'
  },
  imgContentProPic: {
    maxWidth: '100%',
    maxHeight: '200px',
    borderRadius: '50%',
    paddingTop: 10,
    paddingBottom: 10
  },
  imgOuterContainerHover: {
    position: 'relative'
  },
  imgOuterHover: {
    display: 'flex',
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'rgba(107,95,253,0.35)',
    borderRadius: '0px 10px 0px 0px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  hoverButtonOuterContainer: {
    display: 'flex',
  },
  hoverButtonContainer: {
    padding: '7px 15px',
    border: '1px solid ' + AppTheme.white,
    borderRadius: 4,
    fontSize: '12',
    // color: AppTheme.white,
    color: "#000",

    minWidth: 60,
    margin: 8,
    textAlign: 'center',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'rgba(255,255,255,0.5)',
      color: '#323B4D'
    }
  },
  hoverButtonStyle: {
    minWidth: 'unset',
    padding: 10
  },
  iconContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
  },
  icons: {
    color: ColourPalette.color1,
    fontSize: 70
  },
  innerMainContent: {
    display: 'flex',
    width: '90%',
    flexDirection: 'column',
    margin: '40px 0px 50px 0px'
  },
  excelTips: {
    margin: 0,
    color: "#000",
  },
  excelTipsIcon: {
    marginRight: 5,
    color: "#000",
  },
  excelTipsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 10,
    marginBottom: 10
  },
  excelTipsOuterContainer: {
    marginBottom: 10
  },
  downloadLink: {
    color: 'orange'
  },
  divider: {
    height: 20
  }
})
