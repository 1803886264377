/* eslint-disable */ 
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as pdfMake from 'pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";
// import * as moment from 'moment';
import moment from 'moment';
import XlsxPopulate from "xlsx-populate";
import { AppHelper } from '../../helpers/AppHelper';

const customNameForWFH = {	
  placeId: "Project ID",	
  cam_name: "Agent",	
  place_name: "Project",	
  camId: "Agent ID",	
  priority: "Priority",	
  priorityReported: "Reported Priority",	
  name: "Violation",	
  event_time: "Event Date Time",	
  type: "Violation Type",	
  grouped_events: "Grouped Events",	
  image: "Image",	
  comments: "Comments",	
}
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
var wscols = [
  { wch: 3 },
  { wpx: 100 },
  { wpx: 150 },
  { wpx: 100 },
  { wpx: 350 },
  { wpx: 250 }
];
var wsrows = [{}]
export class FileDownloader {
  constructor() { }

  static exportAsExcelFile(json, excelFileName, pword, call) {
    let worksheet = XLSX.utils.json_to_sheet(json);
    if (excelFileName == "audit") {
      worksheet['!cols'] = wscols;
      for (let index = 0; index < json.length; index++) {
        let element = json[index];
        wsrows.push(
          { hpx: 110 }
        )
      }
      worksheet['!rows'] = wsrows;
    }

    let workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    let excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    XlsxPopulate.fromDataAsync(excelBuffer)
      .then(function (workbook) {
        workbook.outputAsync({ type: "arraybuffer", password: pword })
          .then(function (data) {
            FileDownloader.saveAsExcelFile(data, excelFileName);
            if(call) {
              call()
            }
          });
      }).catch((err) => {
        console.log("error", err)
      });
  }

  static saveAsExcelFile(buffer, fileName) {
    const data = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  static downloadAsPdf(columns, rows, fileName, columnStyles, pword, type, call) {
    const col = []
    const widths = new Array(Object.keys(columns).length - 1).fill(0);
    var count = 0;
    var headerKeys = []
    var totalWidth = 100;
    var indexOfCommetns
    var indexOfPriority
    Object.keys(columns).forEach(k => {
      var obj = {}
      obj.style = 'header'
      obj.text = type && type == "wfhTeams" && columns[k].customTitle ? columns[k].customTitle : columns[k].title
      headerKeys.push(obj.text)
      col.push(obj)
    })
    totalWidth -= 4;
    headerKeys.splice(headerKeys.indexOf("SL No."), 1, undefined)
    widths.unshift(4 + "%")
    count++;
    if (headerKeys.includes("comments")) {
      indexOfCommetns = headerKeys.indexOf('comments')
      headerKeys.splice(indexOfCommetns, 1, undefined)
      totalWidth -= 35;
      count++;
    }
    if (headerKeys.includes("priority")) {
      indexOfPriority = headerKeys.indexOf('priority')
      headerKeys.splice(indexOfPriority, 1, undefined)
      totalWidth -= 8;
      count++;
    }
    for (let index = 0; index < headerKeys.length; index++) {
      var element = headerKeys[index]
      if (element) {
        widths.splice(headerKeys.indexOf(element), 1, ((totalWidth / (columns.length - count)) + "%"))
      }
    }
    if (indexOfCommetns || indexOfCommetns == 0) {
      widths.splice(indexOfCommetns, 1, 35 + "%")
    }
    if (indexOfPriority || indexOfPriority == 0) {
      widths.splice(indexOfPriority, 1, 8 + "%")
    }
    rows.unshift(col)
    var docDefinition = {
      userPassword: pword,
      permissions: {
        printing: 'highResolution', //'lowResolution'
        modifying: false,
        copying: true,
        annotating: true,
        fillingForms: true,
        contentAccessibility: true,
        documentAssembly: true
      },
      content: [
        {
          layout: 'headerLineOnly',
          table: {
            widths: widths,
            headerRows: 1,
            body: rows
          }
        }
      ],
      styles: {
        header: {
          fontSize: 8,
          bold: true,
          fillColor: "#0082be",
          color: "white"
        }
      },
      defaultStyle: {
        fontSize: 6,
      }
    };
    pdfMake.createPdf(docDefinition).download(fileName, (err, res) => {
      if (call) {
        call()
      }

    });

  }

  static prepExcelDownload(itemList, selectedItems, fileName, timeAttr, eventsByGroupId, orgPlaces, pword, type, call, startingIndex, timezone) {
    if (selectedItems.includes("selectAll")) {
      selectedItems.splice(selectedItems.indexOf("selectAll"), 1)
    }
    const camEvents = [];
    let index = startingIndex ? startingIndex : 1;
    for (const item of itemList) {
      const e = {};
      e['SL No.'] = index;
      for (const sItem of selectedItems) {
        if (sItem === timeAttr) {
          e[customNameForWFH[sItem] || sItem] = (moment(item[sItem]).tz(timezone ? timezone : "").format('YYYY-DD-MM HH:mm:ss:SSS') )+ " \n " + (timezone ? timezone : "")
         
        } else if (sItem === 'tags') {
          /*if(item.tags) {
            const tagKs = Object.keys(item.tags)
            e[sItem] = null
            for(const tagK of tagKs) {
              if(!e[sItem]) {
                e[sItem] = tagK + ' : ' + item.tags[tagK]
              } else {
                e[sItem] = e[sItem] + ', '  + tagK + ' : ' + item.tags[tagK]
              }
            }
          } else {
            e[sItem] = ''
          }*/
          if (item.tags) {
            const tagKs = Object.keys(item.tags)
            for (const tagK of tagKs) {
              //if(item.tags[tagK] && item.tags[tagK].length > 0) {
              if (item.tags[tagK] !== null && item.tags[tagK] !== undefined) {
                e[tagK] = item.tags[tagK]
              }
            }
          }
        } else if (sItem === 'grouped_events') {
          e[customNameForWFH[sItem] || sItem] = (item.groupId && eventsByGroupId[item.groupId]) ? (eventsByGroupId[item.groupId].length + 1) : 1
        } else if (sItem === 'placeId') {
          e[type && type == "wfhTeams" && customNameForWFH[sItem] ? customNameForWFH[sItem] : sItem] = AppHelper.renderPlaceid(item, orgPlaces)
        } else if (sItem === 'place_name') {
          e[type && type == "wfhTeams" && customNameForWFH[sItem] ? customNameForWFH[sItem] : 'Place Name'] = AppHelper.renderPlaceName(item, orgPlaces)
        } else if (sItem === 'comments') {
          e[customNameForWFH[sItem] || sItem] = AppHelper.renderComment(item, orgPlaces)
        } else if (sItem === 'cam_name') {
          e[type && type == "wfhTeams" && customNameForWFH[sItem] ? customNameForWFH[sItem] : 'Cam Name'] = AppHelper.renderCamName(item, orgPlaces)
        } else if (sItem === 'priority') {
          e[customNameForWFH[sItem] || sItem] = AppHelper.getPriorityStr(item.priority)
        } else {
          e[type && type == "wfhTeams" && customNameForWFH[sItem] ? customNameForWFH[sItem] : sItem] = item[sItem];
        }
      }
      camEvents.push(e);
      index++;
    }
    this.exportAsExcelFile(camEvents, fileName, pword, call);
  }

  static prepExcelDownloadForAudit(itemList, selectedItems, fileName, timeAttr, subSet, timezone) {
    const auditEvents = [];
    let index = 1;
    for (const item of itemList) {
      const e = {};

      e['No.'] = index;
      for (const sItem of selectedItems) {
        if (sItem === timeAttr) {
          e[sItem] = (moment(item[sItem]).tz(timezone ? timezone : "").format('YYYY-DD-MM HH:mm:ss:SSS') )+ " \n " + (timezone ? timezone : "")
        } else if (sItem === 'data') {
          if (item.data) {
            const tagKs = Object.keys(item.data)
            for (const tagK of tagKs) {
              if (subSet.includes(tagK)) {
                if (item.data[tagK] !== null && item.data[tagK] !== undefined) {
                  e[tagK] = item.data[tagK]
                }
              }
            }
          }
        } else {
          e[sItem] = item[sItem];
        }
      }
      auditEvents.push(e);
      index++;
    }
    this.exportAsExcelFile(auditEvents, fileName);
  }

  static prepPdfDownload(itemList, selectedItems, fileName, columnStyles, eventsByGroupId, orgPlaces, pword, type, call, startingIndex, timeAttr, timezone) {
    if (selectedItems.includes("selectAll")) {
      selectedItems.splice(selectedItems.indexOf("selectAll"), 1)
    }
    const columns = [];
    columns.push({ title: 'SL No.' });
    for (const sItem of selectedItems) {
      if (type == "wfhTeams") {
        columns.push({ title: sItem, customTitle: customNameForWFH[sItem] });
      } else {
        columns.push({ title: sItem });
      }
    }
    const rows = [];
    let tempRow = [];
    let index = startingIndex ? startingIndex : 1;
    for (const item of itemList) {
      tempRow = [index];
      for (const sItem of selectedItems) {
        if (sItem === timeAttr) {
                tempRow.push(moment(item[sItem]).tz(timezone ? timezone : "").format('YYYY-DD-MM HH:mm:ss:SSS') + "\n" + (timezone ? timezone : ""))
        } else
        if (sItem === 'tags') {
          let tagStr = null
          if (item.tags) {
            const tagKs = Object.keys(item.tags)
            for (const tagK of tagKs) {
              if (!tagStr) {
                tagStr = tagK + ' : ' + item.tags[tagK]
              } else {
                tagStr = tagStr + ', ' + tagK + ' : ' + item.tags[tagK]
              }
            }
          } else {
            tagStr = ''
          }
          tempRow.push(tagStr);
        } else if (sItem === 'grouped_events') {
          const grpCount = (item.groupId && eventsByGroupId[item.groupId]) ? (eventsByGroupId[item.groupId].length + 1) : 1
          tempRow.push(grpCount)
        } else if (sItem === 'placeId') {
          tempRow.push(AppHelper.renderPlaceid(item, orgPlaces))
        } else if (sItem === 'place_name') {
          tempRow.push(AppHelper.renderPlaceName(item, orgPlaces))
        } else if (sItem === 'comments') {
          tempRow.push(AppHelper.renderComment(item, orgPlaces))
        } else if (sItem === 'cam_name') {
          tempRow.push(AppHelper.renderCamName(item, orgPlaces))
        } else if (sItem === 'priority') {
          tempRow.push(AppHelper.getPriorityStr(item.priority))
        } else {
          tempRow.push(item[sItem]);
        }
      }
      rows.push(tempRow);
      index++;
    }
    this.downloadAsPdf(columns, rows, fileName, columnStyles, pword, type, call);
  }
}