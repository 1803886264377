import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import async from 'async';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Helper } from '../common/helpers/Helper'
import { Controller } from '../common/Controller';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { UsernameInput, PasswordInput } from '../common/components/TextInputs';
import { AppTheme } from '../styles/AppTheme';

export class LoginLocal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProcess: false,
      errMsg: '',
      userName: '',
      pword: '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  onSubmitLogin = () => {
    if (!this.state.userName || !this.state.pword) {
      this.setState({
        errMsg: Helper.getString('enterEmailPwd')
      })
      return
    }

    this.setState({
      inProcess: true
    })

    const jsonBody = {
      username: this.state.userName,
      password: this.state.pword
    };

    async.parallel(
      [
        function (callback) {
          ApiHelper.publicApiCall(
            {
              jsonBody: jsonBody,
              method: 'POST',
              endPoint: ApiHelper.makeUrlPath(['miscs', 'locallogin'])
            }, callback)
        }
      ],
      function (err, results) {
        if (err) {
          this.setState({
            inProcess: false,
            errMsg: Helper.getErrorMsg(err)
          })
          return
        }

        const data = results[0]

        Controller.get().userMgr().setloggedInUserLocal(data).then((result) => {
          this.setState({
            inProcess: false,
          })
          Controller.get().userMgr().navigateUserToHome();
        }).catch((error) => {
          Controller.get().userMgr().signOut()
          this.setState({
            inProcess: false,
            errMsg: Helper.getString('defaultErrorMsg')
          })
          return
        });
      }.bind(this))
  }

  render() {
    return (
      <div className={css(Styles.container)}>
        <Paper className={css(Styles.loginPanel)}>
          <div className={css(Styles.logoImageContainer)}>
            <img
              src={require('../assets/images/logo.png')}
              alt='Logo'
              className={css(Styles.logoImage)} />
          </div>

          {this.state.errMsg && this.state.errMsg.length > 0 &&
            <p className={css(Styles.loginMessage)}>{this.state.errMsg}</p>
          }

          {this.loginView()}

        </Paper>
      </div>
    );
  }

  loginView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })} />

        <PasswordInput
          id='pword'
          value={this.state.pword}
          onChange={(event) => this.setState({ pword: event.target.value })}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              this.onSubmitLogin()
            }
          }} />

        <div className={css(Styles.paddingTop)}>
          <Button
            fullWidth
            margin='normal'
            variant={this.state.inProcess ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.inProcess}
            onClick={this.onSubmitLogin}
          >
            LOGIN
            {this.state.inProcess &&
              <CircularProgress
                color='primary'
                size={20}
                className={css(Styles.marginLeft)} />
            }
          </Button>
        </div>

      </div>
    );
  }
}

const Styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    backgroundImage: `url(${require('../assets/images/login-bg.jpg')})`,
    backgroundSize: 'cover',
    minHeight: '100vh'
  },
  loginPanel: {
    textAlign: 'center',
    width: 300,
    padding: 20,
    boxShadow: 'none',
    backgroundColor: '#262930c9',
    '@media (max-width: 767px)': {
      padding: 10,
      marginLeft: 10,
      marginRight: 10,
      width: 'unset'
    }
  },
  logoImageContainer: {
    width: 232,
    margin: 'auto',
    borderRadius: 5,
  },
  logoImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: 5,
  },
  loginMessage: {
    fontWeight: 'bold',
    margin: 0,
    marginTop: 15,
    fontSize: 14,
    color: AppTheme.white
  },
  paddingTop: {
    paddingTop: 20
  },
  marginLeft: {
    marginLeft: 10,
    color: AppTheme.white
  }
})
