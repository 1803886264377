/* eslint-disable */ 
import { Styles } from './Styles';
import { StyleSheet, css } from 'aphrodite';
import Icon from '@material-ui/core/Icon';
import React, { Component } from 'react';

function NextArrow(props) {
  const { onClick } = props;
  //console.log('NextArrow', props.currentSlide)
  return (
    (props.currentSlide + 1) !== props.slideCount &&
    <div className={
      props.hideMobileView ? css([Styles.iconContainer, Styles.iconContainerSpec]) : css(Styles.iconContainer)
    }>
      <div className={css(Styles.iconContainerNext)} onClick={onClick}>
        <Icon className={css(Styles.closeIcon)}>
          arrow_forward
        </Icon>
      </div>
    </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  //console.log('PrevArrow', props.currentSlide)
  return (
    props.currentSlide !== 0 &&
    <div className={
      props.hideMobileView ? css([Styles.iconContainer, Styles.iconContainerSpec]) : css(Styles.iconContainer)
    }>
      <div className={css(Styles.iconContainerPrev)} onClick={onClick}>
        <Icon className={css(Styles.closeIcon)}>
          arrow_back
        </Icon>
      </div>
    </div>
  );
}

function LeftArrow(props) {
  const { onClick } = props;
  //console.log('LeftArrow', props.currentSlide)
  return (
    <div className={css(Styles.iconContainer)}>
      <div
        className={props.currentSlide === 0 ?
          css(Styles.relatedImageSelectionContent, Styles.relatedImageSelectionLess) : css(Styles.relatedImageSelectionContent)}
        onClick={onClick} >
        {props.currentSlide !== 0 &&
          <img src={require('../../assets/images/left-direction-img.svg')} />
        }
      </div>
    </div>
  );
}

function RightArrow(props) {
  //console.log('RightArrow', props.currentSlide)
  const { onClick } = props;
  return (
    <div className={css(Styles.iconContainer)}>
      <div
        className={(props.currentSlide + 2) === props.slideCount ?
          css(Styles.relatedImageSelectionContent, Styles.relatedImageSelectionLess) : css(Styles.relatedImageSelectionContent)}
        onClick={onClick} >
        {(props.currentSlide + 2) !== props.slideCount &&
          <img src={require('../../assets/images/right-direction-img.svg')} />
        }
      </div>
    </div>
  );
}

export const settings = {
  dots: false,
  infinite: false,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplayspeed: 3000,
  cssEase: 'linear',
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
};

export const relativeImageSliderSettings = {
  dots: false,
  infinite: false,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplayspeed: 3000,
  cssEase: 'linear',
  nextArrow: <NextArrow hideMobileView={true} />,
  prevArrow: <PrevArrow hideMobileView={true} />
};

export const relativesettings = {
  dots: false,
  infinite: false,
  speed: 1000,
  slidesToShow: 2.5,
  slidesToScroll: 1,
  autoplay: false,
  autoplayspeed: 3000,
  cssEase: 'linear',
  nextArrow: <RightArrow />,
  prevArrow: <LeftArrow />,
};

export const relativesettingsLess = {
  dots: false,
  infinite: false,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplayspeed: 3000,
  cssEase: 'linear',
  nextArrow: <RightArrow />,
  prevArrow: <LeftArrow />,
};