/* eslint-disable */ 
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import async from 'async';
import Icon from '@material-ui/core/Icon';
import { UiHelper } from '../common/helpers/UiHelper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { CustomFormFieldsDialogV2 } from '../components/CustomFormFieldsDialogV2';
import { ActionHeaderbar } from '../components/ActionHeaderbar';
import { PopOverV2 } from '../components/PopOverV2';
import { TableListV2 } from '../components/TableListV2';
import { Stats } from '../components/Stats';
import { Controller } from '../common/Controller';
import { CommonStyles } from '../styles/Styles';
import { AppTheme } from '../styles/AppTheme';
import { AppConfig } from '../AppConfig';
import { appData } from '../data/AppData';

export class Devices extends Component {
  constructor(props) {
    super(props);
    this.orgId = (this.props.match && this.props.match.params) ? this.props.match.params.id : null
    this.currentOrg = Controller.get().userMgr().getCurrentOrg()
    this.responseByKey = {}
    this.cams = []
    this.places = []
    this.deviceFields = []
    this.state = {
      updateMemberDialog: false,
      dialogAction: '',
      dialogTitle: '',
      selectedItem: {},
      anchorEl: null,
      confirmDeleteView: false,
      inProcess: false,
      showStats: false,
      deviceFields: [],
      places: [],
      fetchState: ApiHelper.State.LOADING,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.setFields()
    this.fetchItems();
  }

  setFields = () => {
  }

  fetchItems = () => {
    const orgId = this.orgId
    this.setState({
      errMsg: null
    })
    if (Controller.get().userMgr().isAdminUser() && orgId !== 'PRODUCT') {
      async.parallel(
        [
          function (callback) {
            ApiHelper.call(
              { method: 'GET', endPoint: ApiHelper.makeUrlPath(['places'], { orgId }) }, callback)
          },
          function (callback) {
            ApiHelper.call(
              { method: 'GET', endPoint: ApiHelper.makeUrlPath(['devices'], {orgId: orgId}) }, callback)
          },
        ],
        function (err, results) {
          if (err) {
            this.setState({
              fetchState: ApiHelper.State.ERROR,
              errMsg: Helper.getErrorMsg(err)
            })
            return
          }

          this.org = Controller.get().userMgr().getCurrentOrg()

          this.deviceFields = AppConfig.DEFAULT_FIELDS ? (AppConfig.DEFAULT_FIELDS.devicePlaceFields || []) : []

          this.nameByPlaceId = {}
          for(const place of results[0]) {
            this.nameByPlaceId[place._id] = place.name
          }

          const options = []
          for (const p of results[0]) {
            options.push({
              name: p.name,
              key: p._id
            })
          }
          this.deviceFields[0].options = options

          for(const dev of results[1]) {
            dev.placeName = this.nameByPlaceId[dev.placeId]
          }

          this.setState({
            devices: results[1],
            orgs: [],
            places: results[0],
            fetchState: ApiHelper.State.READY
          })

        }.bind(this))
    } else {
      async.parallel(
        [
          function (callback) {
            ApiHelper.call(
              { method: 'GET', endPoint: ApiHelper.makeUrlPath(['orgs']) }, callback)
          },
          function (callback) {
            ApiHelper.call(
              { method: 'GET', endPoint: ApiHelper.makeUrlPath(['devices']) }, callback)
          },
        ],
        function (err, results) {
          if (err) {
            this.setState({
              fetchState: ApiHelper.State.ERROR,
              errMsg: Helper.getErrorMsg(err)
            })
            return
          }

          this.org = Controller.get().userMgr().getCurrentOrg()

          this.deviceFields = AppConfig.DEFAULT_FIELDS ? (AppConfig.DEFAULT_FIELDS.deviceOrgFields || []) : []
          const options = []
          for (const p of results[0]) {
            options.push({
              name: p.name,
              key: p._id
            })
          }
          this.deviceFields[0].options = options
          this.nameByOrgId = {}
          for(const org of results[0]) {
            this.nameByOrgId[org._id] = org.name
          }
          for(const dev of results[1]) {
            dev.orgName = this.nameByOrgId[dev.orgId]
          }
          this.setState({
            devices: results[1],
            orgs: results[0],
            fetchState: ApiHelper.State.READY
          })
        }.bind(this))
    }
  }

  resetToDefaultValues = () => {
    this.responseByKey = {}
    this.setState({
      updateMemberDialog: false,
      inProcess: false,
      dialogAction: '',
      dialogTitle: '',
      selectedItem: {},
      anchorEl: null,
      confirmDeleteView: false,
      errMsg: null
    })
  }

  showUpdateMemberDialog = () => {
    this.responseByKey = this.state.selectedItem
    if (Controller.get().userMgr().isAdminUser() && this.orgId !== 'PRODUCT') {
      this.setState({
        anchorEl: null,
        updateMemberDialog: true,
        dialogTitle: 'Assign Place',
        dialogAction: 'edit',
      })      
    } else {
      this.setState({
        anchorEl: null,
        updateMemberDialog: true,
        dialogTitle: 'Assign Org',
        dialogAction: 'edit',
      })
    }
  }

  showConfirmDeleteView = () => {
    this.setState({
      confirmDeleteView: true,
      inProcess: false,
      dialogAction: '',
    })
  }

  onPopOverClick = (key) => {
    if (key === 'edit') {
      this.showUpdateMemberDialog()
    } else if (key === 'delete') {
      this.showDeleteMemberDialog()
    }
  }

  showDeleteMemberDialog = () => {
    this.responseByKey = this.state.selectedItem
    this.setState({
      anchorEl: null,
      updateMemberDialog: true,
      dialogTitle: 'Delete a device' ,
      dialogAction: 'delete',
    })
  }

  editClick = (e, item) => {
    let nItem  = {}
    nItem = Object.assign(nItem, item)
    this.setState({
      anchorEl: e.currentTarget,
      selectedItem: nItem
    })
  }

  editClickClose = () => {
    this.setState({ anchorEl: null })
  }

  renderMemberDialog = () => {
    return (
      <CustomFormFieldsDialogV2
        onClose={this.resetToDefaultValues}
        dialogOpen={this.state.updateMemberDialog}
        dialogTitle={this.state.dialogTitle}
        confirmDeleteView={this.state.confirmDeleteView}
        inProcess={this.state.inProcess}
        selectedItem={this.state.selectedItem}
        deleteItem={() => this.deleteMember()}
        action={this.state.dialogAction}
        showConfirmDeleteView={this.showConfirmDeleteView}
        responseByKey={this.responseByKey}
        onSuccess={this.addMember}
        formFields={this.deviceFields}
        errMsg={this.state.errMsg}
        enableValidation={this.state.dialogAction === 'delete' ? false : true}
        formDisabled={this.state.dialogAction === 'delete' ? true : false} >
      </CustomFormFieldsDialogV2 >
    )
  }

  addMember = () => {
    this.setState({
      inProcess: true
    })

    let options = {}
    const deviceData = {}
    for (const oField of this.deviceFields) {
      deviceData[oField.key] = this.responseByKey[oField.key]
    }

    const id = this.state.selectedItem ? this.state.selectedItem._id : null

    if (id) {
      const body = {
        orgId: this.orgId,
        deviceData: deviceData
      }
      options = {
        method: 'PUT',
        endPoint: ApiHelper.makeUrlPath(['devices', id]),
        jsonBody: body
      }
    } else {
      return
    }

    Helper.removeNullValues(options.jsonBody.deviceData)

    ApiHelper.call(options, function (err, data) {
      if (err) {
        this.setState({
          errMsg: Helper.getErrorMsg(err),
          inProcess: false,
        })
        return
      } else {
        this.resetToDefaultValues()
        this.fetchItems();
      }
    }.bind(this))
  }

  deleteMember = () => {
    const id = this.state.selectedItem ? this.state.selectedItem._id : null
    if (!id) {
      return
    }
    this.setState({
      inProcess: true
    })
    const options = {
      method: 'DELETE',
      endPoint: ApiHelper.makeUrlPath(['devices', id]),
    }
    ApiHelper.call(options, function (err, data) {
      if (err) {
        this.setState({
          errMsg: Helper.getErrorMsg(err),
          inProcess: false,
        })
        return
      } else {
        this.resetToDefaultValues()
        this.fetchItems();
      }
    }.bind(this))
  }

  onRowItemClick = () => {
    return
  }

  render() {
    if (this.state.fetchState === ApiHelper.State.LOADING) {
      return UiHelper.componentLoadingView();
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      return UiHelper.errorView(this);
    } else {
      return this.readyView();
    }
  }

  readyView = () => {
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    let orgName = null
    if (this.org && this.org.name === 'PRODUCT') {
      orgName = AppConfig.NAME
    } else if (this.currentOrg) {
      orgName = this.currentOrg.name
    }

    return (
      <div className={css(CommonStyles.outerMainContainer)}>
        <ActionHeaderbar
          title={orgName}
          items={this.state.devices}
          org={this.org}
          statPrefix={((this.state.devices && this.state.devices.length > 1) ? ' Devices' : ' Device')}
          inProcess={this.state.inProcess}
          showStats={this.state.showStats}
          showSummaryButton={this.org && this.org.attributes && Object.keys(this.org.attributes).length !== 0 ? true : false}
        />

        <div className={css(CommonStyles.innerMainContainer)}>
          <div className={css(Styles.innerMainContent)}>

            {this.state.showStats &&
              <div className={css(Styles.divider)}></div>
            }

            {this.state.devices.length !== 0 &&
              <TableListV2
                showHealthStatus={true}
                type={'device'}
                disableRowClick={true}
                onRowItemClick={this.onRowItemClick}
                tableColumnTitles={window.innerWidth < 767 ? appData.deviceColMobileTitles : appData.deviceColDesktopViewTitles}
                tableColumnTitlesData={window.innerWidth < 767 ? appData.deviceColTitles : appData.deviceColDesktopViewTitles}
                items={this.state.devices}
                editClick={this.editClick}
                id={id}
              />
            }

            {this.state.devices.length === 0 &&
              <p className={css(CommonStyles.emptyView)}>{Helper.getString('noDevices')}</p>
            }
          </div>
        </div>

        {this.renderMemberDialog()}

        <PopOverV2
          id={id}
          open={open}
          items={Controller.get().userMgr().isAdminUser() && this.orgId !== 'PRODUCT' ? appData.devicePopOverFieldsOrgAdmin: appData.devicePopOverFieldsAdmin }
          anchorEl={this.state.anchorEl}
          onPopOverClick={this.onPopOverClick}
          editClickClose={this.editClickClose}
        />

      </div>
    )
  }
}

const Styles = StyleSheet.create({
  innerMainContent: {
    display: 'flex',
    width: '90%',
    flexDirection: 'column',
    margin: '40px 0px 50px 0px'
  },
  excelTips: {
    margin: 0,
    color: AppTheme.white,
  },
  excelTipsIcon: {
    marginRight: 5,
    color: AppTheme.white,
  },
  excelTipsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 10,
    marginBottom: 10
  },
  excelTipsOuterContainer: {
    marginBottom: 10
  },
  downloadLink: {
    color: 'orange'
  },
  divider: {
    height: 20
  }
})