/* eslint-disable */ 
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import moment from 'moment';
import Hls from 'hls.js';
import async from 'async';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import { CommonStyles } from '../styles/Styles';
import { AppTheme } from '../styles/AppTheme';
import { ColourPalette } from '../styles/AppColourPalette';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { UiHelper } from '../common/helpers/UiHelper';
import { SearchHelper } from '../helpers/SearchHelper';
import { AppHelper } from '../helpers/AppHelper';
import { EventActionHeaderbar } from './ClientPlace/EventActionHeaderBar';
import { AppConfig } from '../AppConfig';
import { liveStreamingIntervals } from '../data/AppData'

export class CamLiveCloud extends Component {
  constructor(props) {
    super(props);
    this.eventUpdateIntervalFnId = null;
    this.placeId = this.props.match.params.id ? this.props.match.params.id : null;
    this.fromDateTs = null
    this.selectedCamIds = (this.props.match && this.props.match.params && this.props.match.params.camid) ? [this.props.match.params.camid] : []
    this.tagFilters = []
    this.priorityFilters = []
    this.tagValue = {}
    this.tileHeight = window.innerWidth < 768 ? 400 : 500
    this.hls = new Hls();
    this.state = {
      fetchState: ApiHelper.State.LOADING,
      pageNumber: 0,
      paginate: false,
      eventListLoading: false,
      eventList: [],
      eventCompleteList: [],
      selectedItem: null,
      time: liveStreamingIntervals[0].value,
      inProcess: false,
      videoErrMsg: null
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.fetchItems();
  }

  componentWillUnmount() {
    this.stopVideo()
    if (this.eventUpdateIntervalFnId) {
      clearInterval(this.eventUpdateIntervalFnId);
    }
    this.eventUpdateIntervalFnId = null
  }

  fetchItems = () => {
    const funcs = []
    const thisObj = this
    funcs.push(function (callback) {
      thisObj.fetchPlace(callback)
    })
    funcs.push(function (callback) {
      thisObj.updateEvents(null, callback)
    })
    funcs.push(function (callback) {
      thisObj.fetchLiveCamDetails(thisObj.state.time, callback)
    })

    async.parallel(funcs, (err, results) => {
      if (err) {
        this.setState({
          fetchState: ApiHelper.State.ERROR,
          errMsg: Helper.getErrorMsg(err)
        })
        return
      }

      this.setBreadcrumbs(results[0])

      if (results[0] && results[0].cams && results[0].cams.length > 0) {
        if (!this.selectedCamIds || this.selectedCamIds.length === 0) {
          this.selectedCamIds = [results[0].cams[0]._id]
        }

        this.setState({
          place: results[0],
          fetchState: ApiHelper.State.READY,
        }, () => {
          this.startVideo(this.state.liveVideoData)
        })
      } else {
        this.setState({
          fetchState: ApiHelper.State.ERROR,
          errMsg: Helper.getString('camNotFound')
        })
      }
    })
  }

  fetchPlace = (callback) => {
    ApiHelper.call(
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['places', this.placeId]) },
      function (err, result) {
        if (err) return callback(err)
        return callback(null, result)
      }.bind(this))
  }

  setBreadcrumbs = (item) => {
    this.breadcrumbs = [
      {
        title: AppHelper.getPlaceAltName(item.org),
        to: '/orgs/' + item.orgId + '/places'
      },
      {
        title: 'Live Grid',
        to: '/places/' + item._id + '/cams'
      },
      {
        title: 'Live Stream',
        to: ''
      },
    ]
  }

  updateEvents = (action, callback) => {
    if (!this.selectedCamIds || this.selectedCamIds.length === 0) {
      this.setState({
        eventList: [],
        eventCompleteList: []
      })
      return
    }

    const params = {
      priority: AppHelper.getGlobalPriotiyValue(),
      limit: AppConfig.dashboardEventQueryLimit,
    }

    if (this.fromDateTs) {
      params.fromDateTs = this.fromDateTs
    }

    params.camId = this.selectedCamIds

    if (action) {
      this.setState({
        eventList: [],
        eventListLoading: true,
        showChart: false,
      })
    }

    if (action === 'next') {
      if (!this.state.paginate) {
        return
      }
      params.pageNumber = this.state.pageNumber + 1
    }

    if (action === 'prev') {
      if (this.state.pageNumber === 0) {
        return
      }
      params.pageNumber = this.state.pageNumber - 1
    }

    const options = {
      method: 'GET',
      endPoint: ApiHelper.makeUrlPath(['camevents'], params)
    }

    ApiHelper.call(
      options,
      function (err, result) {
        if (err) {
          this.setState({
            errMsg: Helper.getErrorMsg(err),
            eventList: [],
            eventCompleteList: [],
            paginate: false,
            pageNumber: 0
          }, () => {
            if (callback) return callback(null)
          })
        } else {
          this.updateUrl()

          if (action === 'time') {
            this.fetchLiveCamDetails(this.state.time)
          }
  
          this.setState({
            pageNumber: result.currPageNumber,
            paginate: result.more ? true : false,
            eventList: this.filterWithParams(result.items),
            eventCompleteList: result.items,
            eventListLoading: false,
          }, () => {
            if (callback) return callback(null)
          })
        }

      }.bind(this))
  }

  fetchLiveCamDetails(time, callback, mode) {
    this.stopVideo()
    this.setState({
      inProcess: true
    })

    let query = {}

    if (time === 'live') {
      query = {
        camId: this.selectedCamIds[0],
        playbackMode: 'LIVE',
      }
    } else if (mode === 'eventVideo') {
      query = {
        camId: this.selectedCamIds[0],
        playbackMode: 'ON_DEMAND',
        fromTs: moment(time).subtract(10, 'seconds').valueOf(),
        orgId: this.state.place.orgId
      }
    } else {
      let ts = moment(time, 'hh:mm').valueOf()
      if (this.fromDateTs) {
        const newTs = moment(this.fromDateTs).format('MMMM Do YYYY') + ', ' + time
        ts = moment(newTs, 'MMMM Do YYYY, hh:mm').valueOf()
      }
      query = {
        camId: this.selectedCamIds[0],
        playbackMode: 'ON_DEMAND',
        fromTs: ts,
        orgId: this.state.place.orgId
      }
    }

    if (query.fromTs) {
      query.toTs = query.fromTs + (3600 * 3 * 1000)
    }

    ApiHelper.call(
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['misctwos', 'streamingtwo'], query) },
      function (err, result) {
        if (err) {
          this.setState({
            liveVideoData: null,
            videoErrMsg: Helper.getString('noVideoStream'),
            inProcess: false,
          }, () => {
            if (callback) return callback(null)
          })
        } else {
          this.setState({
            inProcess: false,
            liveVideoData: result,
            videoErrMsg: (result && result.hls) ? null : Helper.getString('noVideoStream'),
          }, () => {
            if (callback) {
              return callback(null)
            } else {
              this.startVideo(result)
            }
          })
        }
      }.bind(this))
  }

  startVideo = (result) => {
    if (!result || !result.hls) return
    const video = document.getElementById('video');
    const videoSrc = result.hls;
    if (Hls.isSupported()) {
      if(this.startPosition && this.state.time !== 'live') {
        this.hls = new Hls({startPosition: this.startPosition});
      } else {
        this.hls = new Hls();
      }
      this.hls.loadSource(videoSrc);
      this.hls.attachMedia(video);
      this.hls.on(Hls.Events.MANIFEST_PARSED, function () {
        video.play();
      });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = videoSrc;
      video.addEventListener('loadedmetadata', function () {
        video.play();
      });
    }

    this.hls.on(Hls.Events.ERROR, function (event, data) {
      switch (data.type) {
      case Hls.ErrorTypes.NETWORK_ERROR:
        // try to recover network error
        this.startPosition = (this.hls && this.hls.media ) ? this.hls.media.currentTime : null
        this.fetchLiveCamDetails(this.state.time)
        break;
      default:
        //TODO
        break;
      }
    }.bind(this));
  }

  stopVideo = () => {
    if (this.hls) {
      this.hls.stopLoad()
      this.hls.destroy()
    }
  }

  getEventCount = () => {
    return 'Page ' + (this.state.pageNumber + 1)
  }

  updateUrl = () => {
    const camIdStr = this.selectedCamIds[0]
    this.props.history.replace('/places/' + this.placeId + '/cams/' + camIdStr)
  }

  timeChangeHandler = (fromDateTs, mode) => {
    this.fromDateTs = fromDateTs
    this.setState({
      pageNumber: 0
    }, () => this.updateEvents('time'))
  }

  singlePickerHandler = (camId) => {
    this.selectedCamIds = camId
    this.updateEvents('cam')
  }

  filterHandler = (tagFilters, priorityFilters, tagValue) => {
    this.tagFilters = tagFilters
    this.priorityFilters = priorityFilters
    this.tagValue = tagValue
    this.setState({
      eventList: this.filterWithParams(this.state.eventCompleteList),
    })
  }

  eventRefreshHandler = (refresh) => {
    if (refresh) {
      this.startAutoUpdate()
    } else {
      this.stopAutoUpdate()
    }
  }

  startAutoUpdate = () => {
    this.eventUpdateIntervalFnId = setInterval(() => {
      if (this.state.pageNumber === 0) this.updateEvents();
    }, AppConfig.eventRefreshInterval);
  }

  stopAutoUpdate = () => {
    if (this.eventUpdateIntervalFnId) {
      clearInterval(this.eventUpdateIntervalFnId);
      this.setState({
        restoreUpdate: true
      })
    }
    this.eventUpdateIntervalFnId = null
  }

  filterWithParams = (items) => {
    let eventListAfterFilter = []
    if (this.searchText) {
      eventListAfterFilter = SearchHelper.filterItemsBySearchText(this.searchText, items)
    } else {
      eventListAfterFilter = items
    }

    if (this.tagFilters && this.tagFilters.length > 0) {
      eventListAfterFilter = SearchHelper.filterItemsByTag(this.tagFilters, this.tagValue, eventListAfterFilter)
    }

    if (this.priorityFilters && this.priorityFilters.length > 0) {
      eventListAfterFilter = SearchHelper.filterItemsByPriority(this.priorityFilters, eventListAfterFilter)
    }
    return eventListAfterFilter
  }

  getValue(key, item) {
    if (item[key]) {
      if (key === 'event_time') {
        return (
          <TableCell align='left' className={css(Styles.tableItem)}>
            {moment(item[key]).format('MMM DD, HH:mm:ss')}
          </TableCell>
        )
      } else {
        return (
          <TableCell align='left' className={css(Styles.tableItem)}>
            <p className={css(Styles.tableEntry)}>{item[key]}</p>
            {key === 'name' &&
              <p className={css(Styles.typeEntry)}>{item.type}</p>
            }
          </TableCell>
        )
      }
    } else {
      return (
        <TableCell align='left' className={css(Styles.tableItem)}>

        </TableCell>
      )
    }
  }

  onEventClick = (item) => {
    if (item && item.event_time) {
      this.fetchLiveCamDetails(moment(item.event_time).valueOf(), null, 'eventVideo')
      if (this.state.time) {
        this.setState({
          time: null,
          selectedItem: item
        })
      } else {
        this.setState({
          selectedItem: item
        })
      }
    }
  }

  renderCircularProgress = () => {
    return (
      <div
        className={css(Styles.progressContainer)}>
        <CircularProgress size={30} className={css(Styles.progress)} />
      </div>
    )
  }
  eventTable = () => {
    if (this.state.eventListLoading) {
      return this.renderCircularProgress()
    }

    if (!this.state.eventList || this.state.eventList.length === 0) {
      return (
        < p className={css(Styles.emptyEventContainer)}>{Helper.getString('noItemsFound')}</p>
      )
    }

    return (
      <Table>
        <TableBody>
          {
            this.state.eventList.map((item, index) => {
              return (
                <TableRow
                  onClick={() => this.onEventClick(item)}
                  className={
                    (this.state.selectedItem && (this.state.selectedItem._id === item._id)) ?
                      css(Styles.tableRowSelected) : css(Styles.tableRow)
                  }>
                  {this.getValue('name', item)}
                  {this.getValue('event_time', item)}
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    )
  }

  getPaginationData = () => {
    if (this.state.paginate || this.state.pageNumber !== 0) {
      return (
        <div className={css(Styles.paginationContainer)}>
          <Tooltip title='Previous'>
            <IconButton disabled={this.state.pageNumber === 0} className={css(Styles.refreshIconContainer)}>
              <Icon className={css(Styles.refreshIcon)} color='primary' onClick={() => this.updateEvents('prev')}>
                navigate_before
              </Icon>
            </IconButton>
          </Tooltip>

          <p className={css(Styles.pageNumber)}>{this.getEventCount()}</p>

          <Tooltip title='Next'>
            <IconButton disabled={!this.state.paginate} className={css(Styles.refreshIconContainer)}>
              <Icon className={css(Styles.refreshIcon)} color='primary' onClick={() => this.updateEvents('next')}>
                navigate_next
              </Icon>
            </IconButton>
          </Tooltip>
        </div>
      )
    } else {
      return ''
    }
  }

  getEventDetails = () => {
    return (
      <div className={css(Styles.eventContainer)}>
        <div className={css(Styles.eventContainerHeader)}>

          <div className={css(Styles.statsContainer)}>
            <p className={css(Styles.headerContainer)}>Total Events | {this.state.eventList.length}</p>
          </div>

          <div className={css(Styles.downloadContainer)}>
            {this.getPaginationData()}
          </div>
        </div>

        {this.eventTable()}

      </div>
    )
  }

  handleTimeChange = (event, time) => {
    this.startPosition = null
    if (time !== null) {
      this.setState({
        videoErrMsg: null,
        time: time,
        selectedItem: null
      });
      this.fetchLiveCamDetails(time)
    } else {
      if (this.state.time) {
        this.fetchLiveCamDetails(this.state.time)
      }
    }
  };

  getTimeTabs = () => {
    return (
      <ToggleButtonGroup
        value={this.state.time}
        exclusive
        onChange={this.handleTimeChange}
        size={'large'}
        className={css(Styles.toggleButtonContainer)}
      >
        {liveStreamingIntervals.map((item, index) => {
          return (
            <ToggleButton
              data-index={index}
              classes={{
                sizeLarge: css(Styles.toggleButton),
                selected: css(Styles.selectedButton)
              }}
              value={item.value}>
              <p className={css(Styles.toggleButtonValue)}>{item.label}</p>
            </ToggleButton>
          )
        })}
      </ToggleButtonGroup>
    )
  }

  setVideoUpdateMsg(event) {
    let msg = null
    if (event === 'onError') {
      msg = Helper.getString('defaultErrorMsg')
    }
    this.setState({
      videoErrMsg: msg
    })
  }

  render() {
    if (this.state.fetchState === ApiHelper.State.LOADING) {
      return UiHelper.componentLoadingView();
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      return UiHelper.errorView(this);
    } else {
      return this.readyView();
    }
  }

  readyView() {
    return (
      <div className={css(CommonStyles.outerMainContainer)}>
        <EventActionHeaderbar
          hideDateRangePicker={true}
          singleDatePicker={true}
          title={this.state.place.name}
          breadcrumbs={this.breadcrumbs}
          showDownload={true}
          singlePicker={{
            key: 'selectedCamId',
            label: 'Select Cam',
            options: this.state.place.cams,
            value: this.selectedCamIds
          }}
          singlePickerHandler={this.singlePickerHandler}
          timeChangeHandler={this.timeChangeHandler}
          filterHandler={this.filterHandler}
          eventRefreshHandler={this.eventRefreshHandler}
          selectedCamIds={this.selectedCamIds}
          showDownload={false}
        />

        <div className={css(Styles.innerMainContainer)}>
          <div className={css(Styles.innerContainer)}>
            <div
              style={{ height: this.tileHeight }}
              className={css(Styles.innerSubContainer)} >
              {this.state.videoErrMsg && !this.state.inProcess &&
                <p className={css(Styles.emptyEventContainer)}>{this.state.videoErrMsg}</p>
              }
              {this.state.inProcess &&
                this.renderCircularProgress()
              }
              {!this.state.videoErrMsg &&
                !this.state.inProcess &&
                this.state.liveVideoData &&
                this.state.liveVideoData.hls &&
                <video
                  className={css(Styles.videoPlayer)}
                  controls
                  onError={(e) => this.setVideoUpdateMsg('onError')}
                  onEnded={(e) => this.setVideoUpdateMsg('onVideEnd')}
                  id='video'>
                </video>
              }
            </div>

            <div className={css(Styles.showTimeTabsMobView)}>
              {this.getTimeTabs()}
            </div>

            <div
              style={{ height: this.tileHeight }}
              className={css(Styles.innerSubContainer, Styles.innerSubContainerSpec)} >
              {this.getEventDetails()}
            </div>
          </div>

          <div className={css(Styles.showTimeTabsWebView)}>
            {this.getTimeTabs()}
          </div>
        </div>
      </div>
    )
  }
}

const Styles = StyleSheet.create({
  outerMainContainer: {
    minHeight: 'unset'
  },
  innerMainContainer: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
    alignSelf: 'center',
  },
  innerContainer: {
    display: 'flex',
    '@media (max-width: 767px)': {
      flexDirection: 'column'
    }
  },
  innerSubContainer: {
    width: '50%',
    display: 'flex',
    margin: '0px 30px 0px 0px',
    backgroundColor: AppTheme.primaryColor,
    flexDirection: 'column',
    '@media (max-width: 767px)': {
      width: '100%',
      margin: '20px 0px',
    }
  },
  innerSubContainerSpec: {
    margin: '0px 0px 0px 30px',
  },
  title: {
    margin: '10px 0px',
    color: "#000",
    fontWeight: 'bold',
    textAlign: 'center'
  },
  eventList: {
    display: 'flex',
    margin: '10px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progress: {
    color: "#000",
    margin: '20px 0px',
    alignSelf: 'center',
  },
  emptyEventContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
    flex: 1,
    backgroundColor: AppTheme.primaryColor,
    margin: 0
  },
  toggleButtonContainer: {
    width: '100%',
    backgroundColor: AppTheme.primaryColor,
    marginBottom: AppTheme.pagePadding * 4,
    marginTop: AppTheme.pagePadding * 4,
    overflow: 'scroll'
  },
  toggleButton: {
    flex: 1
  },
  toggleButtonValue: {
    color: "#000",
    margin: 0,
  },
  selectedButton: {
    backgroundColor: ColourPalette.color3,
    color: "#000"
  },
  showTimeTabsWebView: {
    '@media (max-width: 767px)': {
      display: 'none'
    }
  },
  showTimeTabsMobView: {
    display: 'none',
    '@media (max-width: 767px)': {
      display: 'flex'
    }
  },
  videoPlayer: {
    height: '100%',
    width: '100%',
    background: AppTheme.primaryColor,
    outline: 'none'
  },
  tableRow: {
    backgroundColor: AppTheme.primaryColor,
    borderRadius: '4px 4px 0 0',
    cursor: 'pointer',
    ':hover': {
      // backgroundColor: AppTheme.primaryBackground,
    }
  },
  tableRowSelected: {
    backgroundColor: AppTheme.primaryBackground,
    borderRadius: '4px 4px 0 0',
    cursor: 'pointer'
  },
  tableItem: {
    color: "#000",
    borderColor: AppTheme.primaryBackground,
  },
  tableEntry: {
    margin: 0,
    marginBottom: 10,
  },
  typeEntry: {
    margin: 0,
    padding: 6,
    border: '1px solid ' + AppTheme.primaryBackground,
    display: 'inline',
    borderRadius: 5,
  },
  pageNumber: {
    opacity: 0.7,
    color: "#000",
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      fontSize: 15,
    }
  },
  refreshIconContainer: {
    padding: '0px 12px',
    ':hover': {
      // backgroundColor: AppTheme.primaryBackground,
    },
    '@media (max-width: 767px)': {
      padding: '0px 5px',
    }
  },
  refreshIcon: {
    borderRadius: '50%',
    cursor: 'pointer',
    opacity: 0.5,
    color: "#000",
    ':hover': {
      // backgroundColor: '#1D2331',
    }
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  eventContainer: {
    display: 'flex',
    flexDirection: 'column',
    // backgroundColor: AppTheme.primaryColor,
    height: '100%',
    overflowX: 'scroll'
  },
  eventContainerHeader: {
    display: 'flex',
    alignItems: 'center',
    // backgroundColor: AppTheme.primaryBackground,
    // backgroundColor: "none",
    border:"1px solid",
    padding: 10,
    borderRadius: '4px 4px 0 0',
    borderBottom: '1px solid ' + "#000"
  },
  headerContainer: {
    fontSize: 18,
    fontWeight: 'bold',
    flex: 1,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    // color: AppTheme.white,
    color: "#000",
    '@media (max-width: 767px)': {
      fontSize: 16,
    }
  },
  progressContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: AppTheme.primaryColor
  },
  statsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  }
})