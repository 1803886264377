/* eslint-disable */ 
import React, { Component } from 'react';
import { css } from 'aphrodite';
import { Styles } from './Styles';
import { CommonStyles } from '../../styles/Styles';
import Tooltip from '@material-ui/core/Tooltip';

export class ViewSummaryButton extends Component {
  constructor(props) {
    super(props);
  }

  setStyle = () => {
    if (this.props.actionHeaderbar) {
      if (this.props.showStats) {
        return css(CommonStyles.headerMemberAddContainer,
          Styles.headerMemberStyle,
          Styles.headerMemberStyleSpec,
          Styles.filterModeOn)
      } else {
        return css(CommonStyles.headerMemberAddContainer,
          Styles.headerMemberStyle,
          Styles.headerMemberStyleSpec)
      }

    } else {
      if (this.props.showStats) {
        return css(CommonStyles.headerMemberAddContainer,
          Styles.headerMemberStyle, Styles.filterModeOn)
      } else {
        return css(CommonStyles.headerMemberAddContainer,
          Styles.headerMemberStyle)
      }
    }
  }

  render = () => {
    return (
      <Tooltip title={'SUMMARY'}>
        <div
          onClick={() => this.props.showStatsHandler(!this.props.showStats)}
          className={this.setStyle()}>
          <img src={require('../../assets/images/summaryIcon.svg')} className={css(Styles.filterIcon)} />
          {/*<p className={css(Styles.switchControlLabel)}>{this.props.showStats ? 'COLLAPSE SUMMARY' : 'VIEW SUMMARY'}</p>*/}
        </div>
      </Tooltip>
    )
  }
}